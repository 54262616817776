import { useEffect, useState } from "react";
import { Space, Button, Input, Popconfirm, Modal, Table } from "antd";
import { request } from "../../util/api";
import {
  EditFilled,
} from "@ant-design/icons";
import { BookOpenIcon } from "@heroicons/react/20/solid";
import { BsBookFill } from "react-icons/bs";

// const {Option} = Select
import { AudioOutlined } from '@ant-design/icons';
const { Search } = Input;
const suffix = (
  <AudioOutlined
    style={{
      fontSize: 16,
      color: '#1677ff',
    }}
  />
);

const onSearch = (value) => console.log(value);


const AdminDashboard = () => {
  useEffect(() => {
    getAll();
  }, []);

  // useState
  const [list, setList] = useState([]);
  const [visibalModal, setVisibalModal] = useState(false);
  const [hfwId, setHfwId] = useState(null)
  const [grade, setGrade] = useState('')
  const [week, setWeek] = useState('')
  const [content, setContent] = useState('')
//   const [page, setPage] = useState(1);

  //confique database
  const getAll = () => {
    request('get','hfword_data').then(res => {
      setList(res.data.list)
      // console.log(res)
    }).catch(err => {
      console.log(err)
    })
  };

  // onConfirmDelete

  const onConfirmDelete = (id) => {
    request('delete','hfword_data/' + id).then(res =>{
        getAll()
    })
  };

  const handleCancel = () =>{
    setVisibalModal(false)
    clearCustomerForm()
  }

  const handleOpen = () =>{
    setVisibalModal(true)
  }

  const clearCustomerForm = () =>{
    setGrade('')
    setWeek('')
    setContent('')
  }

  const handleSave = () =>{
    if (hfwId === null){
        request('post', 'hfword_data/', {
            "grade": grade,
            "week": week,
            "content": content
        }).then(res=>{
            getAll()
            clearCustomerForm()
            setVisibalModal(false)
        }).catch(err=>{
          console.log(err)
        })

      }else{
          request('put', 'hfword_data/', {
              "id": hfwId,
              "grade": grade,
              "week": week,
              "content": content
          }).then(res=>{
            getAll()
            clearCustomerForm()
            setVisibalModal(false)
          }).catch(error=>{
            console.log(error)
          })
      }
    }
    

  const handleUpdate = (item, index) =>{
    setVisibalModal(true)
    setHfwId(item.id)
    setGrade(item.grade)
    setWeek(item.week)
    setContent(item.content)
  }

  const columns =[
    {
      key: 'id',
      title: 'ID',
      dataIndex: 'id',
    },
    {
      key: 'grade',
      title: 'Grade',
      dataIndex: 'grade'
    },
    {
      key: 'week',
      title: 'Week',
      dataIndex: 'week'
    },
    {
      key: 'content',
      title: 'Content',
      dataIndex: 'content'
    },
    {
      key: '',
      title: 'Action',
      dataIndex: '',
      render: (value, item, index) =>(
        <Space>
          <Button 
            icon={<EditFilled/>}
            onClick={() => handleUpdate(item, index)}
            className="bg-sbb-dark-blue h-10 w-24 text-white"
            >Edit</Button>

          <Popconfirm 
            placement="topRight"
            title={"Delete Customer"}
            description={"Are you sure to delete this customer?"}
            onConfirm={() => onConfirmDelete(item.id)}
            okText='Ok'
            cancelText="No"
            type='primary'
            className="bg-sbb-dark-blue"
          >
          </Popconfirm>
        </Space>
      )
    },
  ]

  return (
    <>
      {/* <div className="flex flex-row space-x-4 space-x-reverse border-b px-5 py-5">
        <div>
          <Space className="space">
            <div className="text-xl">Search</div>
            <Search placeholder="input search text" onSearch={onSearch} enterButton className="bg-sbb-dark-blue rounded-md hover:bg-sbb-blue"/>
            
            <Button type="primary" className="bg-sbb-dark-blue w-[120px] h-[40px] flex items-center" onClick={handleOpen}>
              <span className="pr-2"><BsBookFill /></span>Add New
            </Button>
          </Space>
        </div>
      </div>
      <section className="text-gray-600 body-font w-full">
        <div className="container px-4 py-10 mx-auto w-full">
          <div className="flex flex-col text-center w-full mb-10">
            <h1 className="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900">
              High Frequency Word List
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
              The list of High Frequency Word for grade 1 to grade 3
            </p>
          </div>

          <Table 
          className="container"
          key={list.id}
          columns={columns}
          dataSource={list}
          size="large"
          pagination={list.length}
          />    

        </div>
      </section> */}

      {/* <Table columns={columns} dataSource={data} />; */}
      {/* <Modal
        open={visibalModal}
        title= {hfwId === null ? 'New High Frequency Word': 'Update High Frequency Word'}
        onCancel={handleCancel}
        footer={null}        
      >
      <Space direction="vertical" className="w-full">
        <Input 
          value={grade}
          placeholder='Grade'
          onChange={(e)=>{
            setGrade(e.target.value)
          }}
        />
        <Input
          value={week}
          placeholder='Week'
          onChange={(e)=>{
            setWeek(e.target.value)
          }}
        />

        <Input
          value={content}
          placeholder='Content'
          onChange={(e)=>{
            setContent(e.target.value)
          }}
        />
      </Space>
      <Space className="mt-5 flex justify-end">
        <Button
         onClick={handleCancel}
        >Cancel</Button>
        <Button 
          type="primary" 
          className="bg-sbb-dark-blue"
          onClick={handleSave}
          >{hfwId == null ? 'Add': 'Update'}</Button>
      </Space>
      </Modal> */}
    </>
  );
};

export default AdminDashboard;
