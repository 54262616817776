import "./Content.css";

const Article1Content4 = () => {
  return (
    <>
      <div className="content-body">
        <h3>សារទំនាក់ទំនង–សារខ្លឹមសារ៖</h3>
        <p>ពេលផ្ដល់ព័ត៌មានត្រឡប់ សារទំនាក់ទំនង និងសារខ្លឹមសារមានសារៈសំខាន់ដូចគ្នា។ ការប្រើប្រាស់ “សារទំនាក់ទំនង–សារខ្លឹមសារ" បង្កភាពងាយស្រួលក្នុងការផ្តល់ និងទទួលយកព័ត៌មានត្រឡប់ ។
          ឧទាហរណ៍៖ "ខ្ញុំដឹងថាអ្នកនឹងពិបាកស្តាប់រឿងនេះ (រក្សាទំនាក់ទំនងល្អ) ប៉ុន្តែខ្ញុំគិតថាអ្នកត្រូវចំណាយពេលបន្ថែមលើកាបង្កើនចំណេះដឹង ការយល់ដឹងរបស់អ្នកអំពីគណិតវិទ្យា (សារខ្លឹមសារ)។"
        </p>

        <p style={{fontWeight: 'bold'}}>ក-សារទំនាក់ទំនង៖</p>

        <p> ក្នុងពេលផ្តល់ព័ត៌មានត្រឡប់ ចាំបាច់ត្រូវមានទំនាក់ទំនងល្អដើម្បីឱ្យគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាមានភាពកក់ក្ដៅ ។ គ្រូប្រឹក្សាគរុកោ សល្យអាចរៀនសូតបានច្រើននៅពេលដែលពួកគេមានអារម្មណ៍កក់ក្តៅ 
          និងមិនភ័យខ្លាចក្នុងការធ្វើទំនាក់ទំនងជាមួយ ទីប្រឹក្សាគរុកោសល្យ ជំនាញ។ ទំនាក់ទំនងល្អអាចកើតមានឡើង នៅពេលដែលយើងប្រើប្រាស់ពាក្យពេចន៍បានសមរម្យ និងឥរិយាបថរួសរាយ រាក់ទាក់។ <br/>
          ទីប្រឹក្សាគរុកោសល្យអាចប្រើឃ្លាដូចខាងក្រោម៖
        </p>
        
        <ul className="list-style">
          <li>ខ្ញុំយល់ថាលោកគ្រូ/អ្នកគ្រូមានការពិបាក...</li>
          <li>ខ្ញុំចង់ជួយលោកគ្រូ/អ្នកគ្រូ…</li>
          <li>ខ្ញុំឃើញថាលោកគ្រូ/អ្នកគ្រូមានអារម្មណ៍មិនសូវសប្បាយ……</li>
          <li>លោកគ្រូ/អ្នកគ្រូនមិនគួរមានអារម្មណ៍មិនល្អចំពោះការបង្រៀនរបស់លោកគ្រូ/អ្នកគ្រូឡើយ....</li>
          <li>ខ្ញុំ​សូម​សរសើរ​អ្នក​ដែល​ព្យាយាម​ខ្លាំង​ណាស់…</li>
        </ul>

        <p style={{fontWeight: 'bold'}}>ខ. សារខ្លឹមសារ៖</p>
        <p>ក្នុងពេលផ្ដល់ប្រឹក្សាគរុកោសល្យអ្នកចាំបាច់ត្រូវផ្ដល់ព័ត៌មានត្រឡប់ ប្រាប់ពីចំណុចកែលម្អ។ ចំណុចដែលត្រូវកែលម្អទាំងនេះអាចជាព័ត៌មាន និងជាខ្លឹមសារ 
          ដែលមានប្រយោជន៍ដល់គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាដើម្បីយកទៅអនុវត្ត។ ឧទាហរណ៌៖ សារខ្លឹមសារ៖
        </p>

        <ul className="list-style">
          <li>ខ្ញុំបានកត់សម្គាល់ឃើញថាអ្នកមានការលំបាកគ្រប់គ្រងថ្នាក់រៀន។</li>
          <li>សំឡេងរបស់អ្នកគឺខ្សោយពេក។</li>
          <li>ខ្ញុំគិតថាអ្នកមិនបានប្រើប្រាស់សម្ភារៈឧបទេសមិនទាន់បានគ្រប់គ្រាន់ទេ។</li>
          <li>ខ្ញុំ​គិត​ថា អ្នក​មិន​បាន​​យក​ចិត្ត​ទុក​ដាក់​មើលថាសិស្សពាក់កណ្តាលថ្នាក់មិនបានយកចិត្តទុកដាក់ស្តាប់អ្នក។</li>
          <li>ខ្ញុំគិតថា អ្នកគួរតែត្រៀមរៀបចំកិច្ចតែងការបង្រៀនអោយបានប្រសើរជាងនេះ។</li>
          <li>ខ្ញុំគិតថាអ្នកមិនបានកែលម្អតាមអនុសាសន៍ខ្ញុំដែលបានផ្តល់ពីលើកមុនទេ។</li>
          <li>អ្នកត្រូវតែពិនិត្យអក្ខរាវិរុទ្ធរបស់អ្នឡើងវិញ។</li>
          <li>ខ្ញុំគិតថាអ្នកត្រូវកែលម្អក្នុងការសរសេរលើក្តារខៀនឡើងវិញ។</li>
        </ul>

        <p style={{fontWeight: 'bold'}}>គ- ឧទាហរណ៍ការផ្សារភ្ជាប់ “សារទំនាក់ទំនង- សារខ្លឹមសារ</p>
        <p> ជាញឹកញយទីប្រឹក្សាគរុកោសល្យជំនាញសង្កេតឃើញគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាមានការតាំងចិត្តល្អ ប៉ុន្តែសកម្មភាព ឬការប្រព្រឹត្ត របស់គាត់មិនទាន់បានល្អនៅឡើយ។ ឧទាហរណ៍</p>

        <ul className="list-style">
          <li>"ខ្ញុំមើលឃើញថាអ្នកមានការតាំងចិត្តល្អ (សារទំនាក់ទំនង) ប៉ុន្តែខ្ញុំគិតថា ល្បែងសិក្សា ដែលប្អូនយកមកលេងនេះ មិនទាន់ ស្របតាមកម្រិតថ្នាក់ទេ (សារខ្លឹមសារ) ។</li>
          <li>“ខ្ញុំដឹងថានេះគឺជាជំនាញមានការលំបាក ប៉ុន្តែខ្ញុំពិតជាចង់ឱ្យអ្នកព្យាយាមព្រមទាំងសំឡឹងមើលសិស្សផងនៅពេលអានអំណានគំរូ”។</li>
          <li>ខ្ញុំអាចសង្កេតឃើញថាអ្នកកំពុងខំប្រឹងហើយ តែអ្នកត្រូវការកែលម្អជំនាញសរសេរលើក្តារដើម្បីឱ្យសិស្សអាចមើលឃើញទាំងអស់គ្នា។</li>
        </ul>
        
        <p> នៅពេលផ្តល់ព័ត៌មានត្រឡប់ ទីប្រឹក្សាគរុកោសល្យជំនាញត្រូវ៖</p>
        <ul className="list-style">
          <li>ធ្វើវាឱ្យបានញឹកញាប់ និងភ្លាមៗ - នៅពេលដែលទីប្រឹក្សាគរុកោសល្យជំនាញ និងគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលា ពិភាក្សា គ្នាឱ្យបានញឹកញាប់អំពីចំណុចត្រូវកែលម្អបែបស្ថាបនាយចំនួនដើម្បីកែលម្អ 
            វានឹងក្លាយទៅជាទម្លាប់ល្អមួយ។ ប្រសិនបើ ទីប្រឹក្សាគរុកោសល្យជំនាញរង់ចាំដល់យូរៗទើបផ្តល់ព័ត៌មានត្រឡប់ម្តង ហើយលើកឡើងពីមតិកែលម្អច្រើនលើសលប់ពេក វាធ្វើឱ្យ 
            គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលា មានការភ្ញាក់ផ្អើល និងមិនសប្បាយចិត្ត ។ ទីប្រឹក្សាគរុកោសល្យជំនាញ មិនត្រូវរង់ចាំយូរពេក នោះទេ ពីព្រោះគាត់អាចនឹងភ្លេច 
            ពីចំណុចដែលត្រូវផ្ដល់ឱ្យគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាដើម្បីធ្វើការកែលម្អ ហើយ គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាក៏អាចភ្លេច សកម្មភាពបង្រៀនរបស់ខ្លួនដែរ ។</li>
          <li>ធ្វើបន្តិចម្តងៗ-ទីប្រឹក្សាគរុកោសល្យជំនាញមិនត្រូវលើកឡើងពីចំណុចដែលត្រូវកែលម្អច្រើនពេកទេ ព្រោះវាធ្វើឱ្យ គ្រូប្រឹក្សាគរុកោសល្យ មានអារម្មណ៍ថាច្រើនហួសពេកដែលមិនអាចយកទៅអនុវត្តបានគ្រប់ចំណុច។ 
            ពេលផ្តល់ព័ត៌មាន ត្រឡប់ទីប្រឹក្សាគរុកោសល្យជំនាញ ត្រូវផ្តល់អនុសាសន៍មិនឱ្យលើសពី បីចំណុចដែលចាំបាច់បំផុតនោះទេ ។</li>
          <li>ប្រើសារឱ្យបានច្បាស់លាស់-សារទំនាក់ទំនង និងសារខ្លឹមសារត្រូវតែមានភាពច្បាស់លាស់ដើម្បីកែលម្អ និងជួយគាំទ្រដល់គ្រូ ប្រឹក្សាគរុកោសល្យប្រចាំសាលា។</li>
        </ul>
      </div>
    </>
  );
};

export default Article1Content4;
