import "./Content.css";

const Article1Content2 = () => {
  return (
    <>
      <div className="content-body">
        <h3>ការប្រជុំប្រឹក្សាគរុកោសល្យប្រចាំខែ</h3>
        <p>ការប្រជុំស្ដីពីការការប្រឹក្សាគរុកោសល្យប្រចាំខែមានគោលបំណងដើម្បីធ្វើការចែករំលែកពីការរីកចម្រើន និងបញ្ហាប្រឈមក្នុង ការងារប្រឹក្សាគរុកោសល្យ និងចែករំលែកនូវចំណេះដឹងថ្មី ក៏ដូចជាវិធីសាស្រ្តបង្រៀនល្អៗ។</p>

        <h5 style={{fontWeight: 'bold', color: '#004587'}}>មុនពេលប្រជុំ</h5>
        <ul className="list-style">
          <li>កំណត់កាលបរិច្ឆេទ</li>
          <li>ធ្វើការអញ្ជើញគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាដើម្បីឱ្យចូលរួមការប្រចាំ</li>
          <li>ប្រាប់អ្នកចូលរួមឱ្យយកឯកសារត្រូវការនៅពេលមកប្រជុំដែលពួកគាត់ត្រូវយកមកជាមួយ។</li>
          <li>ប្រើព័ត៌មាន ឬទិន្នន័យបានមកពីការប្រជុំមុនៗ កម្រិតជំនាញ និងការសង្កេតដើម្បីយកមករៀបចំរបៀបវារៈ</li>
          <li>កំណត់រកអ្នកសម្របសម្រួលការប្រជុំ</li>
          <li>ជ្រើសរើសប្រធានអង្គប្រជុំ (ប្តូរវេនគ្នា)</li>
          <li>រៀបចំរបៀបវារៈប្រជុំ(បញ្ហា និងដំណោះស្រាយ)</li>
        </ul>

        <h5 style={{fontWeight: 'bold', color: '#004587'}}>អំឡុងពេលប្រជុំ</h5>
        <ul className="list-style">
          <li>លើកទឹកចិត្តឱ្យអ្នកចូលរួមមានការចូលរួមសកម្មក្នុងការប្រជុំ</li>
          <li>បែងចែកក្រុមការងារ សម្រាប់ពិភាក្សា(តាមក្រុម តូច ក្រុមធំ)</li>
          <li>ឱ្យសិក្ខាកាមឡើងឆ្លុះបញ្ចាំង</li>
          <li>អ្នកទទួលខុសត្រូវដែលត្រូវធ្វើការសម្របសម្រួលការប្រជុំ</li>
          <li>លើកទឹកចិត្តឱ្យអ្នកចូលរួមចូលរួមដោះស្រាយបញ្ហា អំឡុងពេលប្រជុំ។</li>
          <li>ពិភាក្សា ដោះស្រាយនិងធ្វើអតិភាពបញ្ហាដែលត្រូវដោះស្រាយ</li>
          <li>ប្រមូលទម្រង់សង្កេតពីគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាដែលបានចុះធ្វើការផ្តល់ប្រឹក្សាគរុកោសល្យ ដើម្បីធ្វើការបូក សរុបសង្ខេបលទ្ធផលការងារប្រជុំប្រឹក្សាគរុកោសល្យប្រចាំ។</li>
          <li>ប្រមូលផែនការសកម្មភាពពីគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលា (បង្កើតផែនការដើម្បីធ្វើការតាមដាននិងជួយតាមសាលា)</li>
          <li>មតិចូលរួមពីការិយាល័យអប់រំ យុវជននិងកីឡាស្រុក ព្រមទាំងអាចធ្វើការផ្តល់ដំណោះស្រាយផងដែរ បើសិនអាចទៅរួច។</li>
        </ul>

        <h5 style={{fontWeight: 'bold', color: '#004587'}}>ក្រោយពេលប្រជុំ</h5>
        <ul className="list-style">
          <li>ធ្វើការឆ្លុះបញ្ចាំងជាទូទៅពីការប្រជុំ</li>
          <li>ធ្វើរបាយការណ៍</li>
          <li>រៀបចំការប្រជុំលើកក្រោយ</li>
        </ul>
      </div>
    </>
  );
};

export default Article1Content2;
