import "./Content.css";

const Article1Content7 = () => {
  return (
    <>
      <div className="content-body">
        <h3>វិធីសាស្ត្រក្នុងការផ្ដល់ព័ត៌មានត្រឡប់</h3>
        <h5 style={{fontWeight: 'bold', color: '#004587'}}>វិធីសាស្រ្តកញ្ចក់ឆ្លុះ៖</h5>
        <p>“កញ្ចក់ឆ្លុះបញ្ចាំង ជាវិធីដ៏មានប្រសិទ្ធភាពមួយក្នុងការផ្ដល់ព័ត៌មានត្រឡប់ ។ វាដូចជាការ ដាក់កញ្ចក់នៅពីមុខនរណាម្នាក់ ដូចនេះ គេអាច ធ្វើការឆ្លុះបញ្ចាំងពីឥរិយាបថរបស់ខ្លួនបាន។ 
          ទីប្រឹក្សាគរុកោសល្យជំនាញជួយគ្រូប្រឹក្សាគរុកោសល្យ ឱ្យចេះឆ្លុះបញ្ចាំងសកម្មភាព របស់ខ្លួន ឡើងវិញ នូវអ្វីដែលកគ្រូប្រឹក្សាគរុកោសល្យបានធ្វើ ឬប្រព្រឹត្តកន្លងមកដោយប្រាប់នូវអ្វីដែលទីប្រឹក្សាគរុ 
          កោសល្យបានសង្កេត ឃើញ ។ គ្រូប្រឹក្សាគរុកោសល្យអាចប្រើវិធីផ្សេងៗក្នុងការធ្វើការឆ្លុះបញ្ចាំងដូចខាងក្រោម៖</p>

        <h5 style={{fontWeight: 'bold', color: '#004587'}}>វិធីសាស្រ្តនិយាយតាម៖</h5>
        <p>គឺជាការនិយាយតាម (ថាតាម) នូវចំណុចសំខាន់ៗដែលគ្រូប្រឹក្សាគរុកោសល្យបានលើកឡើង រួចសួរជាសំណួរ។ 
          ឧទាហរណ៍ៈ “អ្នកបាននិយាយថាការគ្រប់គ្រងថ្នាក់រៀនគឺជាការសំខាន់។ តើអ្នកមានផែនការ ធ្វើការគ្រប់គ្រងថ្នាក់រៀនដោយ របៀបណា?”</p>

        <h5 style={{fontWeight: 'bold', color: '#004587'}}>វិធីសាស្រ្តនិយាយបញ្ជាក់៖</h5>
        <p>គឺជាការនិយាយបញ្ជាក់ឡើងវិញនូវមតិយោបល់របស់គ្រូប្រឹក្សាគរុកោសល្យ។ វាអាចជួយគ្រូប្រឹក្សាគរុកោសល្យឱ្យចេះឆ្លុះ បញ្ចាំង បន្ថែមទៀត និងធ្វើឱ្យមានគំនិតកាន់តែក្នុងដំណើរការឆ្លុះបញ្ចាំង។</p>
        <p>ឧទាហរណ៍៖ គ្រូប្រឹក្សាគរុកោសល្យបាននិយាយថា៖ “ខ្ញុំមានអារម្មណ៍ថាថ្ងៃនេះ ខ្ញុំគ្រប់គ្រងថ្នាក់រៀនមិន បានល្អទេ ព្រោះសិស្ស មិនបានយកចិត្តទុកដាក់ស្តាប់សោះនៅពេលខ្ញុំពន្យល់”។ ទីប្រឹក្សាគរុកោសល្យអាចនិយាយបញ្ជាក់ឡើងវិញថា៖ 
          “អ៊ីចឹងលោក/អ្នកគ្រូចង់ឱ្យសិស្សបានចូលរួមសកម្មភាពជាងនេះបន្ថែមទៀតនៅថ្ងៃនេះ!”។</p>

        
        <h5 style={{fontWeight: 'bold', color: '#004587'}}>វិធីសាស្រ្តនិយាយសង្ខេប៖</h5>
        <p>គឺជាការនិយាយសង្ខេបពីការសន្ទនា ឬសកម្មភាពដែលបានធ្វើ។ 
          ឧទាហរណ៍៖គ្រូប្រឹក្សាគរុកោសល្យបានប្រាប់បញ្ហាដែលបានកើតឡើងនៅក្នុងថ្នាក់ជាមួយកុមារម្នាក់ដោយផ្តល់ព័ត៌មានលម្អិត ជាច្រើន។គ្រូប្រឹក្សាគរុកោសល្យនិយាសង្ខេប​ថា៖“អ្នក​បាន​និយាយ​ថាមិនសប្បាយចិត្តជាមួយសិស្សដោយ​សារ​តែសិស្សមាន 
          ​ឥរិយាបថមិនសមរម្យ។ បន្ទាប់មកអ្នកបាននិយាយជាមួយពួកគេរួចហើយ។ ហើយឥឡូវនេះអ្នកនៅតែបារម្ភពីឥរិយាបថ របស់ពួកគេនាពេលអនាគត។</p>

          <h5 style={{fontWeight: 'bold', color: '#004587'}}>វិធីសាស្រ្តកញ្ចក់ឆ្លុះបញ្ចាំងទាំងបីប្រភេទ៖</h5>
          <p>សកម្មភាពឆ្លើយតបខាងលើអាចអនុវត្តតាមមធ្យោបាយផ្សេងៗគ្នា។ទីប្រឹក្សាគរុកោសល្យជំនាញអាចជ្រើសរើស ការឆ្លុះ បញ្ចាំង ទៅលើអារម្មណ៍របស់គ្រូប្រឹក្សាគរុកកោសល្យប្រចាំសាលាបានបង្ហាញ(កញ្ចក់កក់ក្តៅ)ឬ ការឆ្លុះបញ្ចាំងទៅលើភាពជាក់ 
            ស្តែង និងខ្លឹមសារ(កញ្ចក់ត្រជាក់) ឬការការឆ្លុះបញ្ចាំងដោយនិយាយពង្រីកនូវអ្វីដែលបានសង្កេតឃើញ (កញ្ចក់ពង្រីក)។</p>

        <h5 style={{fontWeight: 'bold', color: '#004587'}}>កញ្ចក់កក់ក្តៅ (ផ្តោតលើការយល់ចិត្ត និងការអធ្យាស្រ័យ)</h5>
        <p>ទីប្រឹក្សាគរុកោសល្យជំនាញ ពិពណ៌នាពាក្យសម្ដី កាយវិការ និង អត្តចរិតខាងក្រៅរបស់គ្រូប្រឹក្សាគរុកោសល្យ ប្រចាំសាលា ឱ្យបានច្បាស់លាស់ដែលអាចធ្វើបាន។ ត្រង់ចំណុចនេះ ទីប្រឹក្សាគរុកោសល្យជំនាញបង្ហាញការយល់ចិត្ត 
          និងការអធ្យាស្រ័យទៅកាន់គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលា។</p>

        <p><span style={{fontWeight: 'bold', color: '#004587'}}>កញ្ចក់ត្រជាក់</span>(​ផ្តោតលើភាពជាក់ស្តែង និងខ្លឹមសារ មិនផ្តោតលើអារម្មណ៍របស់គ្រូប្រឹក្សាគរុកោសល្យទេ)
          ទីប្រឹក្សាគរុកោសល្យជំនាញបរិយាយតែរឿងពិតជាក់ស្តែង និងអ្វីដែលបានសង្កេតឃើញ។ ទីប្រឹក្សាគរុកោសល្យជំនាញ ផ្តោតការយកចិត្តទុកដាក់តែលើការដោះស្រាយបញ្ហាជាជាងផ្តោលើអារម្មណ៍របស់គ្រូប្រឹក្សាគរុកោសល្យ ។
          </p> 
        
        <p><span style={{fontWeight: 'bold', color: '#004587'}}>កញ្ចក់ពង្រីក</span>(និយាយពង្រីកនូវអ្វីដែលអ្នកបានឃើញ និងឮ ហើយធ្វើការបកស្រាយ)
        កញ្ចក់ពង្រីករៀបចំឡើងដើម្បីពង្រីកនូវអ្វីដែលទីប្រឹក្សាគរុកោសល្យជំនាញបានសង្កេតឃើញ ។ ក្រោយមកទីប្រឹក្សាគរុកោសល្យ ជំនាញ សួរថាការបកស្រាយនូវអ្វីដែលគាត់បានសង្កេតឃើញត្រឹមត្រូវដែរឬទេ។ 
        តាមការបង្ហាញបញ្ហាពង្រីកបញ្ហានោះឱ្យធំជាងមុន ហើយសួរ​បញ្ជាក់ គ្រូប្រឹក្សាគរុកោសល្យប្រហែលជាអាចឃើញបញ្ហានោះកាន់តែច្បាស់។
        </p> 

        <p>នៅពេលដែលគ្រូប្រឹក្សាគរុកោសល្យមានឱកាសសម្តែងអារម្មណ៍ ពួកគេអាចគិតថាមានគេយល់អារម្មណ៍របស់ខ្លួន និងមានការ គាំទ្រ(កញ្ចក់កក់ក្តៅ)។ ក្នុងករណីគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាគ្រាន់តែចង់ដោះស្រាយបញ្ហារបស់ពួកគេ 
          ដោយមិនចង់ពិភាក្សាពីអារម្មណ៍ របស់ពួក គេឡើយ(កញ្ចក់ត្រជាក់)</p>
        
        <p>ការនិយាយពង្រីកបញ្ហាអ្វីមួយ វាអាចជួយឱ្យគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាឱ្យមើលឃើញបញ្ហានោះកាន់តែច្បាស់។ វាក៏អាច ជួយគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាឱ្យមើលឃើញថាអ្វីៗទាំងអស់ មិនសុទ្ធតែអាក្រក់ដូចការគិតរបស់ខ្លួនឡើយ 
          និងធ្វើឱ្យពួកគេចាប់ផ្តើមគិតរកដំណោះស្រាយងាយៗ​តាមលទ្ធភាពដែលខ្លួនមាន។ (កញ្ចក់ពង្រីក)</p>

        <h5 style={{fontWeight: 'bold', color: '#004587'}}>ការប្រើប្រាស់សារផ្តើមដោយពាក្យ “ខ្ញុំ”</h5>
        <p> ស្រដៀងគ្នាទៅនឹងខ្លឹមសារកសាងទំនាក់ទំនងគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាអាចប្រើប្រាស់សារផ្តើមដោយប្រើពាក្យថា “ខ្ញុំ” ដើម្បីរក្សាបរិយាកាសបែបសហការ។ “សារផ្តើមដោយពាក្យខ្ញុំ” មានប្រសិទ្ធភាពជាង “សារផ្តើមដោយពាក្យអ្នក” 
          ជាពិសេសនៅ ពេលអ្នកលើកឡើងពីឥរិយាបថរបស់នរណាម្នាក់។</p>

        <p>ឧទាហរណ៍៖ ប្រសិនបើទីប្រឹក្សាគរុកោសល្យជំនាញនិយាយថា “អ្នកមិនបានប្រើប្រាស់សម្ភារឧបទេស ឱ្យបានគ្រប់គ្រាន់ នោះទេ” គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលានឹងមានអារម្មណ៍ថាខ្លួនទទួលបានការស្តីបន្ទោស ហើយមានអារម្មណ៍ភ័យព្រួយ។ 
          ប្រសិនបើប្រសិនបើទីប្រឹក្សាគរុកោសល្យជំនាញនិយាយថា “ ខ្ញុំមានការពេញចិត្តជាងនេះ ប្រសិនបើអ្នកប្រើសម្ភារ ឧបទេស ឱ្យបាន ច្រើនជាងមុន”។ គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលានឹងមានអារម្មណ៍ថាមានការលើកទឹកចិត្តក្នុងការអនុវត្តសកម្មភាព ផ្សេងៗបន្តទៀត។</p>
        
        <p>ការប្រើប្រាស់ “សារផ្តើមដោយប្រើពាក្យខ្ញុំ…” ទីប្រឹក្សាគរុកោសល្យជំនាញហាក់ដូចជាចូលរួមចំណែកទទួលខុសត្រូវយកបញ្ហា របស់គ្រូប្រឹក្សាគរុកោសល្យដើម្បីរកដំណោះស្រាយបញ្ហារួមគ្នា។ ទីប្រឹក្សាគរុកោសល្យជំនាញប្រើសារផ្តើមដោយពាក្យខ្ញុំគឺមិន វាយប្រហារខ្លាំងទៅគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលា ផ្ទុយទៅវិញវាជាការបង្ហាញទំនាក់ទំនងល្អក្នុងការដោះស្រាយបញ្ហារួមគ្នា។
        ប្រសិនបើទីប្រឹក្សាគរុកោសល្យជំនាញប្រើសារផ្តើមដោយពាក្យខ្ញុំនោះគ្រូប្រឹក្សាគរុកោសល្យមិនបដិសេដលើការផ្តល់ព័ត៌មាន ត្រឡប់របស់គាត់ពីព្រោះវាឆ្លុះបញ្ចាំងលើអារម្មណ៍និងគំនិតរបស់អ្នកនិយាយ។ ជាលទ្ធផលបានមកវិញគ្រូប្រឹក្សាគរុកោសល្យ បង្ហាញពីការទទួលយកទំនួលខុសគ្រូវលើការបញ្ហាការងារផ្តល់ប្រឹក្សាគរុកោសល្យនិងជួយស្វែងរកដំណោះស្រាយរួមគ្នា។
        </p>

        <p style={{fontWeight: 'bold', color: '#004587'}}>សារដោយផ្តើមប្រើពាក្យខ្ញុំ…</p>
        <ul className="list-style" style={{ listStyle: "decimal" }}>
          <li>មិនធ្វើឱ្យគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាមានអារម្មណ៍ត្រូវបានវាយប្រហារ។</li>
          <li>ធ្វើឱ្យគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាបើកចិត្តទូលាយ ក្នុងការស្តាប់និងកែលម្អ។</li>
          <li>ធ្វើឱ្យគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាយល់ពីបញ្ហាតាមរយៈអ្វីដែលអ្នកនិយាយ។</li>
        </ul>

        <p>ឧទាហរណ៍៖ "ខ្ញុំ​ពេញចិត្តនៅ​ពេល​អ្នក​មក​ដល់​ថ្នាក់​ទាន់​ពេល។"</p>
        <h5 style={{fontWeight: 'bold', color: '#004587'}}>សារដោយផ្តើមប្រើពាក្យអ្នក…</h5>
        
        <ul className="list-style" style={{ listStyle: "decimal" }}>
          <li>ធ្វើឱ្យគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាបដិសេដ ឬនិយាយការពារខ្លួន និងមានអារម្មណ៍ខ្វះទំនុកចិត្ត។</li>
          <li>ធ្វើឱ្យគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាមិនយកចិត្តទុកដាក់បំពេញការងារ។</li>
          <li>ដាក់កំហុសឱ្យគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលា</li>
        </ul>
        <p>ឧទាហរណ៍៖ "អ្នកតែងតែមកយឺត។"</p>
        <p><span style={{fontWeight: 'bold', color: '#004587'}}>ការប្តូរពីសារផ្ដើមដោយពាក្យអ្នក... មកសារផ្ដើមដោយពាក្យខ្ញុំ</span><br/>
        សារផ្ដើមដោយពាក្យអ្នក : អ្នកមិនបានប្រគល់កិច្ចតែងការបង្រៀនឱ្យខ្ញុំទាន់ពេលវេលា ។<br/>
        សារផ្តើមដោយពាក្យខ្ញុំៈខ្ញុំមិនអាចត្រៀមរៀបចំការសង្កេតការបង្រៀនបានល្អ ប្រសិនបើ ខ្ញុំមិនបានទទួលកិច្ចតែងការបង្រៀនទាន់វេលា ។<br/>
        សារផ្ដើមដោយពាក្យអ្នកៈ អ្នកប្រើសម្ភារឧបទេសតូចៗពេកក្នុងពេលបង្រៀន ។ <br/>
        សារផ្តើមដោយពាក្យខ្ញុំៈ  ខ្ញុំគិតថាសិស្សមានភាពងាយស្រួលក្នុងការមើល ប្រសិនបើសម្ភារៈ ឧបទេសទាំងនោះធំជាងនេះ ។<br/>
        សារផ្ដើមដោយពាក្យអ្នកៈ អ្នកមិនបានឆ្លើយនូវសំណួររបស់ខ្ញុំទេ ។ <br/>
        សារផ្ដើមដោយពាក្យខ្ញុំៈ ខ្ញុំមានការពិបាក អ្វីដែលខ្ញុំនៅតែមិនដឹង..។<br/>
        </p>
        
        <span className="right">
          <i>
          (ឯកសារបំណិនផ្តល់ព័ត៌មានត្រឡប់ ឆ្នាំ២០១៦)
          </i>
        </span>

      </div>
    </>
  );
};

export default Article1Content7;
