import { CheckCircleIcon } from "@heroicons/react/20/solid";

const benefits = [
  "ធនធានថ្នាក់រៀន",
  "ធានធានគណិតវិទ្យា",
  "អភិវឌ្ឍន៍ជំនាញ",
  "ផែនការបង្រៀន",
  "អំណាន",
  "ចំណេះដឹងទូទៅ",
];

export default function About() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-3xl px-4 py-24 sm:px-6 sm:py-24 lg:max-w-7xl">
        <div className="relative overflow-hiden rounded-lg lg:h-[428px]">
          <div className="absolute inset-0">
            <img
              src={require("../assets/images/about_home.jpg")}
              alt=""
              className="h-full w-full object-cover object-center rounded-lg"
            />
          </div>
          <div aria-hidden="true" className="relative h-96 w-full lg:hidden" />
          <div aria-hidden="true" className="relative h-32 w-full lg:hidden" />
          
          <div className="absolute inset-x-0 bottom-0 rounded-bl-lg rounded-br-lg bg-black bg-opacity-25 p-6 backdrop-blur backdrop-filter sm:flex sm:items-center sm:justify-between lg:inset-x-auto lg:inset-y-0 lg:w-96 lg:flex-col lg:items-start lg:rounded-br-none lg:rounded-tl-lg">
            <div>
              <h2 className="text-xl font-bold text-white text-center"> បណ្ណាល័យសិក្សាឌីជីថល</h2>
              <p className="mt-1 text-sm text-gray-300 tex-center">
              បណ្ណាល័យសិក្សាឌីជីថល រួមចំណែកលើកកម្ពស់គុណភាពអប់រំ និងបំពេញបន្ថែមកិច្ចខិតខំប្រឹងប្រែងក្នុងការអភិវឌ្ឍសមត្ថភាពគ្រូបង្រៀន និងការសិក្សារបស់កុមារ។ បណ្ណាល័យសិក្សាឌីជីថលជាបណ្តុំធនធានសម្រាប់ បង្រៀន និងអភិវឌ្ឍជំនាញ៖
              </p>

              <div className="w-full flex-auto">
                <ul                
                className="grid grid-cols-1 gap-x-4 gap-y-2 text-base leading-7 text-white sm:grid-cols-2"
              >
                {benefits.map((benefit) => (
                  <li key={benefit} className="flex gap-x-2">
                    <CheckCircleIcon
                      className="h-7 w-5 flex-none"
                      aria-hidden="true"
                    />
                    {benefit}
                  </li>
                ))}
              </ul>
            </div>
            </div>
            <a
              href="https://seebeyondborders.org/" target="_Blank" rel="noreferrer"
              className="mt-6 flex flex-shrink-0 items-center justify-center rounded-md border border-white border-opacity-25 bg-sbb-blue bg-opacity-0 px-4 py-3 text-base font-medium text-white hover:bg-opacity-10 sm:ml-8 sm:mt-0 lg:ml-0 lg:w-full"
            >
              អានបន្ថែម
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
