import "./Content.css";

const Article1Content7 = () => {
  return (
    <>
      <div className="content-body">
        <h3>បំពេញទម្រង់សង្កេត</h3>
        <p>
          អំឡុងពេលសង្កេតគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលា
          ទីប្រឹក្សាគរុកោសល្យបំពេញទម្រង់សង្កេតដើម្បីធ្វើកំណត់ត្រានៃការចុះជួយ។
        </p>

        <ul className="list-style" style={{ listStyle: "decimal" }}>
          <li>សង្កេតគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលានៅពេលចុះ។</li>
          <li>
            គូសរង្វង់លើពាក្យថា “មាន” ឬ”មិនមាន” ចំពោះសំណួរខាងក្រោម
            ដោយផ្អែកលើការសង្កេតរបស់អ្នក។
          </li>
          <li>
            សង្កេតសូចនាករកម្រិត១មុនដំបូង។នៅពេលគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាបង្ហាញបាននូវសូចនាករកម្រិត1ទាំង
            អស់(នៅពេលសូចនាករកម្រិត១ទាំងអស់ត្រូវបានគូសរង្វង់ពាក្យថា“មាន”)
            បន្ទាប់មកសង្កេតមើលសូចនាករកម្រិត១និងកម្រិត២ទាំងអស់។នៅពេលដែលគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាបង្ហាញបាននូវសូចនាករកម្រិត១និងកម្រិត២ទាំង
            អស់)
          </li>
          <li>
            នៅពេលដែលសូចនាករកម្រិត១និងកម្រិត២ទាំងអស់ត្រូវបានគូសរង្វង់ពាក្យថា"មាន")បន្ទាប់មកសង្កេតមើល
            សូចនាករកម្រិត ១, ២, និង ៣ ទាំងអស់។
          </li>
          <li>
            ប្រើទម្រង់សង្កេតនេះដើម្បីធ្វើការផ្តល់ព័ត៌មានត្រឡប់ទៅដល់គ្រូប្រឹក្សាគរុកោសល្យនូវសូចនាករចំនួនមួយដែលអាចយកទៅកែលម្អ
            សូចនាករទីដំបូងគេដែលមានគូសរង្វង់ថា មិនមានខាងលើ។
          </li>
        </ul>

        <span className="right">
          <i>ឧបសម្ព័ន្ធ៤ ទម្រង់សង្កេតរបស់ទីប្រឹក្សាគរុកោសល្យ</i>
        </span>
      </div>
    </>
  );
};

export default Article1Content7;
