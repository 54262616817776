import "./Content.css";

const Article1Content6 = () => {
  return (
    <>
      <div className="content-body">
        <h3>សង្កេតការផ្តល់ប្រឹក្សាគរុកោលស្យនិងផ្តល់ព័ត៌មានត្រឡប់</h3>
        <p>
          ទីប្រឹក្សាគរុកោសល្យជំនាញធ្វើការសង្កេតគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាម្នាក់ៗ
          ព្រមទាំងជួបជាមួយ នាយកសាលា ចំនួន៣ដងក្នុងមួយឆ្នាំ
          (អាស្រ័យទៅតាមចំនួនកម្រង)
        </p>

        <h4>មុនពេលសង្កេត៖</h4>
        <ul className="list-style">
          <li>ពិនិត្យមើលព័ត៌មានត្រឡប់កាលពីលើកមុន</li>
          <li>
            សួរគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាថាតើបានត្រៀមអ្វីខ្លះនៅពេលចុះជួយលើកនេះ(ចូរមើលសំណួរនៅខាងក្រោម)
          </li>
          <li>
            សួរថាតើគ្រូមានការរីកចម្រើនដែរឬទេ​(សូមមើលផែនការសកម្មភាពរបស់គ្រូបង្រៀន)
          </li>
        </ul>

        <h4>អំឡុងពេលសង្កេត៖</h4>
        <ul className="list-style">
          <li>
            សង្កេតគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាក្នុងមួយពេលចុះផ្តល់ប្រឹក្សាគរុកោសល្យដល់គ្រូបង្រៀន
            ព្រមទាំងធ្វើការបំពេញទម្រង់
            សង្កេត(មិនត្រូវធ្វើឱ្យរំខានដល់ការងារប្រឹក្សាគរុកោសល្យរបស់គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាឡើយ)
          </li>
          <li>
            សង្កេតគ្រូប្រឹក្សាគរុកោសល្យព្រមទាំងធ្វើការកត់ត្រាចំណាំនូវសកម្មភាពរបស់គ្រូប្រឹក្សាគរុកោសល្យផងដែរ។
          </li>
          <li>
            ធ្វើការពិចារណាលើព័ត៌មានត្រឡប់ដែលអ្នកនឹងផ្តល់ឱ្យគ្រូប្រឹក្សាគរុកោសល្យ
            ព្រមទាំងត្រៀមខ្លួនធ្វើការផ្តល់ព័ត៌មានត្រឡប់។
          </li>
          <li>
            ពិចារណាលើគោលដៅឯកភាពដែលអ្នកនឹងកំណត់ព្រមទាំងធ្វើការសម្រេចចិត្ត
            ពិនិត្យថាគោលដៅឯកភាពចាស់បានសម្រេចហើយ ឬនៅ។
          </li>
        </ul>

        <h4>ក្រោយពេលសង្កេត៖</h4>
        <ul className="list-style">
          <li>
            ជួបជាមួយគ្រូប្រឹក្សាគរុកោសល្យ
            (យកល្អគឺធ្វើឡើងនៅថ្ងៃតែមួយនឹងថ្ងៃចុះជួយ)
            ពិភាក្សាលើអ្វីដែលធ្វើបានជោគជ័យនិង ការងារលំបាកដែលសង្កេតឃើញ។
          </li>
          <li>
            សួរគ្រូប្រឹក្សាគរុកោសល្យថាតើការរីកចម្រើនរបស់គ្រូបង្រៀនទៅដល់កម្រិតណាហើយបើធៀបទៅនឹងកាលបង្រៀនពេលមុន។
          </li>
          <li>ផ្តល់ព័ត៌មានត្រឡប់ទៅឱ្យគ្រូប្រឹក្សាគរុកោសល្យ។</li>
          <li>
            បំពេញទម្រង់ព្រមទាំងផ្ញើឯកសារនោះឱ្យប្រធានកម្រង
            និងមួយច្បាប់ទៀតផ្ញើឱ្យការិយាល័យអប់រំ យុវជននិងកីឡា។
          </li>
        </ul>
      </div>
    </>
  );
};

export default Article1Content6;
