import { Link } from 'react-router-dom'
const files = [
    {
      title: 'កិច្ចតែងការ',
      url: 'lsplan',
      source: require('../../assets/images/teachResource/bg-rsc-1.jpg'),
    },
    {
        title: 'សៀវភៅធំ',
        url: '/dashboard/bigbook',
        source: require('../../assets/images/teachResource/bg-rsc-2.jpg'),
      },
      {
        title: 'ពាក្យប្រើញឹកញាប់',
        url: '/dashboard/hfw',
        source: require('../../assets/images/teachResource/bg-rsc-3.jpg'),
      },
      {
        title: 'ពាក្យពិបាក',
        url: '/dashboard/dfw',
        source: require('../../assets/images/teachResource/bg-rsc-4.jpg'),
      },
      {
        title: 'ក្បួនប្រកប និងអាន',
        url: '/blending/spelling',
        source: require('../../assets/images/teachResource/bg-rsc-5.jpg'),
      },
      {
        title: 'ចម្រៀងព្យញ្ជនៈ',
        url: '/blending/consonant-song',
        source: require('../../assets/images/teachResource/bg-rsc-6.jpg'),
      },
      {
        title: 'របៀបសរសេរព្យញ្ជនៈ',
        url: '/blending/how-to-write',
        source: require('../../assets/images/teachResource/bg-rsc-7.jpg'),
      },
      {
        title: 'ផ្ទាំងព្យញ្ជនៈ',
        url: '/blending/consonant-banner',
        source: require('../../assets/images/teachResource/bg-rsc-8.jpg'),
      }
  ]
  export default function TechResc() {
    return (
        <>
            <div className="bg-white px-6 pt-16 sm:pt-15 lg:px-8">
                <div className="mx-auto max-w-2xl text-center">
                    <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">ធនធានសម្រាប់ថ្នាក់រៀន</h2>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                        ធនធានសម្រាប់ថ្នាក់រៀន​ជាធនធានសម្រាប់បម្រើដល់ការបង្រៀននៅក្នុងថ្នាក់ជាប្រចាំថ្ងៃ។
                    </p>
                </div>
            </div>
            <div className="container py-20">                
                <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                    {files.map((file) => (
                    <li key={file.source} className="relative">
                        <Link to={file.url}>
                        <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-sbb-blue focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                            <img src={file.source} alt="" className="pointer-events-none object-cover group-hover:opacity-75" />
                        <button type="button" className="absolute inset-0 focus:outline-none">
                            <span className="sr-only">{file.title}</span>
                        </button>
                        </div>
                        <p className="pointer-events-none mt-2 block truncate text-2xl font-medium text-sbb-dark-blue text-center leading-10">{file.title}</p>                        
                        </Link>
                    </li>                    
                    ))}
                </ul>
            </div>
        </>
    )
  }
