import { useState, useRef, useEffect } from "react";
import { Carousel, Space, Button, Spin } from "antd";
import { useParams } from "react-router-dom";
import { BsFillHouseFill } from "react-icons/bs";
import { request } from "../util/api";

const HfwGradeTwoComp = () => {
  const { id } = useParams();

  //UseState
  const ref = useRef();
  const [active, setActive] = useState(0);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getByGradeTwo(); // call funcion getList
  }, []);

  // create a function fetch data from api
  const getByGradeTwo = () => {
    setLoading(true);
    request("get", "hfw/grade2/" + id).then((res) => {
      setList(res.data.list);
      setLoading(false);
    });
  };
  return (
    <>
      <div className="max-h-screen px-4 justify-center items-center">
        <div className="flex flex-col justify-center items-center">
          <div className="flex pt-4 justify-center">
            <a href="/dashboard/hfw" className="flex">
              <BsFillHouseFill
                size={22}
                className="mx-2 text-gray-600 text-[20px]"
              />{" "}
              <h3 className="text-center text-[20px] text-gray-600">
                {}
                ពាក្យប្រើញឹកញាប់ ថ្នាក់ទី២ | សប្តាហ៍ទី -{" "}
                <span className="font-bold">{id}</span>
              </h3>
            </a>
          </div>

          <Spin
            size="large"
            spinning={loading}
            style={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <Carousel
              className="flex h-[80vh] w-[90vw] rounded-2xl border-8 border-sbb-dark-blue shadow-md bg-white justify-center items-center"
              dots={true}
              dotPosition="bottom"
              pauseOnHover={true}
              draggable={true}
              ref={ref}
            >
              {list.map((slide, slideIndex) => (
                <div className="h-[78vh] justify-center items-center text-[35vh]">
                  <div className="flex h-full font-bold justify-center items-center text-[17vw]">
                    {slide.content}
                  </div>
                </div>
              ))}
            </Carousel>
          </Spin>
        </div>

        <div className="w-[90vw] mx-auto h-[4rem] flex items-center scroll-smooth mt-2 justify-start overflow-scroll">
          <div className="flex rounded border-2 shadow-md px-4">
            {list.map((slide, slideIndex) => (
              <div
                key={slideIndex}
                onClick={() => ref.current.goTo(slideIndex)}
                className="cursor-pointer h-[50px] flex"
              >
                <Space className="mx-2">
                  <Button
                    style={{ fontSize: "1.5vw" }}
                    className={`Button min-w-[5vw] text-lg text-center h-[2.8rem] my-2 font-Battambang px-4 ${
                      active === slideIndex
                        ? "text-white font-bold bg-[#004587] border-sbb-blue"
                        : "border-0"
                    }`}
                    onClick={() => setActive(slideIndex)}
                    key={slideIndex}
                  >
                    {slide.content}
                  </Button>
                </Space>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default HfwGradeTwoComp;
