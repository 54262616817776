import { useEffect, useState } from "react";
import { Space, Button, Input, Popconfirm, Modal, Table } from "antd";
import { request } from "../../util/api";
import { EditFilled} from "@ant-design/icons";
import { BsBookFill } from "react-icons/bs";

const { Search } = Input;
const onSearch = (value) => console.log(value);

const Dfword = () => {

  useEffect(() => {
    getAll();
  }, []);

  // useState
  const [list, setList] = useState([]);
  const [visibalModal, setVisibalModal] = useState(false);
  const [dfwId, setDfwId] = useState(null)
  const [grade, setGrade] = useState('')
  const [day, setDay] = useState('')
  const [week, setWeek] = useState('')
  const [content, setContent] = useState('')
//   const [page, setPage] = useState(1);

  //confique database
  const getAll = () => {
    request('get', 'dfword_data').then(res => {
      setList(res.data.list)
      console.log(res)
    }).catch(err => {
      console.log(err)
    })
  };

  // onConfirmDelete

  const onConfirmDelete = (id) => {
    request('delete','dfword_data/' + id).then(res =>{
        getAll()
    })
  };

  const handleCancel = () =>{
    setVisibalModal(false)
    clearCustomerForm()
  }

  const handleOpen = () =>{
    setVisibalModal(true)
  }

  const clearCustomerForm = () =>{
    // setGrade('');
    // setWeek('');
    // setDay('');
    setContent('');
  }

  const handleSave = () =>{
    if (dfwId === null){
        request('post', 'dfword_data/', {
            "grade": grade,
            "week": week,
            "day": day,
            "content": content
        }).then(res=>{
            getAll()
            clearCustomerForm()
            setVisibalModal(false)
        }).catch(err=>{
          console.log(err)
        })

      }else{
          request('put', 'dfword_data/', {
              "id": dfwId,
              "grade": grade,
              "week": week,
              "day": day,
              "content": content
          }).then(res=>{
            getAll()
            clearCustomerForm()
            setVisibalModal(false)
          }).catch(error=>{
            console.log(error)
          })
      }
    }
    

  const handleUpdate = (item, index) =>{
    setVisibalModal(true)
    setDfwId(item.id)
    setGrade(item.grade)
    setWeek(item.week)
    setDay(item.day)
    setContent(item.content)
  }

  const columns =[
    {
      key: 'id',
      title: 'ID',
      dataIndex: 'id',
    },
    {
      key: 'grade',
      title: 'Grade',
      dataIndex: 'grade'
    },
    {
      key: 'week',
      title: 'Week',
      dataIndex: 'week'
    },
    {
      key: 'day',
      title: 'Day',
      dataIndex: 'day'
    },
    {
      key: 'content',
      title: 'Content',
      dataIndex: 'content'
    },
    {
      key: '',
      title: 'Action',
      dataIndex: '',
      render: (value, item, index) =>(
        <Space>
          <Button 
            icon={<EditFilled/>}
            onClick={() => handleUpdate(item, index)}
            className="bg-sbb-dark-blue h-10 w-24 text-white"
            type="primary"
            >Edit</Button>

          <Popconfirm 
            placement="topRight"
            title={"Delete Customer"}
            description={"Are you sure to delete this customer?"}
            onConfirm={() => onConfirmDelete(item.id)}
            okText='Ok'
            cancelText="No"
            type='primary'
            className="bg-sbb-dark-blue"
          >
          </Popconfirm>
        </Space>
      )
    },
  ]

  return (
    <>
      <div className="flex flex-row space-x-4 space-x-reverse border-b px-5 py-5">
        <div>
          <Space className="space">
            {/* <div className="text-xl">Search</div>
            <Search placeholder="input search text" onSearch={onSearch} enterButton className="bg-sbb-dark-blue rounded-md hover:bg-sbb-blue"/> */}
            
            <Button type="primary" className="bg-sbb-dark-blue w-[120px] h-[40px] flex items-center" onClick={handleOpen}>
              <span className="pr-2"><BsBookFill /></span>Add New
            </Button>
          </Space>
        </div>
      </div>
      <section className="text-gray-600 body-font w-full">
        <div className="container px-4 py-10 mx-auto w-full">
          <div className="flex flex-col text-center w-full mb-10">
            <h1 className="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900">
             Difficult Words
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
              The list of Difficult Word for Grade 3
            </p>
          </div>

          <Table 
          className="container"
          key={list.id}
          columns={columns}
          dataSource={list}
          size="large"
          pagination={list.length}
          />    

        </div>
      </section>

      {/* <Table columns={columns} dataSource={data} />; */}
      <Modal
        open={visibalModal}
        title= {dfwId === null ? 'New High Frequency Word': 'Update High Frequency Word'}
        onCancel={handleCancel}
        footer={null}        
      >
      <Space direction="vertical" className="w-full">
        <Input 
          value={grade}
          placeholder='Grade'
          onChange={(e)=>{
            setGrade(e.target.value)
          }}
        />
        <Input
          value={week}
          placeholder='Week'
          onChange={(e)=>{
            setWeek(e.target.value)
          }}
        />

        <Input
          value={day}
          placeholder='Day'
          onChange={(e)=>{
            setDay(e.target.value)
          }}
        />

        <Input
          value={content}
          placeholder='Content'
          onChange={(e)=>{
            setContent(e.target.value)
          }}
        />
      </Space>
      <Space className="mt-5 flex justify-end">
        <Button
         onClick={handleCancel}
        >Cancel</Button>
        <Button 
          type="primary" 
          className="bg-sbb-dark-blue"
          onClick={handleSave}
          >{dfwId == null ? 'Add': 'Update'}</Button>
      </Space>
      </Modal>
    </>
  );
};

export default Dfword;
