import "./Content.css";

const Article1Content3 = () => {
  return (
    <>
      <div className="content-body">
        <h3>ស្វ័យភាព៖ គោលវិធីតាមបែបឯកលក្ខណៈភាព, ការហ៊ានប្រថុយ</h3>
        <p>
          ខ្ញុំចាំបានទៀតថា មេរៀនមួយបង្រៀនគណគ្រប់គ្រងសាលាឱ្យចេះប្រថុយ។
          ផែនការបែបប្រថុយដែលខ្ញុំទទួលបានគឺគម្រោង
          សាងសង់។ក្រោមការគាំទ្ររបស់គម្រោងសាលាទាំងនោះមានប្រែមុខមាត់ផ្នែកហេដ្ឋារចនាសម្ព័ន
          និងសម្ភារៈរូបវ័ន្តប្រហាក់ប្រហែលសាលា ដំបូងរបស់ខ្ញុំដែរ។
          អ្វីដែលមិនផ្លាស់ប្តូរនោះ គឺលទ្ធផលសិក្សា និងអត្រាគង់វង្សរបស់សិស្ស។
          ខ្ញុំសួរខ្លួនឯងទៀតថា តើមានអ្វីខុសឆ្គង ទៀតហើយ? ថ្ងៃនេះទើបខ្ញុំយល់។
          បើខ្ញុំចង់ឱ្យសិស្សរៀនចេះ​ ចេះរៀន និងរៀនបានវែងឆ្ងាយ
          ខ្ញុំគួរតែផ្តោតលើសិស្ស។
        </p>
        <p>
          អ្នកដែលមានឥទ្ធិពលជួយសិស្សបានជោគជ័យ គឺគ្រូបង្រៀនទេតើ។
          ខ្ញុំគួរតែបានយកចិត្តទុកដាក់លើលោកគ្រូ អ្នកគ្រូបង្រៀនជាចម្បង។
          ខ្ញុំគួរតែប្រឹងប្រែងធ្វើអ្វីៗសព្វបែបយ៉ាងដើម្បីគាំទ្រការបង្រៀន
          និងរៀនដោយផ្ទាល់។ ខ្ញុំគួរតែយកពេលវេលាភាគច្រើនរបស់ខ្ញុំ ខ្វល់ខ្វាយអំពី
          របៀបរៀនរបស់សិស្ស និងរបៀបបង្រៀនរបស់គ្រូ។
          ខ្ញុំគួរតែបានប្រាស្រ័យជាមួយគ្រូដោយជិតស្និទ្ធដើម្បីស្វែងយល់ពីទុក្ខធុរៈរបស់គាត់
          រកលទ្ធភាពគាំទ្រវិជ្ជាជីវៈសម្រាប់គាត់
          និងជម្រុញទឹកចិត្តគាត់ពង្រីកគុណភាពបង្រៀន និងរៀនជាប្រចាំ។
        </p>
        <p>
          ១០ ឆ្នាំក្រោយមក ខ្ញុំបានទៅលេងសាលាដំបូងរបស់ខ្ញុំ
          និងបានជជែកជាមួយនាយកសាលា។ គាត់ថា អត្រាសិស្សរៀនមិនចេះគឺនៅតែខ្ពស់
          ចំណែកបន្ទប់កុំព្យុទ័រ
          និងបន្ទប់ពិសោធន៍ត្រូវបានបិទដំណើរការជាច្រើនឆ្នាំមកហើយ។
          ខ្ញុំបានរៀបរាប់កំហុសរបស់ខ្ញុំប្រាប់គាត់ដូចសេចក្តីខាងដើមហើយសូមឱ្យគាត់គិតសារជាថ្មីដោយយកសិស្សនិងគ្រូជាអាទិភាព!
        </p>
        <p>
          សង្គមបានចាត់ទុកគ្រូបង្រៀន ជាអ្នកចេះដឹង
          ហើយផ្ញើរជោគវាសនាកូនចៅរបស់ខ្លួនជាមួយគ្រូបង្រៀន ក្នុងការដាស់សក្តានុពល
          ដើម្បីអនាគតមួយដែលប្រសើរជាងនេះ។ ដូច្នេះ
          ខ្ញុំជឿជាក់ថាយើងមានទំនួលខុសត្រូវចំពោះមុខមនុស្សជំនាន់ថ្មីនេះឱ្យបានល្អ។
        </p>
      </div>
    </>
  );
};

export default Article1Content3;
