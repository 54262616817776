import "./Content.css";

const Article1Content6 = () => {
  return (
    <>
      <div className="content-body">
        <h3>ការផ្ដល់ព័ត៌មានត្រឡប់</h3>
        <p>ជាធម្មតាការធ្វើការផ្តល់ព័ត៌មានត្រឡប់គឺកើតឡើងនៅពេលក្រោយពីសង្កេតការបង្រៀនរួច ប៉ុន្តែវាក៏អាចធ្វើក្រោយពេលពី ធ្វើសកម្មភាពផ្សេងៗហើយឬក៏ធ្វើនៅក្រៅមេរៀននៅពេលណាដែលត្រូវការថាអាចធ្វើទៅបាន៖</p>
        <ul className="list-style">
          <li>ព័ត៌មានត្រឡប់គឺជាការទទួលស្គាល់នូវចំណុចវិជ្ជមានកិច្ចការងារបស់គ្រូប្រឹក្សាគរុកោសល្យ ឬក៏ការ កែលម្អរតាមបែបស្ថាបនាទៅលើបំណិន  និងកិច្ចការដែលបំពេញមិនទាន់បានល្អ។</li>
          <li>ព័ត៌មានត្រឡប់គឺផ្តល់នូវចំណុចល្អ,ចំណុចខ្វះខាតនិងការផ្តល់អនុសាសន៍រដោយទីប្រឹក្សាគរុកោសល្យជំនាញអំពីទិដ្ឋភាពនៃ ការអនុវត្ត ឬការយល់ដឹងរបស់នរណាម្នាក់។</li>
          <li>ព័ត៌មានត្រឡប់គឺជាការផ្តល់ នូវព័ត៌មានជាក់លាក់អំពីឥរិយាបថនរណាម្នាក់ដើម្បីឱ្យដឹងថាគួរតែបន្ត រក្សាឥរិយាបថមិន ល្អឬក៏ត្រូវផ្លាស់ប្តូរចេញ។</li>
        </ul>
        
        <span className="right">
          <i>
          (កែលម្អកម្មសិក្សាការី ការិនី ក្នុងពេលចុះអនុវត្តកម្មសិក្សាគរុកោសល្យ តាមរយៈបំណិនផ្តល់ព័ត៌មានត្រឡប់ ក្រសួងអប់រំ ២០១៦)
          </i>
        </span>

        <p style={{ fontWeight: "bold", color: '#004587' }}> ប្រើសំណួរគាំទ្រជួយក្នុងការប្រឹក្សាគរុកោសល្យ</p>
        <p>ការសួរសំណួរគឺជាផ្នែកដ៏សំខាន់ក្នុងការងារប្រឹក្សាគរុកោសល្យ។ តាមរយៈការសួរសំណួរទីប្រឹក្សាគរុកោសល្យជំនាញ អាចដឹង 
          បានកាន់តែស៊ី ជម្រៅពីការគិតរបស់គ្រូប្រឹក្សាគរុកោសល្យ ធ្វើការបញ្ជាក់ពីដំណើរការបង្កើតការសម្រេចចិត្តរបស់គ្រូ ប្រឹក្សាគរុកោសល្យ    
          ប្រចាំសាលាដែលប្រហែលជាមិនអាចសង្កេតឃើញ និងដើម្បីជួយគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាឱ្យឆ្លុះបញ្ចាំងពីការអនុវត្តរបស់ខ្លួន។
          សំណួរសម្រាប់យល់ដឹងពីការគិត៖</p>

        <ul className="list-style">
          <li>ខ្ញុំបានកត់សម្គាល់ឃើញថាអ្នកបានសួរ(សំណួរ)នេះ តើហេតុអ្វីបានជាអ្នកធ្វើដូច្នេះ?</li>
          <li>ខ្ញុំបានសង្កេតឃើញថាលោកគ្រូ/អ្នកគ្រូ….…តើលោកគ្រូ/អ្នកគ្រូអាចពន្យល់បាន​ទេថាហេតុអ្វីបានជាវាមានសារៈសំខាន់?</li>
          <li>តើលោកគ្រូ/អ្នកគ្រូអាចពន្យល់ខ្ញុំបាន​ទេ………តើអ្នកទទួលបានចម្លើយដោយរបៀបណា?</li>
        </ul>

        <p style={{ fontWeight: "bold", color: '#004587' }}>សំណួរសម្រាប់ការឆ្លុះបញ្ចាំង៖</p>

        <ul className="list-style">
          <li>តើការចុះមកធ្វើប្រឹក្សាគរុកោសល្យលើកនេះមានគោលបំណងអ្វី?</li>
          <li>តើលោកគ្រូ/អ្នកគ្រូបានអនុវត្តដូចនូវអ្វីដែលបានគ្រោងដែរឬទេ?</li>
          <li>តើលោកគ្រូ/អ្នកគ្រូឃើញមានអ្វីដែលសម្រេចបានជោគជ័យ?</li>
          <li>តើលោកគ្រូ/អ្នកគ្រូអាចប្រាប់/រៀបរាប់ពីចំណុចល្អឱ្យបានចំនួនពីរដែលអ្នកអាចធ្វើបាន? ហេតុអ្វីបានជាអ្នកគិតថាវាជាចំណុចល្អ?</li>
          <li>តើលោកគ្រូ/អ្នកគ្រូអាចប្រាប់/រៀបរាប់ពីចំណុចគួរកែលម្អឱ្យបានចំនួនពីរ?  ហេតុអ្វីបានជាអ្នកជ្រើសរើសចំណុចទាំងពីរនេះ?</li>
          <li>នៅពេលលោកគ្រូ/អ្នកគ្រូចុះផ្ដល់ប្រឹក្សាគរុកោសល្យលើកក្រោយរបស់អ្នក តើអ្វីដែលអ្នកគួរកែលម្អ ហើយហេតុអ្វី?</li>
        </ul>
        <p style={{ fontWeight: "bold", color: '#004587' }}>ដំណើរនៃការផ្ដល់ព័ត៌មានត្រឡប់</p>
        <p>ទីប្រឹក្សាគរុកោសល្យេជំនាញគួរតែចាប់ផ្ដើមការផ្ដល់ព័ត៌មានត្រឡប់ដោយការផ្ដល់ឱ្យគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាធ្វើការឆ្លុះ បញ្ចាំងពី ការប្រឹក្សាគរុកោសល្យរបស់ពួកគាត់ជាមុនសិន៖ 
          ផលសម្រេចបាន, បញ្ហាប្រឈម និងចំណុចដែលពួកគាត់គិត ថាត្រូវការគាំទ្រ បន្ថែមទៀត។ទីប្រឹក្សាគរុកោសល្យេជំនាញផ្ដល់ព័ត៌មានត្រឡប់បែបស្ថាបនាទៅកាន់គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាដោយផ្ដោត 
          ដំបូងលើអ្វី ដែលពួក គាត់ធ្វើបានល្អ និងបន្ទាប់មកបង្ហាញពីចំណុចគួរកែលម្អ មិនឱ្យលើសពីពីរចំណុចទេ។ ព័ត៌មានត្រឡប់គួរតែច្បាស់លាស់ និងជាក់ លាក់ដែល អាចអនុវត្តទៅបានសម្រេច៖ 
          វាមិនមែនត្រឹមតែនិយាយថា “ការប្រើប្រាស់ សំណួររបស់លោកគ្រូ/អ្នកគ្រូគឺមិនទាន់បានល្អ” ធ្វើការពន្យល់ ឱ្យបានច្បាស់លាស់ថាអ្វីដែលត្រូវធ្វើការផ្លាស់ប្ដូរនិងកែលម្អរដើម្បី ឱ្យមានភាពកាន់តែប្រសើរឡើង។ 
          ប្រាកដចិត្តថាព័ត៌មាន ត្រឡប់ធ្ធ្វើឡើង ដោយមានការគោរព និងរួសរាយដោយស្ដាប់យោបល់ពីគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលា ការផ្តល់ព័ត៌មានត្រឡប់ មិនគួរតែជាការ រិះគន់តែជាការពិភាក្សាគ្នា។</p>
      </div>
    </>
  );
};

export default Article1Content6;
