import "./Content.css";

const Article1Content8 = () => {
  return (
    <>
      <div className="content-body">
        <h3>សរសេររបាយណ៍</h3>
        <p>
          ទីប្រឹក្សាគរុកោសល្យធ្វើការផ្តល់របាយការណ៍ជូនការិយាល័យអប់រំ
          យុវជននិងកីឡាដូចខាងក្រោម៖
        </p>

        <ul className="list-style">
          <li>
            ការបំប៉នគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលា
            រួមទាំងវគ្គសិក្ខាសាលារំលឹកឡើងវិញ។
          </li>
          <li>
            នៅពេលដែលទីប្រឹក្សាគរុកោសល្យជំនាញចុះតាមសាលារៀនថតចម្លងរបាយការណ៍ប្រឹក្សាគរុកោសល្យជូនគណៈគ្រប់គ្រងសាលារៀន(
            ផ្ញើជូនការិយាល័យអ.យ.ក)។
          </li>
          <li>
            នៅពេលដែលទីប្រឹក្សាគរុកោសល្យជំនាញ
            មានការពិភាក្សាជាមួយគ្រូបង្រៀនថ្នាក់ដំបូង
            (រួមមានព័ត៌មានត្រឡប់ពីគ្រូបង្រៀន
            អំពីគុណភាពនៃការប្រឹក្សាគរុកោសល្យដែលបានទទួល និង ថតចម្លងជូននាយកសាលា
            និងការិយាល័យ អ.យ.ក)។
          </li>
        </ul>
      </div>
    </>
  );
};

export default Article1Content8;
