// import { School } from '@ant-design/icons';
import { Tabs } from 'antd';

import HfwGradeOne from '../pages/hfWord/HfwGradeOne';
import HfwGradeTwo from '../pages/hfWord/HfwGradeTwo';
import HfwGradeThree from '../pages/hfWord/HfwGradeThree';

import { BsFill1SquareFill, BsFill2SquareFill, BsFill3SquareFill } from "react-icons/bs";

const HfwTabs = () => {

  return(
    <>
      <Tabs 
        defaultActiveKey='Tab1' 
        tabPosition='top' 
        className='container' 
        type="card"
        centered
        size='meduim'
      >
          <Tabs.TabPane 
            tab={<span className='text-xl font-bold flex px-2 justify-center items-center'>
                <span className='px-2 text-sbb-dark-blue'><BsFill1SquareFill/></span>ថ្នាក់ទី ១</span>} 
            key='grade1' 
          >
              <div><HfwGradeOne/></div>
          </Tabs.TabPane>

          <Tabs.TabPane 
            tab={<span className='text-xl font-bold flex px-2 justify-center items-center'>
            <span className='px-2 text-sbb-dark-blue'><BsFill2SquareFill/></span>ថ្នាក់ទី ២</span>}
            key='grade2'
          >
            <div><HfwGradeTwo/></div>
          </Tabs.TabPane>

          <Tabs.TabPane 
            tab={<span className='text-xl font-bold flex px-2 justify-center items-center'>
            <span className='px-2 text-sbb-dark-blue'><BsFill3SquareFill/></span>ថ្នាក់ទី ៣</span>}
            key='grade3'
          >
            <div><HfwGradeThree/></div>
          </Tabs.TabPane>
      </Tabs>
    </>
  );
}


export default HfwTabs;