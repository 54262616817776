import "./Content.css";

const Article1Content5 = () => {
  return (
    <>
      <div className="content-body">
        <h3>ការជម្រុញលើកទឹកចិត្តដើម្បីអោយមានការផ្លាស់ប្ដូរកាន់តែប្រសើរ៖</h3>
        <p>
          អ្នកជាភ្នាក់ងារផ្លាស់ប្តូរការស្រាវជ្រាវបានបង្ហាញថា
          អ្នកដឹកនាំនៅសាលារៀនគឺជាភ្នាក់ងារនាំឱ្យមានការផ្លាស់ប្តូរ។ នៅពេល
          និយាយដល់ការផ្លាស់ប្តូរ
          ខ្ញុំផ្ទាល់ធ្លាប់មានការភ័យខ្លាចសូម្បីតែនិយាយពាក្យនេះចេញមក។
          ប៉ុន្តែបន្ទាប់ពីពិចារណា ច្រើនទៅ
          ការផ្លាស់ប្តូរបានក្លាយជារឿងចាំបាច់សម្រាប់ជីវិតរបស់ខ្ញុំ។
          ព្រោះតែប្រជាជនប្តូរទម្លាប់មកសម្រាលកូននៅពេទ្យ ផ្តល់ទឹកដោះ
          ដំបូងដល់ទារកវាបានជួយឱ្យអត្រាមរណៈមាតានិងទារកមានការថយចុះដ៏គួរឱ្យកត់សម្គាល់។
        </p>
        <p>
          ខ្ញុំចូលចិត្តពាក្យស្លោក «ដាំនៅសាលា ផ្លែផ្កានៅក្នុងសង្គម»។
          ខ្ញុំសូមលើកអំពីផ្លែផ្កាក្នុងសង្គមបច្ចុប្បន្នខ្លះៗ។
          បើយើងមើលកម្មវិធីល្បីៗលើកញ្ចក់ទូរទស្សន៍ ដូចជា Master Chef, Cambodia
          Idol ជាដើម គឺសុទ្ធតែធ្វើតាមគេទេ។ រហូតមានរឿងភាគជាច្រើន​
          ក៏ចម្លងតាមថៃដែរ។ វាមិនមែនជារឿងចៃដន្យទេ ប៉ុន្តែវាជាលទ្ធផលដែលយើងឆ្លង
          កាត់បទពិសោធន៍ ចម្លងមេរៀននៅសាលារហូតក្លាយជាទម្លាប់។ ជាក់ស្តែង
          ខ្ញុំធ្លាប់សរសេរមេរៀនដាក់លើក្តារខៀនឱ្យសិស្សចម្លងតាម និងទម្លាប់ឱ្យ
          សិស្សអានតាម ឬសួរត្រឹមសំណួរបំផុសខ្លីៗប៉ុណ្ណោះ។
          បើអាចបង្វិលពេលវេលាថយក្រោយបាន ខ្ញុំនឹងបង្រៀនសិស្សឱ្យចេះគិត
          ហ៊ានសាកល្បងគំនិតខ្លួនឯង និងសរសេរចេញពីគំនិតខ្លួនឯងតាំងពីថ្នាក់ដំបូង
          រហូតដល់ថ្នាក់បញ្ចប់។
        </p>
        <p>
          បើយើងមើលពីវិស័យការងារ ភាគច្រើនគឺបានត្រឹមជាពលករប្រើកម្លាំងបាយ។
          នោះក៏ព្រោះតែពួកគាត់មិនបានរៀន ឬរៀនបាន តិចតួច
          ឬរៀនដែរតែគ្មានជំនាញពិតប្រាកដ រៀនសើរៗ។ ខ្ញុំស្រមៃថា
          បើសិនពួកគាត់ត្រូវបានផ្តល់ពិសោធន៍ឱ្យ សិស្សរៀន បែប សកម្ម
          គិតស៊ីជម្រៅវិញនោះ ប្រហែលជាពួកគាត់អាចបង្កើតការងារក្នុងស្រុក
          ឬមានសមត្ថភាពប្រកួតប្រជែងស្មើមុខ ស្មើមាត់នឹង គេបាន។
          យើងនៅមានពេលនៅឡើយសម្រាប់រឿងនេះ មែនទេ?
        </p>
        <p>
          បើនិយាយអំពីកាលៈទេសៈ
          ខ្ញុំតែងគិតដល់ការវិវត្តរបស់បញ្ហាសង្គមនៅតាមសម័យកាលនីមួយៗ។
          កាលជំនាន់ឆ្នាំ៨០ និង៩០ ប្រជាជនប្រឹងប្រែងដាំដុះ
          បង្កបង្កើនផលដើម្បីរស់រានជាអាទិភាព។ ចឹងមនុស្សរៀនបានតិចតួច
          ក៏អាចរស់បានដែរ ដោយសារដីស្រែ ចម្ការនៅទូលាយបើធៀបជាមួយដង់ស៊ីតេប្រជាជន។
          បើនិយាយពីវិស័យការងារ ចំពោះអ្នកបានរៀនចប់បាក់ឌុប គឺមិនចេះខ្វះឱកាស
          ការងារធ្វើទេ ជាពិសេសការងាររាជការ។
        </p>
        <p>
          ចាប់ពីឆ្នាំ២០០០មក យើងឃើញមានបញ្ហាថ្មីបន្ថែមទៀត នោះគឺមនុស្សបានកើនឡើង
          ដែលទាមទារឱ្យមានការពង្រីកផ្ទៃដីដាំដុះ។
          មនុស្សបានប្រើធនធានធម្មជាតិយ៉ាងគំហុក (ជាពិសេសព្រៃឈើ)
          ដើម្បីជាដើមទុនផ្គត់ផ្គង់ជីវិតរស់នៅប្រចាំថ្ងៃ
          និងប្តូរជាមួយសម្ភារៈនាំចូលដើម្បីពង្រីកផលិតភាពមុខរបរ។
          ការងារក្នុងស្រុកចាប់មានការប្រកួតប្រជែងទីផ្សារកាន់តែខ្លាំងក្លា
          ដែលតម្រូវឱ្យមានសមត្ថភាពជំនាញ។ ដោយសារជំនាញមិនដល់
          ប្រជាជនជាច្រើនអាចធ្វើបានត្រឹមពលករប្រើកម្លាំងទាំងក្នុងស្រុក
          និងក្រៅស្រុក។
          បំរែបំរួលអាកាសធាតុកាន់តែសម្តែងឱ្យមនុស្សយើងឃើញកាន់តែច្បាស់តាមរយៈការឡើងកំដៅ
          ជាដើម។ ស្រមៃមើលថា​ នៅប៉ុន្មានឆ្នាំទៅមុខទៀតនេះ
          តើនឹងមានបញ្ហាអ្វីខ្លះកើតឡើង? ខ្ញុំមិនដឹងទេ
          <b>
            ប៉ុន្តែខ្ញុំជឿថា ប្រសិនបើអ្នកគ្រូ លោកគ្រូ
            ផ្លាស់ប្តូរមកបង្រៀនមនុស្សជំនាន់ថ្មីនេះឱ្យចេះរៀន ឱ្យចេះគិត
            ឱ្យមានពិសោធន៍ដោះស្រាយបញ្ហា ឱ្យយល់និង ប្រកាន់គុណធម៌ដោយក្តីភ្ញាក់រឭក
            នោះពួកគាត់ប្រាកដជាអាចមានសមត្ថភាពដោះស្រាយបញ្ហាអនាគតនៃមនុស្ស
            ជាតិបានដោយខ្លួនឯង។
          </b>
          សូមចាប់ផ្តើមឆ្លុះបញ្ចាំងរបៀបបង្រៀនរបស់លោកអ្នកចាប់ពីថ្ងៃនេះតទៅ។
        </p>
        <p>
          ខ្ញុំជឿជាក់ថា មានតែការអប់រំទេ ដែលជួយឱ្យមនុស្សយើងកែប្រែគុណភាពជីវិតបាន។
          ហើយបើអ្នកចង់កែប្រែលទ្ធផលអប់រំ អ្នកត្រូវនាំគ្រូកែប្រែវិធីបង្រៀន
          ត្រូវហ៊ានចាកចេញពីទម្លាប់ចាស់សម្រាប់រឿងខ្លះ។
          សព្វថ្ងៃមានអតីតសិស្សខ្ញុំខ្លះបានក្លាយជាគ្រូបង្រៀន។
          មានម្នាក់ដែលធ្វើខ្ញុំមោទនភាពបំផុត
          ដោយសារគាត់មិនបានដើរតាមគំរូចាស់របស់ខ្ញុំ។
          នោះក៏ព្រោះតែខ្ញុំយល់ថាវិធីចាស់ របស់ខ្ញុំប្រហែល
          អាចជួយដោះស្រាយបញ្ហាសម័យនោះបាន
          តែវាច្បាស់ណាស់ថាមិនអាចដោះស្រាយបញ្ហានៅសម័យឌីជីថលនេះបានល្អនោះឡើយ។
        </p>
      </div>
    </>
  );
};

export default Article1Content5;
