import "./Content.css";

const Article1Content4 = () => {
  return (
    <>
      <div className="content-body">
        <h3>គោលការណ៍ប្រតិបត្តិរបស់ខ្ញុំ៖</h3>
        <p>
          ខ្ញុំជឿថាលោកអ្នកនឹងកំណត់រូបភាពជោគជ័យក្នុងអាជីពជាគ្រូបង្រៀនរៀងៗខ្លួនដោយយកសិស្សជាកម្មវត្ថុនៃភាពជោគជ័យទាំងនោះ។ពេលកំណត់គោលដៅបានហើយសូមកំណត់ផែនការមួយជំហានបន្ទាប់ដើម្បីឆ្ពោះទៅកាន់គោលដៅនោះ។
          ខ្ញុំចង់ឃើញមនុស្សជំនាន់ ថ្មីនេះ បានរៀនប្រកបដោយជោគជ័យ
          ហើយមានសក្តានុពលច្នៃប្រឌិត អភិវឌ្ឍសង្គមបានល្អប្រសើរ។
        </p>
        <p>
          មានគន្លឹះអាទិភាពមួយចំនួនដែលខ្ញុំប្រកាន់ខ្ជាប់ ហើយចង់ឱ្យលោក
          អ្នកពិចារណាជាមួយខ្ញុំដែរ។
        </p>
        <ul className="list-style">
          <li>
            រៀបចំជាមុន។ ក្រសួងអប់រំបានផ្តល់ទិសដៅតាមរយៈគោលនយោបាយ
            ផែនការយុទ្ធសាស្ត្រ កម្មវិធីសិក្សា ប៉ុន្តែលោក អ្នកចាំបាច់
            ត្រូវតែរៀបចំលម្អិត។ ជញ្ជីងថ្លឹងមនុស្ស ខុសពីជញ្ជីងថ្លឹងមាស។
            ការរៀបចំកិច្ចតែងការ ការវាយតម្លៃចំណេះដឹង សិស្សជាប្រចាំ
            សុទ្ធតែជាជំហានគ្រឹះដ៏ចាំបាច់។
          </li>
          <li>
            ផ្តោត! ផ្តោតលើសិស្សជាអាទិភាព។ បង្រៀនឱ្យសិស្សចេះគិតស៊ីជម្រៅ។
            ពាក្យចាស់លោកថា «ចេះដប់ មិនស្មើប្រសប់មួយ»។​ ភាសាវិជ្ជមាន,
            ដើម្បីបណ្តុះគោលការណ៍វិជ្ជមានសម្រាប់ជីវិតកុមារ។ឧទាហរណ៍៖
            បង្កើតគោលការណ៍ថ្នាក់រៀនថា «យើងស្មោះត្រង់»។ សម្លឹងទៅមុខ!
            ហើយអភិវឌ្ឍសមត្ថភាពខ្លួនឯងជាប្រចាំ។
          </li>
        </ul>
      </div>
    </>
  );
};

export default Article1Content4;
