import { Layout, Menu, ConfigProvider} from 'antd';
import { useState } from 'react';
import FooterNav from '../componants/FooterNav';
import UserHeader from './UserHeader';
import { Link, Outlet } from 'react-router-dom';

import { BsCalculatorFill, BsFillPieChartFill, BsMortarboardFill } from "react-icons/bs";
import { DesktopOutlined, } from '@ant-design/icons';
import { FaChalkboardTeacher } from "react-icons/fa";

const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children, url) {
  return {
    key,
    icon,
    children,
    label,
    url
  };
}

<ConfigProvider
    theme={{
      token: {
        colorPrimary: '#004587',
      },
    }}
  >
</ConfigProvider>

const items = [
  getItem(<Link to='/dashboard'>ទំព័រដើម</Link>, '1', <DesktopOutlined />),
  getItem('ធនធានថ្នាក់រៀន', 'sub1', <FaChalkboardTeacher />, 
    [ getItem(<Link to='hfw'>ពាក្យប្រើញឹកញាប់</Link>, '2'),
      getItem(<Link to='dfw'>ពាក្យពិបាក</Link>, '3'),
      getItem('សៀវភៅធំ', '4'),
      getItem('កិច្ចតែងការ', '5'),
      getItem(<Link to='lessonbuilder'>រៀបចំកិច្ចតែងការ</Link>, '6'),]),
  
  getItem('គណិតវិទ្យា', 'sub2', <BsCalculatorFill />, 
    [ getItem(<Link to='/math'>ធនធានគណិតវិទ្យា</Link>, '7'),
      getItem(<Link to='/math/hundredchart/kh'>តារាងការេ ១០០</Link>, '8'), 
      getItem(<Link to='/math/hundredchart/en'>តារាងការេ 100</Link>, '9'), 

      // getItem('តម្លៃលេខតាមខ្ទង់', '9'),
      // getItem('ផ្ទាំងចំណុច', '10'), 
      // getItem('ទម្រង់ពង្រាយ', '11'), 
      // getItem('គោល៥ និង១០', '12'), 
      // getItem('វិធីបូក និងគុណ', '13'), 
      // getItem('បន្ទាត់លេខ', '14'),     
      // getItem('តារាងលេខ ៣ខ្ទង់', '15'),     
      // getItem('នាឡិកាប្រាប់ម៉ោង', '16'),
      // getItem('វង្វាស់រង្វាលទំងន់', '17'),   
      // getItem('លំនាំគម្រូ', '18'),      
    ]),
    getItem('តេស្តសិស្ស', 'sub3', <BsFillPieChartFill />, 
    [ getItem(<Link to='formative/test1'>តេស្តព្យញ្ជនៈពួក អ</Link>, '19'),
      getItem('តេស្តព្យញ្ជនៈ ពួកអ៊', '20'),
      getItem('តេស្តស្រៈ ពួកអ', '21'),
      getItem('តេស្តស្រៈ ពួកអ៊', '22'),
      getItem('ទម្រង់ធ្វើតេស្ត', '23'),]),

  getItem('អភិវឌ្ឍនជំនាញ', '24', <BsMortarboardFill />),
  ];

const UserDashboard = () => {
  const [collapsed, setCollapsed] = useState(false);  
  return (
    <Layout className='min-h-[100vh]'>        
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}         
        style={{backgroundColor: '#004587', color:'white'}}  
      > 
        <Link to='/' >
            <img src={require('../assets/images/sbb-logo-sm.png')} alt=''className='m-auto w-12 py-2'/>
        </Link>
        <Menu defaultSelectedKeys={['1']} mode="inline" theme='dark' items={items} style={{backgroundColor: '#004587', color:'white', }}/>        
      </Sider>
      <Layout>
        <Header className='bg-white p-2 h-24'>
          <UserHeader/>
        </Header>
        <Content className='p-0'>
          <main className="px-8 bg-white max-h-fit">
            <Outlet/>
          </main>
        </Content>
        <Footer
          className='text-center bg-white'        >
          <div className='border-t-2 border-gray-300 border-shadow-sm opacity-96'>
              <FooterNav />
          </div>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default UserDashboard;