import "./Content.css";
const people = [
  { 
    name: 'សិក្ខាសាលាស្តីពីតេស្តវិនិច្ឆ័យ', 
    title:<>
            <ul style={{fontSize: '12px', listStyle: 'inside', color: '#333'}}>
              <li>តើហេតុអ្វីបានជាតេស្តមានសារៈសំខាន់?</li>
              <li>របៀបធ្វើតេស្ត</li>
              <li>លំនាំដំណើរធ្វើតេស្ត្ត</li>
              <li>វិធីដាក់ពិន្ទុតេស្ត</li>
              <li>វិភាគលទ្ធផល</li>
            </ul>
          </>
}, 
{
  name: 'សិក្ខាសាលាស្តីពីចំនួននិងវិធីបូក',
  title:<>
            <ul style={{fontSize: '12px', listStyle: 'inside', color: '#333'}}>
              <li>វិធីបង្រៀនសិស្សមជ្ឈមណ្ឌល</li>
              <li>បង្រៀនពីរូបីទៅអរូបី</li>
              <li>ការប្រើប្រាស់សៀវភៅជំនួយ</li>
              <li>តើការរាប់ចំនួនជាអ្វី? តើហេតុអ្វីបានជាមានសារៈសំខាន់?</li>
              <li>សារៈសំខាន់នៃការប្រើសំណួរ</li>
              <li>ធ្វើការបង្ហាញ</li>
              <li>បំបែកបញ្ញតិវិធីបូក</li>
              <li>បង្ហាញនិមិត្តសញ្ញាបូក</li>
              <li>ការអនុវត្ត</li>
            </ul>
        </>
},
{
  name: 'សិក្ខាសាលាស្តីពីប្រឹក្សាគរុកោសល្យ',
  title:<>
            <ul style={{fontSize: '12px', listStyle: 'inside', color: '#333'}}>
              <li>តើប្រឹក្សាគរុកោសល្យជាអ្វី?</li>
              <li>តួនាទីនិងទំនួលខុសត្រូវរបស់គ្រូប្រឹក្សាគរុកោសល្យ</li>
              <li>លំនា/ដំណើរការប្រឹក្សាគរុកោសល្យ</li>
              <li>ការយល់ដឹង និងជំនាញគ្រូបង្រៀនត្រូវការ ព្រមទាំងការផ្តល់ព័ត៌មាន ត្រឡប់</li>
              <li>រៀនពីលំនាគំរូ</li>
              <li>គ្រូប្រឹក្សាគរុកោសល្យផ្តល់ការគាំទ្រជួយគ្រូប្ងរៀនលើការគ្រប់គ្រងថ្នាក់ រៀន។</li>
              <li>ផ្តល់បច្ចេកទេសនិងសង្កេត</li>
              <li>ផ្តល់ព័ត៌មានត្រឡប់</li>
              <li>បំពេញចំណុចកែលម្អ</li>
              <li>ទម្រង់វាស់វែងកម្រិតជំនាញគ្រូបង្រៀន</li>
            </ul>
        </>
},
{
  name: 'កម្រិតជំនាញរបស់គ្រូប្រឹក្សាគរុកោសល្យ',
  title:<>
            <ul style={{fontSize: '12px', listStyle: 'inside', color: '#333'}}>
              <li>តើអ្វីទៅជាកម្រិតជំនាញ?</li>
              <li>តើធ្វើការវាស់វែងកម្រិតជំនាញដើម្បីអ្វី?</li>
              <li>សារៈសំខាន់របស់កម្រិតជំនាញ</li>
              <li>យល់ដឹងលើខ្លឹមសាររបស់កម្រិតជំនាញ</li>
              <li>វិធីធ្វើការវាស់វែងកម្រិតជំនាញ</li>
              <li>លំនាំ/ដំណើរការកម្រិតជំនាញ</li>
              <li>វិភាគ</li>
            </ul>
        </>
},
{
  name: 'សិក្ខាសាលាស្តីពីវិធីដក',
  title:<>
            <ul style={{fontSize: '12px', listStyle: 'inside', color: '#333'}}>
              <li>វិធីបង្រៀនសិស្សមជ្ឈមណ្ឌល</li>
              <li>បង្រៀនពីរូបីទៅអរូបី</li>
              <li>ការប្រើប្រាស់សៀវភៅជំនួយ</li>
              <li>សារៈសំខាន់នៃការប្រើសំណួរ</li>
              <li>សំណួរដែលប្រើជាមួយតារាង១០០</li>
              <li>រាប់បន្ថយ(ដក)</li>
              <li>បង្ហាញការដក</li>
              <li>ស្វែរកភាពខុសគ្នារវាងពីរចំនួន</li>
              <li>រាប់បន្ថែមដើម្បីរកភាពខុសគ្នា</li>
              <li>ទំនាក់ទំនងរវាងវិធីបូកនិងដក</li>
              <li>ប្រើបន្ទាត់គ្មានលេខ</li>
            </ul>
        </>
},
{
  name: 'សិក្ខាសាលាស្តីពីវិធីគុណនិងវិធីចែក',
  title:<>
            <ul style={{fontSize: '12px', listStyle: 'inside', color: '#333'}}>
              <li>វិធីបង្រៀនសិស្សមជ្ឈមណ្ឌល</li>
              <li>បង្រៀនពីរូបីទៅអរូបី</li>
              <li>ការប្រើប្រាស់សៀវភៅជំនួយ</li>
              <li>ចំណេះដឹងចាំបាច់ដែលសិស្សត្រូវមាន</li>
              <li>ពិភាក្សាក្រុមធំ</li>
              <li>ស្គាល់ក្រុមដែលមានបរិមាណមិនស្មើគ្នា</li>
              <li>ខ្លឹមសារ ធ្វើជាចំណែក ឬរំលែក</li>
              <li>បន្តវិធីបូកចំនួនដដែលៗបន្តគ្នា</li>
              <li>បង្ហាញគំរូនិងប្រាប់បានពីប្រមាណវិធីគុណ</li>
              <li>បង្ហាញអំពីការតម្រៀបវត្ថុជាជួរ</li>
              <li>ការប្រើការតម្រៀបជាជួរដើម្បីបង្ហាញគំរូវិធីគុណ</li>
              <li>បង្ហាញវិធីចែកមានទម្រង់ពីរខុសគ្នា</li>    
              <li>ដឹកនាំពិភាក្សាលើមូលហេតុអ្វីចាំបាច់បានជាសិស្សត្រូវយល់ពីវិធីចែកតាមទម្រង់ទាំងពីរ “ធ្វើជាចំណែក ឬរំលែក” និង”ដាក់ជាក្រុម”</li>
              <li>អនុវត្ត</li>               
            </ul>
        </>
},
{
  name: 'សិក្ខាសាលាស្តីប្រភាគ',
  title:<>
            <ul style={{fontSize: '12px', listStyle: 'inside', color: '#333'}}>
              <li>វិធីបង្រៀនសិស្សមជ្ឈមណ្ឌល</li>
              <li>បង្រៀនពីរូបីទៅអរូបី</li>
              <li>ពិភាក្សាលើការបង្កើនចំណេះដឹងនៃខ្លឹមសារប្រភាគ</li>               
              <li>បង្កើតនិងពន្យល់ចំណេះដឹងសិស្សមានស្រាប់</li>               
              <li>រំលឹកកិច្ចតែងការ</li>               
              <li>ជ្រើសរើសមេរៀនដោយធ្វើការផ្សារភ្ជាប់ទៅសៀវភៅសិក្សាគោល</li>               
              <li>បង្ហាញគំរូ អនុវត្ត</li>               
            </ul>
        </>
},
{
  name: 'សិក្ខាសាលាស្តីដោះស្រាយចំណោទបញ្ហា',
  title:<>
            <ul style={{fontSize: '12px', listStyle: 'inside', color: '#333'}}>
              <li>តើដោះស្រាយចំណោទ/បញ្ហាមានន័យយ៉ាង ដូចម្ត៉េច?</li>
              <li>តើប្រភេទ ចំណោទបញ្ហាអ្វីខ្លះដែលអ្នកជួបប្រទះនៅក្នុងជីវភាពរាល់ថ្ងៃ?​</li>
              <li>តើយើងអាចធ្វើចំណាត់ថ្នាក់ចំណោទទាំងអស់នេះទៅតាមប្រភេទបានទេ?</li>              
              <li>ដាក់បញ្ហាឱ្យក្រុមដោះស្រាយ</li>              
              <li>តើអ្នកមានអារម្មណ៍យ៉ាងណាពេលអ្នកជួបចំណោទគណិតវិទ្យា?</li>              
              <li>តើយើងទាំងអស់គ្នាអាចរៀនពីរបៀបដោះស្រាយចំណោទបានទេ?</li>              
              <li>ហេតុអ្វីជំនាញដោះស្រាយចំណោទមានសារៈសំខាន់?</li>
              <li>បង្ហាញត្រួសៗនូវវិធីសាស្ត្រដោះស្រាយចំណោទទាំង៥</li>
              <li>បង្ហាញត្រួសៗនូវលំនាំជំហានទាំង៤</li>    
              <li>បង្ហាញវិធីសាស្ត្រទី២ សម្តែងតួ/ប្រើសម្ភារជាក់ស្តែង</li>          
              <li>បង្ហាញលំនាំគំរូទី១ គូររូបភាព គំនូសតាង/​ដ្យាក្រាម</li>
              <li>បង្ហាញវិធីសាស្រ្តទី៣ គូសជាតារាង</li>          
              <li>បង្ហាញវិធីសាស្ត្រទី៤ ទស្សទាយ,ស្មាន និងផ្ទៀងផ្ទាត់</li>
              <li>បង្ហាញវិធីសាស្រ្តទី៥ រកលំនាំគំរូ</li>          
              <li>ឆ្លុះបញ្ចាំង</li>
              <li>ធ្វើការជាក្រុមដោះស្រាយចំណោទដោយប្រើវិធីសាស្ត្រទាំង៥</li>          
              <li>សំណួរ ព័ត៌មានត្រឡប់</li>
            </ul>
        </>
},
{
  name: 'សិក្ខាសាលាស្តីលំនាំគំរូ',
  title:<>
            <ul style={{fontSize: '12px', listStyle: 'inside', color: '#333'}}>
              <li>ណែនាំអំពីសិក្ខាសាលា</li>
              <li>វិធីសាស្រ្តសិស្សមជ្ឈមណ្ឌល</li>
              <li>ការបង្រៀនពីរូបីទៅអរូបី</li>
              <li>សៀវភៅជំនួយ</li>
              <li>បង្ហាញណែនាំអំពីលំនាំគំរូ</li>
              <li>បង្រៀនអំពី លក្ខណៈទ្រង់ទ្រាយ</li>
              <li>បង្រៀនបង្ហាញៈ សកម្មភាពទី១ នៅក្នុងសៀវភៅលំនាំរបស់អង្គការនៅទំព័រទី៧</li>
              <li>បង្រៀនបង្ហាញៈ សកម្មភាពទី២ នៅក្នុងសៀវភៅលំនាំរបស់អង្គការនៅទំព័រទី៨ </li>
              <li>បង្រៀនបង្ហាញ សកម្មភាពទី៣ សៀវភៅលំនាំគំរូទំព័រទី១១</li>
              <li>ប្រើសម្ភារជាក់ស្តែងបង្កើតលំនាំគំរូ</li>
              <li>បទពិសោធន៍ជាមួយលំនាំគំរូនៅក្នុងជីវភាពប្រចាំថ្ងៃ(​សកម្មភាពទី៤ នៅក្នុងសៀវភៅលំនាំគំរូ)</li>
              <li>ការបង្រៀនបង្ហាញៈ សកម្មភាពទី៥ នៅក្នុងសៀវភៅលំនាំគំរូទំព័រទី១៤</li>
              <li>បង្ហាញសៀវភៅសិក្សាគោល</li>
              <li>ការបង្រៀនបង្ហាញៈ សកម្មភាពទី៧ នៅក្នុងសៀវភៅលំនាំគំរូទំព័រទី១៦</li>
              <li>ដាក់លេខទៅហ្នឹងលំនាំគំរូ</li>
              <li>ពិភាក្សាបញ្ហានៅក្នុងសៀវភៅសិក្សាគោលគណិតវិទ្យានៅក្នុងថ្នាក់ទី១</li>
              <li>ពិភាក្សាលើការកំណត់និងអនុវត្ត លក្ខ័ណ្ឌ ទៅលើលំនាំគំរូ </li>
            </ul>
        </>
}

  // More people...
]

const Article1Content1 = () => {
  return (
    <>
      <div className="content-body">
        <h3>៦. សិក្ខាសាលា</h3>

        {/* <h5 style={{fontWeight: 'bold', color: '#004587'}}>
          ប្រជុំបច្ចេកទេសថ្ងៃព្រហស្បតិ៍
        </h5> */}
        <p>
        មានសិក្ខាសាលាផ្សេងៗដែលគ្រូបង្រៀនត្រូវចូលរួមដើម្បីអភិវឌ្ឍចំណេះដឹងលើខ្លឹមសារ/បញ្ញតិគណិតវិទ្យានិងវិធី សាស្ត្រ បង្រៀន។ 
        ចំណុចទាំងអស់នេះមានសារៈសំខាន់ណាស់ក្នុងការជួយគាំទ្រគ្រូបង្រៀននៅក្នុងថ្នាក់ ព្រមទាំងរៀបចំមូលដ្ឋាន គ្រឹះ លើការរៀននិងបង្រៀនគណិតវិទ្យា។ 
        សិក្ខាសាលាទាំងអស់នេះនឹងត្រូវសម្របសម្រួលដោយទីប្រឹក្សាគរុកោសល្យជំនាញ ព្រមទាំងមានគោលបំណងឱ្យមានការចូលរួម បានអនុវត្តព្រមទាំងមានភាពងាយស្រួលក្នុងអនុវត្តនៅក្នុងថ្នាក់។
        </p>

        <p>សិក្ខាសាលាទាំងអស់នេះនឹងកើតមាននៅពេលផ្សេងៗគ្នានៅក្នុងពេលមួយឆ្នាំ។ គ្រប់ទីប្រឹក្សាគរុកោសល្យជំនាញ ទាំងអស់ ត្រូវបានទទួលបំប៉នឱ្យចេះដឹកនាំ 
          សម្រប់សម្រួលព្រមទាំងប្រើឯកសាររបស់អង្គការ SeeBeyondBorders ដើម្បីធ្វើការ សម្របសម្រួលសិក្ខាសាលាដល់គ្រូបង្រៀនថ្មីៗ។ 
          ទីប្រឹក្សាគរុកោសល្យជំនាញចូលរួមវគ្គបំប៉នគ្រូឧទេសរាល់គ្រប់សិក្ខាសាលា ទាំងអស់។</p>
        
        <h5 style={{fontWeight: 'bold', color: '#004587'}}>ការសម្របសម្រួលសិក្ខាសាលា</h5>
        <ul>
          <h6 style={{fontWeight: 'bold', color: '#004587'}}>ក. មុនពេលសិក្ខាសាលា</h6>
          <ul style={{fontSize: '16px', listStyle: 'inside', color: '#333'}}>
            <li>អានផែនការដឹកនាំ សម្របសម្រួលសិក្ខាសាលា ព្រមទាំងវត្ថុបំណង</li>
            <li>អានស្លាយបទបង្ហាញនៅក្នុងសិក្ខាសាលា ព្រមទាំងត្រៀមរៀបចំឱ្យរួចស្រេច</li>
            <li>ត្រៀមរៀបចំសម្ភារ ព្រីនឯកសារដែលត្រូវការក្នុងសិក្ខាសាលា</li>
            <li>កំណត់កាលបរិច្ឆេទ ផ្ញើការអញ្ជើញសិក្ខាកាម ព្រមទាំងកក់កន្លែងសិក្ខាសាលា</li>
            <li>ប្រសិនបើសិក្ខាសាលាដឹកនាំ សម្របសម្រួលដោយទីប្រឹក្សាគរុកោសល្យច្រើននាក់ កំណត់ថានរណាជាអ្នកកាន់ផ្នែក ណានៅក្នុងវគ្គសិក្ខាសាលា។</li>
            <li>កំណត់ថានរណាជាសិក្ខាកាមចូលរួមសិក្ខាសាលា (ដោយគ្រូផ្លាស់ប្តូរពីស្រុកមួយទៅស្រុក សូមធ្វើការកត់ត្រាព័ត៌មាន របស់គ្រូដើម្បីដឹងថានរណាត្រូវការទទួលការបំប៉ន)</li>
          </ul>

          <h6 style={{fontWeight: 'bold', color: '#004587'}}>ខ. អំឡុងពេលសិក្ខាសាលា</h6>
          <ul style={{fontSize: '16px', listStyle: 'inside', color: '#333'}}>
            <li>ប្រមូលវត្តមានរបស់សិក្ខាកាម ដែលមានឈ្មោះគ្រូ ភេទ  សាលា កម្រង និងកាលបរិច្ឆេទ ដែលគ្រូចូលរួមក្នុងគម្រោង ព្រមទាំងកាលបរិច្ឆេទរួមសិក្ខាសាលានីមួយៗ និងឈ្មោះសិក្ខាសាលា។</li>
            <li>តាមដានការចូលរួមរបស់សិក្ខាកាម និងធ្វើការកែតម្រូវប្រសិនបើមាន។</li>
            <li>គ្រូឧទេសធ្វើការបង្ហាញគំរូសកម្ម ឬពន្យល់ខ្លឹមសារ/បញ្ញតិដោយវិធីច្បាស់ៗដែលធ្វើឱ្យសិក្ខាកាមមានឱកាសអនុវត្ត សកម្មភាព ឬបញ្ញតិ។</li>
            <li>ប្រើការងារក្រុម និងទំហំក្រុមជាក្រុមតូច ដែលមានលាយគ្នាពីសិក្ខាកាមដែលមានសមត្ថភាពចម្រុះគ្នា ព្រមទាំងកំណត់ ថានរណាជាអ្នកធ្វើអ្វី និងកំណត់ពេលច្បាស់លាស់។ </li>
            <li>នៅពេលមានការពិភាក្សាក្រុម គ្រូឧទេសដើរជុំវិញក្រុមដើម្បីជួយ ប្រសិនមានតម្រូវការ។</li>
            <li>មន្ត្រីការិយាល័យចំនួន២នាក់ចូលរួមសិក្ខាសាលាដើម្បីធ្វើការជំរុញលើកទឹកចិត្ត ផ្តល់ព័ត៌មានត្រឡប់ដល់គ្រូឧទេស ព្រមទាំងជួយឱ្យពួកគាត់ធ្វើការដឹកនាំទៅតាមផែនការ។ 
              រៀនពីសិក្ខាកាមថាអ្វីដែលមានភាពងាយស្រួលនិងអ្វីដែលមាន ការលំបាក ឬយល់ខុស។</li>
          </ul>

          <h6 style={{fontWeight: 'bold', color: '#004587'}}>គ. ក្រោយពេលសិក្ខាសាលា</h6>
          <ul style={{fontSize: '16px', listStyle: 'inside', color: '#333'}}>
            <li>យើងប្រើទម្រង់ស្តង់ដារមួយដើម្បីធ្វើការវាយតម្លៃទៅតាមសំណួរដូចមាននៅក្នុងទម្រង់រង្វាយតម្លៃសិក្ខាសាលា។​ នៅចុងបញ្ចប់សិក្ខា 
              សាលាគ្រប់សិក្ខាកាមទាំងអស់ធ្វើការបំពេញទម្រង់រង្វាយតម្លៃសិក្ខាសាលាដែលជាកន្លែគេសួរពីគុណភាពនៃការដឹកនាំសិក្ខាសាលា។ 
              សិក្ខាកាមទទួលទម្រង់វាយតម្លៃសិក្ខាសាលារួចហើយបំពេញជាកំបាំងមុខ ប៉ុន្តែមានសរសេរឈ្មោះក្រុមសិក្ខាសាលាដែលបានចូលរួម 
              សរសេរឈ្មោះសិក្ខាសាលាដាក់នៅទម្រង់រង្វាយតម្លៃសិក្ខាសាលា។ គ្រប់ទម្រង់រង្វាយតម្លៃសិក្ខាសាលាត្រូវបានបញ្ចូលទិន្នន័យនិងធ្វើការ វិភាគរួចហើយសរសេរជារបាយការណ៍សង្ខេប។​</li>
          </ul>
        </ul>

        <h5 style={{fontWeight: 'bold', color: '#004587'}}>គ្រោងសិក្ខាសាលាដែលមាន</h5>
        <div className="px-4 sm:px-6 lg:px-8">          
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                        ឈ្មោះសិក្ខាសាលា
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        បរិយាយ
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {people.map((person, index) => (
                      <tr key={index}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                          {person.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.title}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>


        
      </div>
    </>
  );
};

export default Article1Content1;
