import "./Content.css";

const Article1Content4 = () => {
  return (
    <>
      <div className="content-body">
        <h3>ការចុះធ្វើប្រឹក្សាគរុកោសល្យរបស់ទីប្រឹក្សាគរុកោសល្យជំនាញ</h3>
        <p>
          ការចុះជួបគ្រូប្រឹក្សាគរុកោសល្យចាប់ផ្តើមដោយការជួបជាមួយគណៈគ្រប់គ្រងសាលាឬនាយកសាលាជាមុនសិនបន្ទាប់មកជួបជា
          មួយគ្រូ
          ប្រឹក្សាគរុកោសល្យប្រចាំសាលា។ទីប្រឹក្សាគរុកោសល្យជំនាញសង្កេតគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាធ្វើការផ្តល់ប្រឹក្សាគរុកោ
          សល្យនិងធ្វើការផ្តល់ព័ត៌មានត្រឡប់ឱ្យ គ្រូបង្រៀន។
          ការប្រជុំមានគោលដៅឯកភាពដើម្បីធ្វើការកែលម្អ ព្រមទាំងមានកំណត់ពេលចុះមក
          ធ្វើការផ្តល់ប្រឹក្សាគរុកោសល្យលើកក្រោយ។
          ចុងបញ្ចប់ទីប្រឹក្សាគរុកោសល្យជំនាញ
          ធ្វើរបាយការណ៍លើការផ្តល់ប្រឹក្សាគរុកោសល្យ។
          នៅពេលទីប្រឹក្សាគរុកោសល្យចុះទៅតាមសាលា៖
        </p>
        <ul className="list-style">
          <li>ជួបប្រជុំជាមួយគណៈគ្រប់គ្រងសាលារៀន (ប្រហែល ៣០នាទី )</li>
          <li> ប្រើប្រាស់ឧបករណ៍ទាំងអស់សម្រាប់ទីប្រឹក្សាគរុកោសល្យ ជំនាញ។</li>
          <li>
            សង្កេតគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលារៀនដែលកំពុងផ្តល់ប្រឹក្សាគរុកោសល្យដល់គ្រូបង្រៀនកម្រិតថ្នាក់ដំបូង។
          </li>
          <li>
            ផ្តល់ព័ត៌មានត្រឡប់បែបស្ថាបនាភ្លាមៗទៅកាន់គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាដោយប្រើប្រាស់ទម្រង់កែលម្អសម្រាប់គ្រូ
            ប្រឹក្សាគរុកោសល្យប្រចាំសាលា។
          </li>
          <li>
            ពិភាក្សាជាមួយគ្នា (ប្រហែល ៣០នាទី)
            អំពីជំហានបន្តរជាមួយគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលារៀន និងនាយកសាលា
            ក្នុងអំឡុងពេល២សប្ដាហ៍បន្ទាប់ទៀត។
          </li>
        </ul>
        <span className="right">
          <i>(ដកស្រង់ចេញពីសៀវភៅក្រសួង ទីប្រឹក្សាគរុកោសល្យជំនាញ ទំព័រទី 8-9)</i>
        </span>
      </div>
    </>
  );
};

export default Article1Content4;
