import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  SquaresPlusIcon,
  XMarkIcon,
  ArrowRightOnRectangleIcon,
} from "@heroicons/react/24/outline";
import {
  FaHome,
  FaChalkboardTeacher,
  FaDraftingCompass,
  FaMedal,
  FaBookReader,
  FaCalendarAlt,
  FaBookOpen,
  FaAd,
  FaDonate,
} from "react-icons/fa";

import { ChevronDownIcon, PlayCircleIcon } from "@heroicons/react/20/solid";
import LoginPage from "../pages/LoginPage";

const products = [
  {
    name: "កិច្ចតែងការ",
    description: "ជាគម្រោងមេរៀនប្រចាំថ្ងៃបង្រៀនតាមជំហាននីមួយៗ។",
    href: "lsplan",
    icon: FaCalendarAlt,
  },
  {
    name: "សៀវភៅធំ",
    description:
      "សៀវភៅធំ ជាធនធានសម្រាប់ឱ្យកុមារអាន ប្រកប និងយល់ពីខ្លឹមសារនៃពាក្យសាច់រឿង។",
    href: "/dashbaord/bigbook",
    icon: FaBookOpen,
  },
  {
    name: "ពាក្យប្រើញឹកញាប់",
    description:
      "ធានធានសម្រាប់បង្រៀនកុមារឱ្យស្គាល់ ចេះ ចាំពាក្យ ដែលប្រើប្រាស់ប្រចាំ ឬញឺកញាប់នៅក្នុងជីវិតជាក់ស្តែង។",
    href: "/dashboard/hfw",
    icon: FaAd,
  },
  {
    name: "ពាក្យពិបាក",
    description:
      "ធានធាន សម្រាប់កុមាររៀន ចងចាំ ឬជាពាក្យដែលពិបាកក្នុងការប្រកប ដែលកុមារត្រូវការជំនួយក្នុងការអាន។",
    href: "/dashboard/dfw",
    icon: SquaresPlusIcon,
  },
  {
    name: "ធនធានគណិតវិទ្យា",
    description:
      "ធនធាន គណិតវិទ្យាឌីជីថល ដែលមានបណ្តុំនៃគន្លឹះបង្រៀនគណិតវិទ្យាជាច្រើន សម្រាប់បង្រៀនដល់កុមារ ឱ្យឆាប់ចេះ​ ចាំ និងយល់។",
    href: "https://math.sbbdigitalresource.org/",
    icon: FaDraftingCompass,
  },
];
const callsToAction = [
  {
    name: "មើលវីដេអូ",
    href: "https://www.youtube.com/@SeeBeyondBorders",
    icon: PlayCircleIcon,
  },
  { name: "ឧបត្ថម្ភៈ", href: "https://seebeyondborders.org/", icon: FaDonate },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function HeaderNav() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="bg-sbb-dark-blue text-white">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <Link href="#" className="-m-1.5 p-1.5">
            <span className="sr-only">SeeBeyondBorders</span>
            <img
              className="h-8 w-auto"
              src={require("../assets/images/logo.png")}
              alt=""
            />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">បើកទំម្រងដើម</span>
            <Bars3Icon className="h-6 w-6 text-white" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12">
          <a
            href="/"
            className="text-md font-semibold leading-6 text-white flex items-center"
          >
            <span className="px-2">
              <FaHome />
            </span>
            ទំព័រដើម
          </a>

          <Popover className="relative">
            <Popover.Button className="flex items-center gap-x-1 text-md font-semibold leading-6 text-white">
              <span className="px-2">
                <FaChalkboardTeacher />
              </span>
              ធនធានថ្នាក់រៀន
              <ChevronDownIcon
                className="h-5 w-5 flex-none text-white"
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {products.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-4 text-md leading-6 hover:bg-gray-50"
                    >
                      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <item.icon
                          className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="flex-auto">
                        <a
                          href={item.href}
                          className="block font-semibold text-gray-900"
                        >
                          {item.name}
                          <span className="absolute inset-0" />
                        </a>
                        <p className="mt-1 text-gray-600 text-sm">
                          {item.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                  {callsToAction.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                    >
                      <item.icon
                        className="h-5 w-5 flex-none text-gray-400"
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          <a
            href="https://math.sbbdigitalresource.org/"
            className="text-md font-semibold leading-6 text-white flex items-center"
          >
            <span className="px-2">
              <FaDraftingCompass />
            </span>{" "}
            គណិតវិទ្យា
          </a>
          <a
            href="master"
            className="text-md font-semibold leading-6 text-white flex items-center"
          >
            <span className="px-2">
              <FaMedal />
            </span>
            ប្រឹក្សាគរុកោសល្យ
          </a>
          <a
            href="login"
            className="text-md font-semibold leading-6 text-white flex items-center"
          >
            <span className="px-2">
              <FaBookReader />
            </span>
            ចំណេះដឹងទូរទៅ
          </a>
        </Popover.Group>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <a
            href="login"
            className="text-md font-semibold leading-6 text-white flex px-2 items-center"
          >
            ចូលសិក្សា
            <span aria-hidden="true">
              <ArrowRightOnRectangleIcon className="w-8" />
            </span>
          </a>
        </div>
      </nav>

      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">SeeBeyondBorders</span>
              <img
                className="h-8 w-auto"
                src={require("../assets/images/logo-color.png")}
                alt=""
              />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-base rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 hover:bg-gray-50">
                        <span className="px-2">
                          <FaChalkboardTeacher />
                        </span>{" "}
                        ធនធានថ្នាក់រៀន
                        <ChevronDownIcon
                          className={classNames(
                            open ? "rotate-180" : "",
                            "h-5 w-5 flex-none"
                          )}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {[...products, ...callsToAction].map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="flex rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50 flex-block items-center"
                          >
                            <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                              <item.icon
                                className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                                aria-hidden="true"
                              />
                            </div>
                            {item.name}
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <a
                  href="https://sbbdigitalresource.org/"
                  className="-mx-3 rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 flex flex-block items-center"
                >
                  <span className="px-2">
                    <FaDraftingCompass />
                  </span>{" "}
                  គណិតវិទ្យា
                </a>
                <a
                  href="master"
                  className="-mx-3 rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 flex flex-block items-center"
                >
                  <span className="px-2">
                    <FaMedal />
                  </span>
                  ប្រឹក្សាគរុកោសល្យ
                </a>
                <a
                  href="general"
                  className="-mx-3 rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 flex flex-block items-center"
                >
                  <span className="px-2">
                    <FaBookReader />
                  </span>
                  ចំណេះដឹងទូរទៅ
                </a>
              </div>
              <div className="py-6">
                {/* <a
                  href="login"
                  className="-mx-3 rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 flex flex-block items-center"
                > */}
                ចូលសិក្សា
                <span aria-hidden="true">
                  <ArrowRightOnRectangleIcon className="w-8" />
                </span>
                <LoginPage />
                {/* </a> */}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
