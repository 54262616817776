// Import Content
import Content1 from "./acticle6Content/Content1";

import React, { useState } from "react";
import { Button, message, Steps, theme } from "antd";
const steps = [
  {
    title: "៦.១",
    content: <Content1 />,
  }
];

const MasterManualActicle6 = () => {
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const contentStyle = {
    height: "530px",
    textAlign: "left",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px solid ${token.colorBorder}`,
    marginTop: 16,
    overflow: "scroll",
  };
  return (
    <>
      <Steps current={current} items={items} />
      <div style={contentStyle}>{steps[current].content}</div>
      <div
        style={{
          marginTop: 24,
        }}
      >
        {current > 0 && (
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={() => prev()}
          >
            ថយក្រោយ
          </Button>
        )}

        {current < steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => next()}
            style={{ backgroundColor: "#004587" }}
          >
            បន្ទាប់
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => message.success("បានបញ្ចប់ដោយជោគជ័យ")}
            style={{ backgroundColor: "#004587" }}
          >
            បញ្ចប់
          </Button>
        )}
        
      </div>
    </>
  );
};
export default MasterManualActicle6;
