import "./Content.css";

const Article1Content1 = () => {
  return (
    <>
      <div className="content-body">
        <h3>៤. ប្រឹក្សាគរុកោសល្យពីទីប្រឹក្សាគរុកោសល្យជំនាញមានគុណភាព</h3>

        <h4 style={{ padding: 15, color: "#004587" }}>
          កសាងទំនាក់ទំនងជាមួយគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលា
        </h4>
        <p>
          ចំណុចសំខាន់បំផុតនៃការងារប្រឹក្សាគរុកោសល្យធ្វើឡើងដោយទីប្រឹក្សាគរុកោសល្យជំនាញ
          គឺការមានទំនាក់ទំនង ដ៏រឹងមាំ
          បើកចំហរនិងស្មោះត្រង់ជាមួយគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលា។
          ជារឿងសំខាន់ណាស់ដែលគ្រូប្រឹក្សាគរុកោសល្យមើលឃើញថា
          ទីប្រឹក្សាគរុកោសល្យជាអ្នក ដែលមកជួយ គាំទ្រធ្វើឱ្យមានការរីកចម្រើន
          មិនមែនជាអធិការទេ។ ប្រសិនបើមានចំណុចនេះ
          អ្នកនឹងឃើញមានការប្រឹក្សាគរុកោសល្យតាម បែបលក្ខណៈធម្មតា
          ជាជាងនៅពេលគ្រូប្រឹក្សាគរុកោសល្យមានអារម្មណ៍ភ័យញ័រ។
        </p>
        <ul className="list-style">
          <li>
            ទីប្រឹក្សាគរុកោសល្យជំនាញគួរតែចាប់ផ្តើមធ្វើការណែនាំខ្លួនទៅកាន់គ្រូប្រឹក្សាគរុកោសល្យ
            ប្រាប់ហេតុអ្វីបានជាមកសាលានេះ ព្រម
            ទាំងពន្យល់ប្រាប់ទៅកាន់គ្រូបង្រៀនថាអាចជួយដល់គ្រូបង្រៀននិងគ្រូប្រឹក្សាគរុកោសល្យព្រមទាំងនាយកសាលាដោយរបៀបណា។
            ទីប្រឹក្សាគរុកោសល្យជំនាញក៏ប្រាប់នូវអ្វីដែលជាក្តីសង្ឃឹមបានមកពីកិច្ចការផ្តល់ប្រឹក្សាគរុកោសល្យផងដែរ។
          </li>
          <li>
            ទីប្រឹក្សាគរុកោសល្យជំនាញនឹងធ្វើការពិភាក្សាផ្តួចផ្តើមដំបូងជាមួយគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាស្តីពីចំណុចខ្លាំង
            និងបញ្ហាប្រឈម។
            ឱ្យគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាគូសលើតារាងទម្រង់អក្សរYដើម្បីបង្ហាញពីចំណុចខ្លាំង,
            និងបញ្ហាប្រឈម /ភាពមិនប្រាកដដែលពួកគាត់មាន។
          </li>
          <li>
            ធ្វើការពន្យល់ដំណើរការព្រមទាំងលំនាំអ្វីខ្លះដែលនឹងកើតមានឡើង
            ពន្យល់ពីវិធីដែលថាជួយដល់គ្រូប្រឹក្សាគរុកោសល្យ មិនមែនការវិនិច្ឆ័យទេ។
          </li>
          <li>បង្កើតការយល់ដឹងពីចំណុចខ្លាំងនិងចំណុចកែលម្អរបស់គ្រូបង្រៀន។</li>
          <li>
            ចូលធ្វើចិត្តឱ្យល្អ និងមានការពិចារណា ព្រមទាំងយល់ពីអារម្មណ៍របស់គេ។
            អ្នកប្រហែលជាជ្រើសរើសក្នុងការផ្តល់ព័ត៌មានតិចតួច
            ដើម្បីធ្វើឱ្យគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាមិនមានអារម្មណ៍លើសលុប។
          </li>
          <li>
            ធ្វើចិត្តឱ្យទូលាយ និងរួសរាយ សាកសួរគ្រូប្រឹក្សាគរុកោសល្យឱ្យបានច្រើន
            មិនមែនត្រឹមតែរឿងសាលារៀនទេ ព្រមទាំងបើកចិត្ត
            ឱ្យទូលាយចែករំលែកនូវអ្វីដែលកើតឡើងចំពោះខ្លួនអ្នកផងដែរ។
          </li>
        </ul>
      </div>
    </>
  );
};

export default Article1Content1;
