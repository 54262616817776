// Import Content
import Content1 from "./acticle4Content/Content1";
import Content2 from "./acticle4Content/Content2";
import Content3 from "./acticle4Content/Content3";
import Content4 from "./acticle4Content/Content4";
import Content5 from "./acticle4Content/Content5";
import Content6 from "./acticle4Content/Content6";
import Content7 from "./acticle4Content/Content7";
import Content8 from "./acticle4Content/Content8";
import Content9 from "./acticle4Content/Content9";



import React, { useState } from "react";
import { Button, message, Steps, theme } from "antd";
const steps = [
  {
    title: "៤.១",
    content: <Content1 />,
  },
  {
    title: "៤.២",
    content: <Content2 />,
  },
  {
    title: "៤.៣",
    content: <Content3 />,
  },
  {
    title: "៤.៤",
    content: <Content4 />,
  },
  {
    title: "៤.៥",
    content: <Content5 />,
  },
  {
    title: "៤.៦",
    content: <Content6 />,
  },
  {
    title: "៤.៧",
    content: <Content7 />,
  },
  {
    title: "៤.៨",
    content: <Content8 />,
  },
  {
    title: "៤.៩",
    content: <Content9 />,
  },
];

const MasterManualActicle4 = () => {
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const contentStyle = {
    height: "530px",
    textAlign: "left",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px solid ${token.colorBorder}`,
    marginTop: 16,
    overflow: "scroll",
  };
  return (
    <>
      <Steps current={current} items={items} />
      <div style={contentStyle}>{steps[current].content}</div>
      <div
        style={{
          marginTop: 24,
        }}
      >
        {current > 0 && (
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={() => prev()}
          >
            ថយក្រោយ
          </Button>
        )}

        {current < steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => next()}
            style={{ backgroundColor: "#004587" }}
          >
            បន្ទាប់
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => message.success("បានបញ្ចប់ដោយជោគជ័យ")}
            style={{ backgroundColor: "#004587" }}
          >
            បញ្ចប់
          </Button>
        )}
        
      </div>
    </>
  );
};
export default MasterManualActicle4;
