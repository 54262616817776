import { FaFacebookSquare, FaTwitterSquare, FaLinkedin, FaYoutubeSquare} from "react-icons/fa";

const Footer = () => {
    return ( 
        <>
            <footer id="footer" className="bg-sbb-dark-blue">
                <div className="container flex flex-col items-center py-10  text-white">
                    <div className="copyright text-white">
                        &copy; រក្សាសិទ្ធិ {new Date().getFullYear()} 
                        <strong>
                            <span className="px-1">បណ្ណាល័យឌីជីថល</span>
                        </strong> 
                            គម្រោងអប់រំតាមបែបបច្ចេកវិទ្យា
                    </div>
                    <div className="credits flex flex-col items-center">                    
                        <p>បង្កើតដោយ <a href="https://seebeyondborders.org" target='_blank' rel="external noreferrer" className=" text-sbb-blue hover:text-sbb-orange">SeeBeyondBorders</a></p>
                        <div className='footer-social-icon'>
                            <div className="flex">
                                <a 
                                    href='https://www.facebook.com/SeeBeyondBorders/'
                                    className="px-1  text-white"
                                    target='_blank' 
                                    rel="external noreferrer">
                                    <FaFacebookSquare className='social-icon w-6 h-6'/>
                                </a>
                                <a 
                                    href='https://twitter.com/seebeyondborder' 
                                    target='_blank' 
                                    rel="external noreferrer"
                                    className="px-1  text-white"
                                >
                                    <FaTwitterSquare className='social-icon w-6 h-6'/>
                                </a>
                                <a 
                                    href='https://www.linkedin.com/company/seebeyondborders/mycompany/' 
                                    target='_blank' 
                                    rel="external noreferrer"
                                    className="px-1  text-white"
                                >
                                    <FaLinkedin className='social-icon w-6 h-6'/>
                                </a>
                                <a 
                                    href='https://www.youtube.com/user/seebeyondborders' 
                                    target='_blank' 
                                    rel="external noreferrer"
                                    className="px-1  text-white"
                                >
                                    <FaYoutubeSquare className='social-icon w-6 h-6'/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
     );
}
 
export default Footer;