export const Config = {
 

    // baseUrl : "http://localhost:8000/api/",
    // imagePath : "http://localhost:/project/image_path",
    // authPath: "https://localhost:8000/",

    baseUrl : "https://src-api.sbbdigitalresource.org/api/",
    imagePath : "https://sbbdigitalresource.org/project/image_path/",


    pagination : 10,
    staticToken : "",
    version : "1.1.0"
}