import "./Content.css";

const Article1Content2 = () => {
  return (
    <>
      <div className="content-body">
        <h3>ការជួបជាមួយការិយាល័យអប់រំស្រុក</h3>
        <p>
          ទីប្រឹក្សាគរុកោសល្យជួបជាមួយការិយាល័យអប់រំស្រុកយ៉ាងហោចណាស់ឱ្យបានចំនួន៣ដងក្នុងមួយឆ្នាំដើម្បីធ្វើការរាយការណ៍និងពិភាក្សា
          ការលំបាក។
        </p>
        <h3>ការជួបជាមួយនាយកសាលា</h3>
        <p>
          ទីប្រឹក្សាគរុកោសល្យជួបជាមួយនាយកសាលា/គណៈគ្រប់គ្រងសាលាដើម្បីសម្របសម្រួលឱ្យគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាមានពេល
          វេលាគ្រប់គ្រាន់សម្រប់ការចុះធ្វើប្រឹក្សាគរុកោសល្យ។
          អំឡុងពេលចុះម្តងៗវាជាឱកាស៖
        </p>
        <ul className="list-style">
          <li>
            ពិភាក្សាតម្រូវការរបស់គ្រូបង្រៀន គ្រូប្រឹក្សាគរុកោសល្យ និង សិស្ស។
          </li>
          <li>
            ពិភាក្សា/រាយការណ៍លើដំណើររីកចម្រើនរបស់គ្រូបង្រៀន
            គ្រូប្រឹក្សាគរុកោសល្យ និងរបស់សិស្ស។
          </li>
          <li>កសាងទំនាក់ទំនងជាមួយនាយកសាលា។</li>
          <li>
            ចែករំលែកនូវកាលវិភាគចុះប្រឹក្សាគរុកោសល្យ
            ព្រមទាំងស្វែងយល់នូវព្រឹត្តការណ៍ដែលប្រហែលកើតឡើងនឹងអាចប៉ះពាល់ដល់
            ថ្ងៃចុះមកធ្វើប្រឹក្សាគរុកោសល្យ។
          </li>
          <li>
            ធ្វើការស្វែងរកគំនិតគាំទ្រលើការធ្វើការប្រឹក្សាគរុកោសល្យ
            ព្រមទាំងធ្វើការគូសបង្ហាញនូវសារៈសំខាន់របស់ការងារនេះ។
          </li>
          <li>ផ្តល់ព័ត៌មានត្រឡប់ទៅនាយកសាលាលើភាពជោគជ័យនិងផលលំបាក។</li>
        </ul>
        <span className="right">
          <i>ឧបសម្ព័ន្ធទី២៖ការវាយតម្លៃដំបូងរបស់នាយកសាលា</i>
        </span>
      </div>
    </>
  );
};

export default Article1Content2;
