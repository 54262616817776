import "./Content.css";

const Article1Content2 = () => {
  return (
    <>
      <div className="content-body">
        <h3>ទំនួលខុសត្រូវរបស់ទីប្រឹក្សាគរុកោសល្យជំនាញ</h3>
        <p>
          ទីប្រឹក្សាគរុកោសល្យជំនាញ មានទំនួលខុសត្រូវដូចខាងក្រោម៖
          ក្នុងន័យផ្សព្វផ្សាយគុណភាពនៃការបង្រៀន និងលទ្ធផលសិក្សារបស់សិស្ស។
        </p>
        <ul className="list-style">
          <li>
            ចូលរួមក្នុងវគ្គបំប៉នស្តីពីវិធីសាស្ត្របង្រៀនដែលពាក់ព័ន្ធនិងនៅពេលដែលមាន
            ឧ.ការប្រើប្រាស់កញ្ចប់សម្ភារៈគណិតវិទ្យាថ្នាក់ដំបូង។
          </li>
          <li>ចូលរួមក្នុងវគ្គបំប៉នសម្រាប់ទីប្រឹក្សាគរុកោសល្យជំនាញ។</li>
          <li>
            រៀបចំផែនការចុះប្រឹក្សាគរុកោសល្យដល់គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលា
            និងនាយក/នាយិកាសាលារៀន ទៅ​
            តាមតម្រូវការជាក់ស្តែងជាមួយមន្ត្រីការិយាល័យអប់រំយុវជននិងកីឡាស្រុក។
          </li>
          <li>
            ផ្តល់ប្រឹក្សាគរុកោសល្យដល់គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាក្នុងម្នាក់ៗ
            យ៉ាងតិច ១ដង ក្នុងមួយខែ ដោយមានការសង្កេតការបង្រៀន រយៈពេញមួយម៉ោងសិក្សា។
          </li>
          <li>
            ប្រើប្រាស់ឧបករណ៍សង្កេតការបង្រៀនរបស់គ្រូ
            ដើម្បីជួយក្នុងការពិនិត្យតាមដានសកម្មភាព ការបង្រៀន
            និងក្នុងការផ្តល់ព័ត៌មានត្រឡប់។
          </li>
          <li>
            បង្កើត/កសាងទំនាក់ទំនងល្អជាមួយគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលា
            និងនាយក/នាយិកាសាលា។
          </li>
          <li>
            ធ្វើការផ្តល់ជំនួយ
            គាំទ្រផ្នែកបច្ចេកទេសដល់គណៈគ្រប់គ្រងសាលាក៏ដូចជាគ្រូប្រឹក្សាគរុកោសល្យផងដែរ។
          </li>
          <li>
            សម្របសម្រួលជាមួយក្រុមគណៈគ្រប់គ្រងសាលាក្នុងការជ្រើសរើសគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាសម្រាប់សាលារៀនរបស់
            ពួកគាត់។
          </li>
          <li>
            សរសេរ/ធ្វើរបាយការណ៍ស្តីពីការប្រឹក្សាគរុកោសល្យក្នុងពេលចុះម្តងៗ
            ព្រមទាំងផ្ញើឱ្យការិយាល័យអប់រំ យុវជននិងកីឡាស្រុក។
          </li>
          <li>
            ធ្វើការពិភាក្សាជាមួយគ្រូប្រឹក្សាគរុកោសល្យដើម្បីធ្វើការឆ្លុះបញ្ចាំងនូវចំណុចល្អ
            ព្រមទាំងស្វែងរកចំណុចឯកភាពគ្នាសម្រាប់យក ទៅកែលម្អ
            ឆ្លើយនឹងសំណួរព្រមទាំងចូលរួមក្នុងការដោះស្រាយការលំបាករបស់គ្រូប្រឹក្សាគរុកោសល្យក្រោយពីសង្កេតដំណើរការ
            ធ្វើការប្រឹក្សាគរុកោសល្យជាមួយគ្រូបង្រៀន។
          </li>
          <li>
            រៀបចំរបាយការណ៍ប្រឹក្សាគរុកោសល្យត្រីមាសនិងឆមាសផ្ញើជូនការិយាល័យអប់រំយុវជន
            និងកីឡាស្រុក។
          </li>
          <li>
            ធ្វើការវាស់វែងកម្រិតជំនាញគ្រូប្រឹក្សាគរុកោសល្យជារៀងរាល់៦ខែម្តង។
          </li>
          <li>
            ជ្រើសរើសដោយការិយាល័យអប់រំស្រុក ព្រមទាំងចេញលិខិតចំណាត់តាំងភារកិច្ច។
          </li>
          <li>ជាគ្រូបង្រៀនមានបទពិសោធន៍បង្រៀនល្អច្រើនឆ្នាំ</li>
          <li>
            មានសមត្ថភាពដឹកនាំសម្របសម្រួលការប្រជុំនិងវគ្គសិក្ខាសាលាប្រកបដោយប្រសិទ្ធភាព
          </li>
          <li>មានបទពិសោធន៍ការចុះធ្វើប្រឹក្សាគរុកោសល្យដល់គ្រូបង្រៀន</li>
          <li>ជាប្រធានកម្រង</li>
          <li>
            យល់ដឹងនូវជំនាញនិងវិធីសាស្រ្ដបង្រៀនដែលត្រូវសង្កេតការបង្រៀន
            យុទ្ធសាស្រ្ដសង្កេតនិងការប្រើប្រាស់ទម្រង់សង្កេត ការបង្រៀន។
          </li>
          <li>បានចូលរួមក្នុងកម្មវិធីប្រឹក្សាគរុកោសល្យនិង ទទួលការបំប៉ន។</li>
          <li>
            មានទំនាក់ទំនងល្អជាមួយគ្រូបង្រៀន ព្រមទាំងអាចដើរតួជាដៃគូ
            និងធ្វើគំរូដល់គ្រូបង្រៀន និងយល់ដឹងនូវបច្ចេក
            ទេសប្រឹក្សាគរុកោសល្យដើម្បីធ្វើការគាំទ្រក្នុងការបង្រៀន។
          </li>
          <li>អាចធ្វើការសម្របសម្រួលការប្រជុំថ្ងៃព្រហស្បត្តិ</li>
          <li>មានការចូលរួមប្រជុំទៀងទាត់</li>
          <li>មានមនសិការក្នុងការពង្រឹងគុណភាពប្រឹក្សាគរុកោសល្យនៅក្នុងកម្រង។</li>
          <li>ដឹងច្បាស់ពីវិធីធ្វើការផ្តល់ព័ត៌មានត្រឡប់។</li>
        </ul>
      </div>
    </>
  );
};

export default Article1Content2;
