import {
  FaBookReader,
  FaDraftingCompass,
  FaMedal,
  FaChalkboardTeacher,
} from "react-icons/fa";

export default function HomeHero() {
  const stats = [
    {
      id: 1,
      name: "ធនធានថ្នាក់រៀន",
      value: "teaching",
      icon: <FaChalkboardTeacher />,
    },
    {
      id: 2,
      name: "គណិតវិទ្យា",
      value: "https://math.sbbdigitalresource.org/",
      icon: <FaDraftingCompass />,
    },
    { id: 3, name: "ប្រឹក្សាគរុកោសល្យ", value: "login", icon: <FaMedal /> },
    { id: 4, name: "ចំណេះដឹងទូរទៅ", value: "login", icon: <FaBookReader /> },
  ];

  return (
    <div className="bg-sbb-dark-blue h-screen">
      <div className="relative isolate overflow-hidden">
        <img
          src={require("../assets/images/hero-bg-2.jpg")}
          className="absolute inset-0 -z-10 w-full object-cover h-screen"
          alt="SeeBeyondBorders"
        />

        <div className="mx-auto max-w-4xl py-20 sm:py-64 lg:py-80">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:max-w-none">
              <div className="text-center">
                <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                  បណ្ណាល័យសិក្សាឌីជីថល
                </h2>
                <p className="mt-4 text-lg leading-8 text-gray-300">
                  ផលិតដោយអង្គការទស្សនៈឥតព្រំដែន
                </p>
              </div>
              <dl className="mt-10 grid grid-cols-1 gap-0.5 overflow-hidden rounded-xl text-center sm:grid-cols-2 lg:grid-cols-4">
                {stats.map((stat) => (
                  <a
                    href={stat.value}
                    className="block font-semibold text-gray-900"
                  >
                    <div
                      key={stat.id}
                      className="flex flex-col bg-white/10 hover:bg-white/30 cursor-pointer p-8 items-center justify-center"
                    >
                      <dd className="order-first text-3xl font-semibold tracking-tight text-white py-2">
                        {stat.icon}
                      </dd>
                      <dt className="text-xl font-semibold leading-6 text-gray-300">
                        {stat.name}
                      </dt>
                    </div>
                  </a>
                ))}
              </dl>
            </div>
          </div>
        </div>

        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#000] to-[#000] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
      </div>
    </div>
  );
}
