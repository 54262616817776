const cards = [
  {
    name: 'ទស្សនៈវិស័យ',
    description: 'ជំនាន់មួយដែលកុមារកម្ពុជាត្រូវបានលើកកម្ពស់តាមរយៈការអប់រំ។',
    url:'https://www.seebeyondborders.org/vision-mission-and-values/',
    icon: require('../assets/images/vision.png')
  },
  {
    name: 'បេសកកម្ម',
    description: 'អង្គការទស្សនៈឥតព្រំដែន ផ្តល់នូវការបង្រៀន និងសិក្សាប្រកបដោយគុណភាព',
    url:'https://www.seebeyondborders.org/vision-mission-and-values/',
    icon: require('../assets/images/mission.png')
  },
  {
    name: 'គុណតម្លៃ',
    description: 'សមត្ថភាព សុចរិតភាព ផ្តល់ឱកាស ភាពក្លាហាន និងការគោរព',
    url:'https://www.seebeyondborders.org/vision-mission-and-values/',
    icon: require('../assets/images/integrity.png')
  },
]

export default function Vision() {
  return (
    <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
      <img
        src={require('../assets/images/hero-bg-3.jpg')}
        alt=""
        className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
      />
      <div className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl">
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#009ed6] to-[#004587] opacity-20"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu">
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#004587] to-[#009ed6] opacity-20"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-2xl font-bold tracking-tight text-white sm:text-4xl text-center">ទស្សនៈវិស័យ បេសកកម្ម</h2>
          <p className="mt-6 text-lg leading-8 text-gray-300 text-center">
          យើងប្រតិបត្តិការគម្រោងស្របតាមគោលដៅអភិវឌ្ឍន៍ប្រកបដោយនិរន្តរភាពរបស់អង្គការសហប្រជាជាតិ ចំណុចទី ៤ ការអប់រំប្រកបដោយគុណភាព។
          </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-8">
          {cards.map((card) => (
            <div key={card.name} className="flex gap-x-4 rounded-xl bg-white/5 p-6 ring-1 ring-inset ring-white/10">
              {/* <card.icon className="h-7 w-5 flex-none text-sbb-blue" aria-hidden="true" /> */}
              <img src={card.icon} alt='' className="h-10 w-11 flex-none text-sbb-blue" aria-hidden="true"/>
              <div className="text-base leading-7">
                <a href={card.url}>
                  <h3 className="font-semibold text-white">{card.name}</h3>
                </a>
                <p className="mt-2 text-gray-300">{card.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}