import "./Content.css";

const Article1Content2 = () => {
  return (
    <>
      <div className="content-body">
        <h3>តួនាទីរបស់ទីប្រឹក្សាគរុកោសល្យជំនាញ</h3>
        <p>
          ទីប្រឹក្សាគរុកោសល្យជំនាញ
          មានតួនាទីជួយគាំទ្រគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាក្នុងការលើកកម្ពស់ចំណេះដឹង
          ជំនាញ និងវិជ្ជា ជីវៈ
          ដើម្បីផ្តល់ប្រឹក្សាគរុកោសល្យបន្តដល់គ្រូបង្រៀនថ្នាក់ដំបូងឱ្យមានប្រសិទ្ធភាព
          ក្នុងន័យផ្សព្វផ្សាយគុណភាពនៃការបង្រៀន និងលទ្ធផលសិក្សារបស់សិស្ស។
        </p>
        <p>
          ទីប្រឹក្សាគរុកោសល្យជំនាញផ្តល់ការគាំទ្រដោយផ្ទាល់ដល់គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលា
          ក៏ដូចជានាយកសាលានៅកម្រិត សាលា និងកម្រិតកម្រងសាលារៀន
          សម្រាប់ការបង្រៀននៅកម្រិតថ្នាក់ដំបូង។
        </p>
        <span className="right">
          <i>
            (ប្រឹក្សាគរុកោសល្យសម្រាប់ ថ្នាក់ដំបូង ក្រសួងអប់រំ យុវជននិងកីឡា
            ខែកញ្ញា ឆ្នាំ២០២០។)
          </i>
        </span>
      </div>
    </>
  );
};

export default Article1Content2;
