import "./Content.css";

const Article1Content3 = () => {
  return (
    <>
      <div className="content-body">
        <h3>ការរៀបចំកាលវិភាគចុះធ្វើប្រឹក្សាគរុកោសល្យ</h3>
        <p>
          ទីប្រឹក្សាគរុកោសល្យជំនាញត្រូវធ្វើការទំនាក់ទំនងជាមួយគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាដើម្បីពិភាក្សាពីការកំណត់ថ្ងៃសម្រាប់
          ធ្វើការចុះប្រឹក្សាគរុកោសល្ឱ្យយបានជាក់លាក់។ផែនការនេះគួរមានកំណត់នូវព័ត៌មានដូចខាងក្រោម៖
        </p>
        <ul className="list-style">
          <li>កាលបរិច្ឆេទដែលត្រូវចុះមកធ្វើការប្រឹក្សាគរុកោសល្យ</li>
          <li>ឈ្មោះគ្រូបង្រៀន</li>
          <li>ថ្នាក់ទី</li>
          <li>មុខវិជ្ជានិងខ្លឹមសារមេរៀន</li>
          <li>គោលដៅឯកភាពសម្រាប់ធ្វើការកែលម្អដែលមានបានឯកភាពពីកាលលើកមុន</li>
        </ul>
        <span className="right">
          <i>ឧបសម្ព័ន្ធទី៣៖ គំរូកាលវិភាគចុះធ្វើការផ្តល់ប្រឹក្សាគរុកោសល្យ</i>
        </span>
      </div>
    </>
  );
};

export default Article1Content3;
