import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoutes from "./util/PrivateRoutes";

// Pages and Componants
import Home from "./pages/Home";
import PageNotFound from "./pages/404";

//Layouts
import RootLayout from "./layouts/RootLayout";
import MasterMentor from "./layouts/MasterMentor";

//Root Page
import TeachingResource from "./pages/teachRes/TeachRes";

//HFW Routes
import HfwGradeThree from "./pages/hfWord/DfwGradeThree";
import HfWordHome from "./pages/hfWord/HfWordHome";

// One Page Route
import HfwGradeOneComponents from "./componants/HfwGradeOneComp";
import HfwGradeTwoComp from "./componants/HfwGradeTwoComp";
import HfwGradeThreeComp from "./componants/HfwGradeThreeComp";

//Admin Route
import AdminDashboard from "./pages/admin/AdminDashboard";
import Register from "./pages/Register";
import Login from "./pages/Login";

//User Route
import UserDashboard from "./layouts/UserDashboard";

//HFW Route
import HfwTabs from "./componants/HfwTabs";
import DfwGradeThreeComp from "./componants/DfwGradeThreeComp";
import Hfword from "./pages/admin/Hfword";
import Dfword from "./pages/admin/Dfword";

//Math Route
import Canvas from "./canvas/Canvas";
import MentoringData from "./pages/admin/MentoringData";
import LessonPlandBuilder from "./pages/lessonPlan/LessonPlanBuilder";
import MasterManualActicle1 from "./pages/masterMentorPage/MasterManualActicle1";
import MasterManualActicle2 from "./pages/masterMentorPage/MasterManualActicle2";
import MasterManualActicle3 from "./pages/masterMentorPage/MasterManualActicle3";
import MasterManualActicle4 from "./pages/masterMentorPage/MasterManualActicle4";
import MasterManualActicle5 from "./pages/masterMentorPage/MasterManualActicle5";
import MasterManualActicle6 from "./pages/masterMentorPage/MasterManualActicle6";
import MasterManualActicle7 from "./pages/masterMentorPage/MasterManualActicle7";


import MasterPage from "./pages/masterMentorPage/MainPage";
// import LoginPage from "./pages/LoginPage_Acheived";
// import AdminLayout from "./layouts/AdminLayout";



function App() {
  return (
    <>
      <Router>
        <Routes>
          {/* Private Routes */}
          <Route element={<PrivateRoutes />}>
            <Route path="/master" element={<MasterMentor />}>
              <Route path="/master" element={<MasterPage />} />
              <Route path="/master/article1" element={<MasterManualActicle1 />}/>
              <Route path="/master/article2" element={<MasterManualActicle2 />}/>
              <Route path="/master/article3" element={<MasterManualActicle3 />}/>
              <Route path="/master/article4" element={<MasterManualActicle4 />}/>
              <Route path="/master/article5" element={<MasterManualActicle5 />}/>
              <Route path="/master/article6" element={<MasterManualActicle6 />}/>
              <Route path="/master/article7" element={<MasterManualActicle7 />}/>


            </Route>
          </Route>

          {/* Public Routes */}
          <Route path="/" element={<RootLayout />}>
            <Route path="/" element={<Home />} exact/>
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="teaching" element={<TeachingResource />} />
            <Route path="canvas" element={<Canvas />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>

          {/* Admin Routes */}
          <Route path="/admin" element={<Login />}>
            <Route path="/admin" element={<AdminDashboard />} />
            <Route path="hfword" element={<Hfword />} />
            <Route path="dfword" element={<Dfword />} />
            <Route path="mentoring" element={<MentoringData />} />
          </Route>

          {/* Resource Routes  */}
          <Route path="/dashboard" element={<UserDashboard />}>
            <Route path="/dashboard" element={<HfWordHome />} />
            <Route path="hfw" element={<HfwTabs />} />
            <Route path="dfw" element={<HfwGradeThree />} />
            <Route path="bb" />
            <Route path="ls" />
            <Route path="lessonbuilder" element={<LessonPlandBuilder />} />
          </Route>

          {/* Indivisual Routes */}
          <Route path="dashboard/hfw/grade1/:id" element={<HfwGradeOneComponents />}/>
          <Route path="dashboard/hfw/grade2/:id" element={<HfwGradeTwoComp />} />
          <Route path="dashboard/hfw/grade3/:id" element={<HfwGradeThreeComp />} />
          <Route path="dashboard/dfw/:weekId/:dayId" element={<DfwGradeThreeComp />}/>

        </Routes>
      </Router>
    </>
  );
}

export default App;

 // <>
    //   <Router>
    //     <Routes>
    //       {/* Private Routes */}
    //       <Route element={<PrivateRoutes />}>
    //         <Route path="/master" element={<MasterMentor />}>
    //           <Route path="/master" element={<MasterPage />} />
    //           <Route path="/master/article1" element={<MasterManualActicle1 />}/>
    //           <Route path="/master/article2" element={<MasterManualActicle2 />}/>
    //           <Route path="/master/article3" element={<MasterManualActicle3 />}/>
    //           <Route path="/master/article4" element={<MasterManualActicle4 />}/>
    //         </Route>
    //       </Route>

    //       {/* Public Routes */}
    //       <Route path="/" element={<RootLayout />}>
    //         <Route path="/" element={<Home />} exact/>
    //         <Route path="login" element={<Login />} />
    //         <Route path="register" element={<Register />} />
    //         <Route path="teaching" element={<TeachingResource />} />
    //         <Route path="canvas" element={<Canvas />} />
    //         <Route path="*" element={<PageNotFound />} />
    //       </Route>

    //       {/* Admin Routes */}
    //       <Route path="/admin" element={<Login />}>
    //         <Route path="/admin" element={<AdminDashboard />} />
    //         <Route path="hfword" element={<Hfword />} />
    //         <Route path="dfword" element={<Dfword />} />
    //         <Route path="mentoring" element={<MentoringData />} />
    //       </Route>

    //       {/* Resource Routes  */}
    //       <Route path="/dashboard" element={<UserDashboard />}>
    //         <Route path="/dashboard" element={<HfWordHome />} />
    //         <Route path="hfw" element={<HfwTabs />} />
    //         <Route path="dfw" element={<HfwGradeThree />} />
    //         <Route path="bb" />
    //         <Route path="ls" />
    //         <Route path="lessonbuilder" element={<LessonPlandBuilder />} />
    //       </Route>

    //       {/* Indivisual Routes */}
    //       <Route path="dashboard/hfw/grade1/:id" element={<HfwGradeOneComponents />}/>
    //       <Route path="dashboard/hfw/grade2/:id" element={<HfwGradeTwoComp />} />
    //       <Route path="dashboard/hfw/grade3/:id" element={<HfwGradeThreeComp />} />
    //       <Route path="dashboard/dfw/:weekId/:dayId" element={<DfwGradeThreeComp />}/>

    //     </Routes>
    //   </Router>
    // </>




// // import style
// import ProtectedRoute from "./util/ProtectedRoute";
// import "./App.css";

// //import React Companant
// import {
//   createBrowserRouter,
//   Route,
//   createRoutesFromElements,
//   Routes,
//   BrowserRouter,
//   RouterProvider,
// } from "react-router-dom";

// // Pages and Componants
// import Home from "./pages/Home";
// import PageNotFound from "./pages/404";

// //Layouts
// import RootLayout from "./layouts/RootLayout";
// import MasterMentor from "./layouts/MasterMentor";

// //Root Page
// import TeachingResource from "./pages/teachRes/TeachRes";

// //HFW Routes
// import HfwGradeThree from "./pages/hfWord/DfwGradeThree";
// import HfWordHome from "./pages/hfWord/HfWordHome";

// // One Page Route
// import HfwGradeOneComponents from "./componants/HfwGradeOneComp";
// import HfwGradeTwoComp from "./componants/HfwGradeTwoComp";
// import HfwGradeThreeComp from "./componants/HfwGradeThreeComp";

// //Admin Route
// import AdminDashboard from "./pages/admin/AdminDashboard";
// import Register from "./pages/Register";
// import Login from "./pages/Login";

// //User Route
// import UserDashboard from "./layouts/UserDashboard";

// //HFW Route
// import HfwTabs from "./componants/HfwTabs";
// import DfwGradeThreeComp from "./componants/DfwGradeThreeComp";
// import Hfword from "./pages/admin/Hfword";
// import Dfword from "./pages/admin/Dfword";

// //Math Route
// import Canvas from "./canvas/Canvas";
// import MentoringData from "./pages/admin/MentoringData";
// import LessonPlandBuilder from "./pages/lessonPlan/LessonPlanBuilder";
// import MasterManualActicle1 from "./pages/masterMentorPage/MasterManualActicle1";
// import MasterManualActicle2 from "./pages/masterMentorPage/MasterManualActicle2";
// import MasterManualActicle3 from "./pages/masterMentorPage/MasterManualActicle3";
// import MasterManualActicle4 from "./pages/masterMentorPage/MasterManualActicle4";

// import MasterPage from "./pages/masterMentorPage/MainPage";

// //Router
// const router = createBrowserRouter(
//   createRoutesFromElements(
//     <>
//       <Route path="/" element={<RootLayout />}>
//         <Route path="/" element={<Home />} />
//         <Route path="login" element={<Login />} />
//         <Route path="register" element={<Register />} />
//         <Route path="teaching" element={<TeachingResource />} />
//         <Route path="canvas" element={<Canvas />} />
//         <Route path="*" element={<PageNotFound />} />
//       </Route>

//       <Route path="/admin" element={<Login />}>
//         <Route path="/admin" element={<AdminDashboard />} />
//         <Route path="hfword" element={<Hfword />} />
//         <Route path="dfword" element={<Dfword />} />
//         <Route path="mentoring" element={<MentoringData />} />
//       </Route>

//       <Route path="/dashboard" element={<UserDashboard />}>
//         <Route path="/dashboard" element={<HfWordHome />} />
//         <Route path="hfw" element={<HfwTabs />} />
//         <Route path="dfw" element={<HfwGradeThree />} />
//         <Route path="bb" />
//         <Route path="ls" />
//         <Route path="lessonbuilder" element={<LessonPlandBuilder />} />
//       </Route>

//       <Route path="/master" element={<Login />}>
//         <Route path="/master" element={<MasterMentor />} />
//         <Route path="/master" element={<MasterPage />} />
//         <Route path="/master/article1" element={<MasterManualActicle1 />} />
//         <Route path="/master/article2" element={<MasterManualActicle2 />} />
//         <Route path="/master/article3" element={<MasterManualActicle3 />} />
//         <Route path="/master/article4" element={<MasterManualActicle4 />} />
//       </Route>

//       <Route
//         path="dashboard/hfw/grade1/:id"
//         element={<HfwGradeOneComponents />}
//       />
//       <Route path="dashboard/hfw/grade2/:id" element={<HfwGradeTwoComp />} />
//       <Route path="dashboard/hfw/grade3/:id" element={<HfwGradeThreeComp />} />
//       <Route
//         path="dashboard/dfw/:weekId/:dayId"
//         element={<DfwGradeThreeComp />}
//       />
//     </>
//   )
// );

// function App() {
//   return <RouterProvider router={router} />;
// }

// export default App;
