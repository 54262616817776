import {
  Button,
  Checkbox,
  Form,
  Input,
  Select,
  message,
  DatePicker
} from "antd";

//Import 
// import { useState } from "react";
import { request } from "../util/api";
import { useNavigate } from "react-router-dom";


const { Option } = Select;

const formItemLayout = {  
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const Register = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate()
  

  const onFinish = (values) => {
    
      request("post", "auth/register", {
        "email": values.email,
        "password": values.password,
        "firstname": values.firstname,
        "lastname": values.lastname,
        "gender": values.gender,
        "tel": values.tel,
        "dob": values.dob,
        "address": values.address,
        "role": values.role,
        "agreement": values.agreement

      } ).then((res) => {
        console.log(res)
        if(res.data.error === true){
          return message.info(res.data.message);
        } else {
          message.info(res.data.message);
          navigate('/login')
        }

        
      })       
  };



  return (
    <>
      <div className="flex min-h-full flex-col flex-1 justify-center px-6 my-10 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-xl pl-40 pb-4">
          <img
            className="mx-auto h-20 w-auto left-8"
            src={require('../assets/images/sbb-logo.png')}
            alt="SeeBeyondBorders"
          />
          <h2 className="mt-4 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            ទម្រង់ស្នើចុះឈ្មោះ
          </h2>
        </div>

        <div className="sm:mx-auto sm:w-full sm:max-w-xl">
          <Form        
            {...formItemLayout}
            form={form}
            name="register"
            onFinish={onFinish}            
            scrollToFirstError
            size="large"
          >
            <Form.Item            
              name="email"
              label= {<span className="font-Battambang">អ៊ីម៉ែល</span>}
              rules={[
                {
                  type: "email",
                  message: "អ្វីអ្នកបញ្ចូលមិនត្រូវតាមស្តង់ដា អ៊ីម៉ែលទេ សូមផ្ទៀងផ្ទាត់សញ្ញា (@)!",
                },
                {
                  required: true,
                  message: "សូមបញ្ចូលអ៊ីម៉ែលរបស់អ្នក!",
                },
              ]}
            >
              <Input className="border-sbb-blue rounded-md w-full h-10"
                    
              />
            </Form.Item>

            <Form.Item
              name="password"
              label={<span className="font-Battambang">ពាក្យសម្ងាត់</span>}
              rules={[
                {
                  required: true,
                  message: "សូមបញ្ចូលពាក្យសម្ងាត់!",
                },
              ]}
              hasFeedback
            >
              <Input.Password className="border-sbb-blue rounded-md w-full h-10"
                 
              />
            </Form.Item>

            <Form.Item
              name="confirm"
              label={<span className="font-Battambang">ផ្ទៀងផ្ទាត់ពាក្យសម្ងាត់</span>}
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "សូមផ្ទៀតផ្ទាត់ពាក្យសម្ងាត់!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "ពាក្យសម្ងាត់ដែលអ្នកបញ្ចូលមិនដូចគ្នានោះទេ!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password className="border-sbb-blue rounded-md w-full h-10"/>
            </Form.Item>

            <Form.Item
              name="firstname"
              label={<span className="font-Battambang">ឈ្មោះផ្ទាល់ខ្លួន</span>}
              tooltip="ឈ្មោះដែលអ្នកប្រើប្រាស់ផ្លូវការ"
              rules={[
                {
                  required: true,
                  message: "សូមបញ្ចូលឈ្មោះផ្ទាល់ខ្លួនរបស់អ្នក!",
                  whitespace: true,
                },
              ]}
            >
              <Input className="border-sbb-blue rounded-md w-full h-10"/>
            </Form.Item>

            <Form.Item
              name="lastname"
              label={<span className="font-Battambang">ឈ្មោះត្រកូល</span>}
              tooltip="ឈ្មោះត្រកូលរបស់អ្នក"
              rules={[
                {
                  required: true,
                  message: "សូមបញ្ចូលឈ្មោះត្រកូលរបស់អ្នក!",
                  whitespace: true,
                },
              ]}
            >
              <Input className="border-sbb-blue rounded-md w-full h-10"/>
            </Form.Item>

            <Form.Item              
              name="gender"
              label={<span className="font-Battambang">ភេទ</span>}
              rules={[
                {
                  required: true,
                  message: "សូមជ្រើសរើសភេទរបស់អ្នក!",
                },
              ]}
            >
              <Select placeholder="ជ្រើសរើសភេទរបស់អ្នក">
                <Option value={0}>ស្រី</Option>
                <Option value={1}>ប្រុស</Option>
                <Option value={3}>ផ្សេងទៀត</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="dob"
              label={<span className="font-Battambang">ថ្ងៃខែឆ្នាំកំណើត</span>}
              tooltip="សូមបញ្ចូល ថ្ងៃ ខែ ឆ្នាំ កំណ់តរបស់អ្នក"
              // rules={[
              //   {
              //     required: true,
              //     message: "សូមបញ្ចូលថ្ងៃខែឆ្នាំរបស់អ្នក!",
              //     whitespace: true,
              //   },
              // ]}
              required
            >
              {/* <Input className="border-sbb-blue rounded-md w-full h-10"/> */}
                <DatePicker />
            </Form.Item>

            <Form.Item
              name="tel"
              label={<span className="font-Battambang">លេខទូរស័ព្ទ</span>}
              rules={[
                {
                  required: true,
                  message: "សូមបញ្ចូលលេខទូរស័ព្ទរបស់អ្នក!",
                },
              ]}
            >
              <Input
                className="border-sbb-blue rounded-md w-full h-10"
              />
            </Form.Item >

            <Form.Item
              name="address"
              label={<span className="font-Battambang">អាស័យដ្ឋាន</span>}
              rules={[
                {
                  required: true,
                  message: "សូមបញ្ចូលអាស៧ដ្ឋានរបស់អ្នក!",
                },
              ]}
            >
              <Input                
                className="border-sbb-blue rounded-md w-full h-10"
              />
            </Form.Item>

            <Form.Item              
              name="role"
              label={<span className="font-Battambang">តួនាទី</span>}
              rules={[
                {
                  required: true,
                  message: "សូមជ្រើសរើសភេទរបស់អ្នក!",
                },
              ]}
            >
              <Select placeholder="ជ្រើសរើសតួនាទីរបស់អ្នក">
                <Option value={0}>ប្រឹក្សាគរុកោសល្យជំនាញ</Option>
                <Option value={1}>ប្រឹក្សាគរុកោសល្យ</Option>
                <Option value={3}>នាយកឬនាយិកា</Option>
                <Option value={4}>គ្រូភាសាខ្មែរ</Option>
                <Option value={5}>គ្រូគណិតវិទ្យា</Option>
                <Option value={6}>គ្រូបង្រៀន</Option>
                <Option value={8}>និស្សិតអប់រំ</Option>
                <Option value={9}>សិស្សឬនិស្សិត</Option>
                <Option value={10}>សហគមន៍</Option>
                <Option value={11}>អាណាព្យាបាល</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(new Error("Should accept agreement")),
                },
              ]}
              {...tailFormItemLayout}
            >
              <Checkbox className="font-Battambang">                
                ខ្ញុំអះអាងថា ខ្ញុំបានអាន និងយល់ព្រម <a href="/user/agreement"><span className="font-bold">កិច្ចព្រមព្រៀង</span></a> របស់គម្រោងអប់រំតាមបែបច្ចេកវិទ្យា
              </Checkbox>
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit" className="bg-sbb-blue rounded-md w-full h-10 font-Battambang">
                ចុះឈ្មោះ
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Register;
