import { Outlet } from "react-router-dom";
import HeaderNav from "../componants/HeaderNav";
import Footer from "../componants/Footer";

export default function RootLayout() {
  return (
    <div>
      <HeaderNav />
      <Outlet />
      <Footer />
    </div>
  );
}
