import "./Content.css";

const Article1Content2 = () => {
  return (
    <>
      <div className="content-body">
        <h3>តើអ្វីគឺជាភាពជាអ្នកដឹកនាំ?</h3>
        <p style={{ fontWeight: "bold" }}>
          សំបុត្រផ្ញើជូនទីប្រឹក្សាគរុកោសល្យជំនាញ
        </p>
        <p>
          ក្នុងខណៈដែលខ្ញុំសរសេរអត្ថបទនេះ
          វាជាខួបម្ភៃឆ្នាំរបស់ខ្ញុំក្នុងអាជីពជាអ្នកអប់រំ ក្នុងនោះមានជាង១៣ឆ្នាំ
          ដែលខ្ញុំបានធ្វើការក្នុង តួនាទីជាអ្នកដឹកនាំទាំងក្នុងវិស័យរដ្ឋ
          និងវិស័យអង្គការសង្គមស៊ីវិលលើវិស័យអប់រំ។
          ច្រើនឆ្នាំមកនេះខ្ញុំបានគិតរកចម្លើយពីសំណួរថា តើការ
          ដឹកនាំក្នុងអាជីពអ្នកអប់រំមានន័យដូចម្តេច? ប្រតិបត្តិដូចម្តេច?
        </p>
        <p>
          ជាទ្រឹស្តីនិងជាគោលការណ៍ ខ្ញុំជឿថាលោកអ្នកមិនពិបាកស្វែងរកចម្លើយនោះទេ។
          មានវគ្គសិក្សាជាច្រើនដែលនិយាយ អំពីភាព ជាអ្នកដឹកនាំ។ ជាពិសេស
          ក្រសួងអប់រំក៏បាននិងកំពុងផ្សព្វផ្សាយឯកសារណែនាំស្តីពីការគ្រប់គ្រង
          ដឹកនាំសាលារៀនយ៉ាងផុសផុលផងដែរ។ សំខាន់
          តើអ្នកជឿថាភាពជាអ្នកដឹកនាំមានន័យដូចម្តេចសម្រាប់ខ្លួនអ្នកផ្ទាល់!
        </p>
        <p>
          សម្រាប់ខ្ញុំ អ្នកណាក៏អាចជាអ្នកដឹកនាំបានដែរ។
          វាមិនចាំបាច់ទាល់តែខ្លួនមានតួនាទីខ្ពស់ ជានាយក នាយករង ឬប្រធាន
          ស្ថាប័ននោះឡើយ។ វាជារូបស្តែងនូវវីរៈភាព តាមរយៈទង្វើ
          និងផ្នត់គំនិតរបស់អ្នកទៅវិញទេ។
        </p>
        <p>
          ចំពោះការប្រតិបត្តិវិញ
          ខ្ញុំរីករាយនឹងប្រាប់អំពីបទពិសោធន៍ផ្ទាល់ខ្លួនចំពោះរឿងនេះ។ នៅឆ្នាំ២០០៧
          ខ្ញុំត្រូវបានតែងតាំងជានាយក
          សាលាទើបបើកថ្មីមួយកន្លែងក្នុងស្រុកជនបទមួយនៅខេត្តសៀមរាប។
          បីឆ្នាំក្នុងតួនាទីនេះ
          ខ្ញុំបានប្រឹងប្រែងស្វែងរកជំនួយរហូតសាលាមានអគារថ្មី ខ្លោងទ្វារថ្មី
          ប្រព័ន្ធទឹកស្រោចស្រព ដើមឈើថ្មី បន្ទប់កុំព្យុទ័រ
          បន្ទប់ពិសោធន៍វិទ្យាសាស្រ្ត រហូតអាចនិយាយបានថា ជាសាលាទំនើបមួយក្នុងស្រុក។
          អ្វីដែលមិនផ្លាស់ប្តូរនោះ គឺលទ្ធផលសិក្សា និងអត្រាគង់វង្សរបស់សិស្ស។
          តើខ្ញុំបានធ្វើអ្វីខុសទៅ?
        </p>
        <p>
          នៅឆ្នាំ២០១០
          ខ្ញុំបានចូលបម្រើការជាមួយអង្គការអប់រំក្នុងគម្រោងដ៏ធំសម្បើមមួយ។
          រយៈពេលបីឆ្នាំខ្ញុំបានសម្របសម្រួលវគ្គ
          សម្រាប់នាយកនិងនាយករងសាលាជាច្រើនរូបស្តីពីការកសាងផែនការអភិវឌ្ឍសាលារៀន
          និងភាពជាអ្នកដឹកនាំ។​ខ្ ញុំបានជួបបញ្ហា
          ចម្បងមួយនោះគឹសាលាភាគច្រើនបានចម្លងទស្សនៈវិស័យ
          និងផែនការយុទ្ធសាស្ត្រដូចៗគ្នាពីប្រភពណាមួយ។ ជាទូទៅពួកគាត់សរសេរថា
          «ក្នុងប្រាំឆ្នាំខាងមុខ សាលា………នឹងក្លាយជាសាលាកុមារមេត្រី»
          រួចបន្តរៀបរាប់សមាសភាគទាំង៦នៃកម្មវិធីជាតិមួយនេះ។ ជាលទ្ធផលអនុវត្តន៍
          សាលាមិនទទួលបានការប្រែប្រួលអ្វីមួយជាដុំកំភួននោះឡើយ។
          ថ្ងៃនេះទើបខ្ញុំយល់ថា សាលានីមួយៗគួរមានសេចក្តី
          ក្លាហានក្នុងការកំណត់គោលដៅអាទិភាពជាក់លាក់ដើម្បីធានាឱ្យបានថាអាចសម្រេចបានក្នុងលទ្ធភាពជាក់ស្តែង។
          អ្នកមិនអាចធ្វើនេះបន្តិច ធ្វើនោះបន្តិច ហើយរំពឹងលទ្ធផលធំដុំបាននោះទេ។
          សាលានីមួយៗ គួរតែផ្តោតធ្វើអ្វីមួយឱ្យខាងតែបានជោគជ័យតាម សេចក្តីប្រាថ្នា
          រួមរបស់អង្គភាព។
        </p>
      </div>
    </>
  );
};

export default Article1Content2;
