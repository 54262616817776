import "./Content.css";

const Article1Content8 = () => {
  return (
    <>
      <div className="content-body">
        <h3>វិធីសាស្រ្តផ្ដល់ព័ត៌មានត្រឡប់តាមបែបហាំបឺហ្គឺ</h3>
        <p>វិធីសាស្រ្តសម្រាប់ផ្ដល់ព័ត៌មានត្រឡប់ដ៏ល្អមួយគឺតាមគំរូនំហាំប៊ឺហ្គ័រ។ គំរូនំហាំប៊ឺហ្គ័រជាវិធីល្អមួយក្នុងការផ្តល់មតិកែលម្អ។ ការផ្ដល់មតិ កែលម្អ លើចំណុចអវិជ្ជមានច្រើន អាចធ្វើឱ្យគ្រូប្រឹក្សាគរុកោសល្យ 
          មិនហ៊ានបង្ហាញពីគំនិតផ្តួចផ្តើមរបស់ខ្លួនហើយមាន ឥរិយាបថពឹងផ្អែក លើអ្នកដទៃ ។ គ្រូប្រឹក្សាគរុកោសល្យអាចរៀនសូត្របានល្អនៅពេលទីប្រឹក្សាគរុកោសល្យញ្ចាក់ពីសកម្មភាព របស់ខ្លួនថា មានប្រសិទ្ធភាព និងភាពជោគជ័យ។ 
          លទ្ធផលគ្រូប្រឹក្សាគរុកោសល្យបង្កើនស្វ័យភាព និងមិនសូវពឹងផ្អែកលើអ្នកដទៃ។ ការផ្តល់មតិកែ លម្អតាមគំរូនំហាំប៊ឺហ្គ័រ ចាប់ផ្តើមពីចំណុចវិជ្ជមានឱ្យបានច្រើនជាមុន 
          បន្ទាប់មកចំណុចត្រូវកែលម្អចំនួនពីរទៅបី និងត្រូវបញ្ចប់ដោយលើក ចំណុចវិជ្ជមានមួយឬពីរ បន្ថែមទៀត។</p>
        
        <h5 style={{fontWeight: 'bold', color: '#004587'}}>ដំបូង៖</h5>
        <p>ទីប្រឹក្សាគរុកោសល្យជំនាញត្រូវ​លើកសរសើរពីចំណុចវិជ្ជមានឱ្យបានច្រើន ដើម្បីលើកទឹកចិត្តគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលា។ 
          គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាចង់ស្តាប់មតិកែលម្អរបស់ទីប្រឹក្សាគរុកោសល្យជំនាញបន្ទាប់ពីបានឮពីចំណុចវិជ្ជមានជាមុន។</p>
        
        <h5 style={{fontWeight: 'bold', color: '#004587'}}>បន្ទាប់៖</h5>
        <p>ទីប្រឹក្សាគរុកោសល្យជំនាញត្រូវ​លើកឡើងពីចំណុចគួរកែលម្អ តែមិនត្រូវលើកច្រើនចំណុចពេកទេ គួរលើកយកតែពីរ ឬបីចំណុច ណាដែលសំខាន់ និងអាចឱ្យគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាយកទៅអនុវត្តបាន។ </p>

        <h5 style={{fontWeight: 'bold', color: '#004587'}}>ចុងក្រោយ៖</h5>
        <p>ទីប្រឹក្សាគរុកោសល្យជំនាញបញ្ចប់ដោយលើកចំណុចវិជ្ជមានមួយ ឬពីរបន្ថែមទៀត ដើម្បីបញ្ជាក់ថាអ្វីដែលគ្រូប្រឹក្សាគរុកោសល្យ ប្រចាំសាលាកំពុងធ្វើពិតជាត្រឹមត្រូវ 
          ព្រមទាំងធានាថាពួកគាត់នឹងបន្តអនុវត្តចំណុចវិជ្ជមានទាំងអស់នេះបានល្អទៅទៀត។</p>
        
        <span className="right">
          <i>
          (ឯកសារបំណិនផ្តល់ព័ត៌មានត្រឡប់ ឆ្នាំ២០១៦)
          </i>
        </span>
      </div>
    </>
  );
};

export default Article1Content8;
