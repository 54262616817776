import HomeHero from "../componants/HomeHero"
import About from "../componants/About";
import Vision from "../componants/Vision";


const Home = () => {
    return ( 
        <>
            <HomeHero/>        
            <About/>
            <Vision/>       
        </>
     );
}
 
export default Home;