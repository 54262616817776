import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import {Carousel} from 'antd'
// import RescFooter from '../../componants/Resc.footer';
import { useParams } from 'react-router-dom';
import data from '../../data/hfw.grade2.json';


const imgSlides = [
    { url: require("../../assets/images/hfword/grade2/slide/img01.jpg") },
    { url: require("../../assets/images/hfword/grade2/slide/img02.png") },
    { url: require("../../assets/images/hfword/grade2/slide/img03.png") },
];

export default function HfwGradeTwo() {
  const {id} = useParams()
  return (
    <>
    <div className='container py-2'>
        <Carousel autoplay className="rounded">
            {imgSlides.map((slide, slideIndex) => (
              <>
            <div>
                <img
                src={slide.url}
                alt=""
                className="w-[1660px] h-[420px] object-cover object-center rounded"
                />
            </div>           
            </>
            ))}
        </Carousel>
        
        <div className='container justify-center'>

        <h1 className="text-2xl font-medium text-gray-500 text-center py-3">ពាក្យប្រើញឹកញាប់ | <span className="text-sbb-dark-blue font-bold">ថ្នាក់ទី២</span></h1>
            <ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3">
            {data.grade2.map((week, weekIndex) => (
            <a href={`hfw/grade2/${week.id}`}> 
              <li key={id} className="col-span-1 flex rounded-md shadow-sm">
                <div className="bg-sbb-dark-blue flex w-20 flex-shrink-0 items-center justify-center rounded-l-md text-white text-center font-bold">
                    <h1 className='text-[18px] font-bold leading-8 py-2'> សប្តាហ៍ទី <br/> {week.id}</h1>
                </div>
                <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                  <div className="flex-1 truncate px-4 py-2 w-auto">
                    <a href={`hfw/grade2/${week.id}`} className="font-medium text-gray-900 hover:text-gray-600 ">
                        <h2 className='text-[18px]'> ពាក្យប្រើញឹកញាប់</h2>
                    </a>
                    <p className="text-gray-500">ថ្នាក់ទី២</p>
                  </div>
                  <div className="flex-shrink-0 pr-2">
                    <button
                      type="button"
                      className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <span className="sr-only">Open options</span>
                      <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </li>
              </a>
            ))}
        </ul>
        </div>
      </div>

    {/* <RescFooter/> */}
    </>    
  )
}


