import "./Content.css";

const Article1Content3 = () => {
  return (
    <>
      <div className="content-body">
        <h3>ការសន្ទនាលំបាក</h3>
        <p>
          ពេលខ្លះទីប្រឹក្សាគរុកោសល្យេជំនាញត្រូវប្រាប់ទៅគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលានូវសារកែលម្អអំពីឥរិយាបថ
          អាកប្បកិរិយា និង សកម្មភាពបង្រៀន និងរៀនរបស់គ្រូប្រឹក្សាគរុកោសល្យ។
          ទីប្រឹក្សាគរុកោសល្យជំនាញត្រូវធ្វើការតទល់ជាមួយគ្រូប្រឹក្សាគរុកោសល្យ
          ក៏ប៉ុន្តែ ស្របពេលជាមួយគ្នានោះដែរត្រូវការរក្សាទំនាក់ទំនងល្អ។
          ដូច្នេះហើយសារដែលត្រូវនិយាយនោះត្រូវតែប្រកបដោយភាពខ្វល់ខ្វាយ
          និងយកចិត្តទុកដាក់។
        </p>

        <h4 style={{ paddingTop: 5, paddingBlock: 5, color: "#004587" }}>
          ក. ការសរសើរ និងការកែលម្អ
        </h4>
        <p>
          គេនិយាយថា៖មនុស្សភាគច្រើនពិបាកទទួលយកការកែលម្អ ។
          ដើម្បីឱ្យគាត់រកទទួលយកការកែលម្អបាន លុះត្រាតែ ការសរសើរឱ្យ បានគ្រប់គ្រាន់
          ។ ម្យ៉ាងទៀត បើមានគេសរសើរ យើងតែងតែចង់ដឹង និងចង់ឮនូវពាក្យសម្តីរបស់គេ ។
          ដូចនេះដើម្បីឱ្យគ្រូប្រឹក្សាគរុកោសល្យយកអនុសាសន៍ទៅអនុវត្តបានល្អ
          ទីប្រឹក្សាគរុកោសល្យត្រូវប្រើវិធី កែលម្អតាមបែបស្ថាបនា (ទាំងការសរសើរ
          ទាំងកែលម្អ)ក្នុងពេលផ្ដល់ព័ត៌មានត្រឡប់ ។ ការកែលម្អតាមបែបស្ថាបនា
          មានន័យថា ទាំងសារខ្លឹមសារនិងសារ ទំនាក់ទំនងមានសារៈសំខាន់ដូចគ្នា ។
          គ្រូប្រឹក្សាគរុកោសល្យ
          នឹងមានចិត្តចង់ស្តាប់ពីចំណុចកែលម្អនៅពេលដែលមានទំនាក់ទំនងល្អ
          នឹងមានការសរសើរពីទីប្រឹក្សាគរុកោសល្យជំនាញផងដែរ ។
        </p>
        <p>
          ដើម្បីធានាថាគ្រូប្រឹក្សាគរុកោសល្យទទួលយកការកែលម្អ
          ព្រមទាំងធ្វើការស្តាប់និងខំធ្វើការលើចំណុចកែលម្អទាំងអស់នោះ
          ទីប្រឹក្សាគរុកោសល្យជំនាញអាចអនុវត្តក្នុងការផ្ទេរសារ
          ព្រមទាំងរក្សាទំនាក់ទំនងល្អផងនោះក្នុងពេលជាមួយគ្នា។ មានវិធី២
          យ៉ាងក្នុងការកែលម្អតាមបែបស្ថាបនាគួរចៀសវាងចំណុចខាងក្រោម៖
        </p>
        <h4 style={{ paddingTop: 5, paddingBlock: 5, color: "#004587" }}>
          និយាយត្រង់ៗ ឬនិយាយចំៗពេក៖
        </h4>
        <p>
          ប្រសិនបើសារដែលផ្តល់ឱ្យមានលក្ខណៈត្រង់ពេក
          វាអាចធ្វើឱ្យប៉ះពាល់ដល់អារម្មណ៍របស់គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលា
          ហើយគ្រូប្រឹក្សា គរុកោសល្យប្រចាំសាលា
          អាចរកវិធីនិយាយតបតវិញដើម្បីការពារខ្លួនមិនព្រមទទួលយកគំនិត
          កែលម្អនោះទៅអនុវត្ត។
        </p>
        <h4 style={{ paddingTop: 5, paddingBlock: 5, color: "#004587" }}>
          ប្រុងប្រយ័ត្នពេក៖
        </h4>
        <p>
          ពេលខ្លះទីប្រឹក្សាគរុកោសល្យក៏អាចផ្ដល់ការកែលម្អតាមបែបស្ថាបនាដល់កគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាដោយប្រុងប្រយ័ត្នពេក
          ដោយសារតែគាត់មិនចង់ឱ្យប៉ះពាល់ដល់អារម្មណ៍របស់គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលា។
          ទីប្រឹក្សាគរុកោសល្យជំនាញបារម្ភថាទំនាក់ទំនង
          របស់គាត់ជាមួយគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាអាចមិនសូវល្អ
          នៅពេលដែលគាត់ធ្វើការកែលម្អចំៗពេក ជាហេតុធ្វើឱ្យគាត់សម្រេចចិត្តថា
          មិនប្រាប់គ្រូប្រឹក្សាគរុកោសល្យ។
          នៅពេលទីប្រឹក្សាគរុកោសល្យជំនាញគិតតែពីទំនាក់ទំនងខ្លាំងពេក
          ធ្វើឱ្យខ្លឹមសារដែលផ្តល់ឱ្យគ្រូប្រឹក្សាគរុ កោសល្យមិនច្បាស់លាស់
          និងមានភាពជាក់លាក់។
          នៅចុងបញ្ចប់គ្រូប្រឹក្សាគរុកោសល្យមិនដឹងពីចំណុចកែលម្អដែលខ្លួនត្រូវអនុវត្តបន្ត។
          ដូច្នេះដើម្បីឱ្យការផ្តល់ព័ត៌មានត្រឡប់មានប្រសិទ្ធភាព
          យើងត្រូវធ្វើការកែលម្អតាមបែបស្ថាបនាដោយប្រើប្រាស់ “សារទំនាក់ទំនង
          សារខ្លឹមសារ”។
        </p>
      </div>
    </>
  );
};

export default Article1Content3;
