import "./Content.css";

const Article1Content8 = () => {
  return (
    <>
      <div className="content-body">
        <h3>ការរៀបចំគោលដៅ</h3>
        <p>ទីប្រឹក្សាគរុកោសល្យត្រូវតែឱ្យច្បាស់និងប្រាកដក្នុងការបង្កើតគោលដៅដែលប្រកបគុណប្រយោជន៍ជាមួយគ្រូប្រឹក្សាគរុកោសល្យ ប្រចាំសាលា ព្រមទាំងរៀបចំកាលបរិច្ឆេទជាក់លាក់ដើម្បីមកធ្វើការផ្ទៀងផ្ទាត់៖
          បំពេញគ្រប់ចំណុចទាំងអស់នៅក្នុងទម្រង់ផែនការសកម្មភាពជាមួយគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលា ព្រមទាំងអនុញ្ញតិឱ្យគាត់ចូលរួមក្នុង ដំណើរការនេះផង។ ទីប្រឹក្សាគរុកោសល្យត្រូវសរសេរយុទ្ធសាស្ត្រជាក់លាក់ 
          (សាម័ញ្ញ ងាយយល់ និងងាយសម្រេចបាន) ដែលទុកឱ្យគ្រូ ប្រឹក្សាគរុកោសល្យធ្វើសម្រេចបានទៅតាមគោលដៅបានឯកភាពគ្នា ព្រមទាំងអាចយកទៅអនុវត្តនៅពេលមេរៀនលើកក្រោយទៀតបាន។
        </p>

        <p>ទីប្រឹក្សាគរុកោសល្យជំនាញអាចធ្វើការបង្ហាញគំរូសកម្មភាព (បើត្រូវការ) ដើម្បីធ្វើឱ្យគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាយល់កាន់តែច្បាស់។ នៅពេលទីប្រឹក្សាគរុកោសល្យជំនាញមកសង្កេតម្តងទៀត 
          នឹងធ្វើការផ្តោតទៅលើផែនការសកម្មភាពដែលបានផ្តល់យុទ្ធសាស្ត្រឱ្យ។ ប្រសិនបើទីប្រឹក្សាគរុកោសល្យជំនាញមកសង្កេតគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាម្តងទៀតឃើញថាជំនាញ ឬក៏គោលដៅឯកភាពបាន 
          កើនឡើង ទីប្រឹក្សាគរុកោសល្យត្រូវចាថា គោលដៅឯកភាពសម្រេចជោគជ័យហើយ។ តែបើមិនសម្រេចទេត្រូវចាថា ត្រូវបន្តទៀត ព្រមទាំង សាកល្បងជាមួយយុទ្ធសាស្ត្រថ្មី។ 
          ទីប្រឹក្សាគរុកោសល្យជំនាញត្រូវតែរក្សាកំណត់ត្រាសង្កេតនៅលើទម្រង់វាស់វែងកម្រិតជំនាញរបស់គ្រូ ប្រឹក្សាគរុកោសល្យប្រចាំសាលា។ 
          (ទម្រង់វាស់វែងកម្រិតជំនាញរបស់គ្រូប្រឹក្សាគរុកោសល្យប្រហែលជាអាចប្រើដើម្បីណែនាំជួយគ្រូប្រឹក្សាគរុ កោសល្យប្រចាំសាលាឱ្យមានការរីកចម្រើនលើវិជ្ជាជីវៈ។)</p>

          <span className="right">
            <i>
              ឧបសម្ព័ន្ធ៥៖ ផែនការសកម្មភាព
            </i>
          </span>
          
      </div>
    </>
  );
};

export default Article1Content8;
