import { Outlet } from "react-router-dom";
import React, { useState } from "react";
import { Steps } from "antd";
import { Link } from "react-router-dom";
// import { FaUser, FaSignOutAlt, FaHome } from "react-icons/fa";
// import { useNavigate } from "react-router-dom";
import "./MasterMentorLayout.css";

const MasterManual = () => {
  // const navigate = useNavigate();
  const [current, setCurrent] = useState(0);
  const onChange = (value) => {
    console.log("onChange:", value);
    setCurrent(value);
  };

  // const onClick = () => {
  //   navigate("/");
  // };

  return (
    <>
      <div className="main">
        {/* <div className="mainHeader">
          <div className="userProfile">
            <FaHome onClick={onClick} className="home-icon" />
          </div>
          <h1>ធនធានស្តីពីទីប្រឹក្សាគរុកោសល្យជំនាញ ឆ្នាំ២០២២</h1>
          <div className="signoutIcon">
            <button
              onClick={() => {
                localStorage.setItem("is_login", "0");
                window.location.href = "/login";
              }}
              className="logout-icon"
            >
              <FaSignOutAlt />
            </button>
          </div>
        </div> */}

        <div className="content">
          <div className="contentBody">
            <Link to="/master">
              <h1 style={{fontSize: '1.5rem'}}>ធនធានស្តីពីទីប្រឹក្សាគរុកោសល្យជំនាញ ឆ្នាំ២០២២</h1>
              <h3 style={{textAlign:'center'}}>មាតិកា</h3>
            </Link>
            <Steps
              className="articleStep"
              current={current}
              onChange={onChange}
              direction="vertical"
              items={[
                {
                  title: (
                    <Link to="/master/article1">
                      សេចក្តីផ្តើមទីប្រឹក្សាគរុកោសល្យជំនាញ
                    </Link>
                  ),
                },
                {
                  title: (
                    <Link to="/master/article2">
                      ដំណើរការចុះផ្តល់ប្រឹក្សាគរុកោសល្យតាមសាលារៀន
                    </Link>
                  ),
                },
                {
                  title: (
                    <Link to="/master/article3">
                      ភាពជាអ្នកដឹកនាំរបស់ទីប្រឹក្សាគរុកោសល្យជំនាញ
                    </Link>
                  ),
                },
                {
                  title: (
                    <Link to="/master/article4">
                      ប្រឹក្សាគរុកោសល្យពីទីប្រឹក្សាគរុកោសល្យជំនាញមានគុណភាព
                    </Link>
                  ),
                },
                {
                  title: (
                    <Link to="/master/article5">
                      ការប្រជុំ
                    </Link>
                  ),
                },
                {
                  title: (
                    <Link to="/master/article6">
                      សិក្ខាសាលា
                    </Link>
                  ),
                },
                {
                  title:(
                    <Link to="/master/article7">
                      ការពិនិត្យតាមដាននិងធ្វើការវាយតម្លៃ
                    </Link>
                  ),
                },
              ]}
            />
          </div>
          <div className="outledBody">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default MasterManual;
