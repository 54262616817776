import "./Content.css";

const Article1Content2 = () => {
  return (
    <>
      <div className="content-body">
        <h3>វិធីសាស្រ្តក្នុងការស្ដាប់៖</h3>
        <h4 style={{ padding: 15, color: "#004587" }}>ក. ការស្ដាប់សកម្ម</h4>
        <p style={{ fontWeight: "bold" }}>
          ការស្តាប់សកម្ម គឺជាបំណិនស្តាប់ដើម្បីបង្កើនការយល់ដឹង
          និងទំនាក់ទំនងល្អរវាងអ្នកស្គាល់ ដឹងអ្នកនិយាយ។ អ្នកស្តាប់សកម្មត្រូវយក
          ចិត្តទុកដាក់ស្តាប់អ្នកនិយាយឱ្យបានទាំងភាសានិយាយ និងភាសាកាយវិការ
          បន្ទាប់មកសង្ខេប ឬនិយាយឡើងវិញនូវចំណុចសំខាន់ៗ នៃសាររបស់អ្នកនិយាយ។
        </p>
        <h4 style={{ padding: 15, color: "#004587" }}>
          ការស្ដាប់សកម្មមានន័យថា៖
        </h4>
        <ul className="list-style">
          <li style={{ fontWeight: 700 }}>យល់</li>
          <p>តើសាររបស់គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាបានប្រាប់ខ្ញុំអ្វីខ្លះ?</p>
          <li style={{ fontWeight: 700 }}>សង្កេតឥរិយាបថ(អារម្មណ៍របស់ដៃគូ)</li>
          <p>
            តើគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាមានអារម្មណ៍ដូចម្តេចកំពុងសន្ទនាគ្នា?
            ធុញថប់ ព្រួយបារម្ភ សប្បាយចិត្ត ឬមានទំនុកចិត្ត? តើអារម្មណ៍ទាំងនេះ
            អាចជះឥទ្ធិពលដល់ការអនុវត្តការងាររបស់គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាដែរឬទេ?
          </p>
          <li style={{ fontWeight: 700 }}>គិត</li>
          <p>
            តើខ្ញុំសង្កេត
            ឬកត់សម្គាល់ឃើញអ្វីខ្លះនៅពេលដែលគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាកំពុងនិយាយ?
          </p>
          <li style={{ fontWeight: 700 }}>ដឹង(ឆាប់ដឹងសាច់រឿង)</li>
          <p>
            តើមានរឿងអ្វីដែលគ្រូប្រឹក្សាគរុកោសល្យចង់និយាយប៉ុន្តែខ្លាចមិនហ៊ាននិយាយ?
            តើខ្ញុំត្រូវធ្វើដូចម្តេចដើម្បីឱ្យគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាមានភាពក្លាហានក្នុងការពិភាក្សាគ្នា?
          </p>
        </ul>

        <h4 style={{ paddingTop: 5, paddingBlock: 5, color: "#004587" }}>
          ខ. អត្ថប្រយោជន៏នៃការស្ដាប់សកម្ម៖
        </h4>

        <p>ការស្ដាប់សកម្មមានសារៈប្រយោជន៍ជាច្រើនដូចជា៖</p>
        <ul className="list-style">
          <li>លើកទឹកចិត្តអ្នកនិយាយ</li>
          <li>
            លើកកម្ពស់ការទុកចិត្ត និងការគោរពគ្នាទៅវិញទៅមក
            រវាងគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលា និងទីប្រឹក្សាគរុកោសល្យជំនាញ។
          </li>
          <li>ជួយឱ្យអ្នកស្តាប់ទទួលព័ត៌មានបានល្អ និងគ្រប់ជ្រុងជ្រោយ</li>
          <li>លើកកម្ពស់ទំនាក់ទំនង</li>
          <li>ដោះស្រាយបញ្ហាបានកាន់តែប្រសើរ</li>
          <li>ទទួលបានការសហការល្អ</li>
          <li>លើកកម្ពស់ការយល់ចិត្តគ្នា ។</li>
        </ul>

        <h4 style={{ paddingTop: 5, paddingBlock: 5, color: "#004587" }}>
          គោលការណ៏នៃការស្ដាប់សកម្ម
        </h4>
        <p>១. យកចិត្ត ទុកដាក់ស្តាប់</p>
        <ul className="list-style">
          <li>សម្លឹងមើលទៅកាន់អ្នកនិយាយ</li>
          <li>
            ចៀសវាងមិនឱ្យមានការរំខានពីកត្តាផ្សេងៗដូចជាការនិយាយទូរស័ព្ទការដើរបញចូលរបស់សិស្ស
            ឬក្រុមការងារ
          </li>
          <li>សង្កេតមើល "ភាសាកាយវិការ”របស់អ្នកនិយាយ។</li>
          <li>ពិចារណាពីអារម្មណ៍របស់គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលា</li>
        </ul>

        <h4 style={{ paddingTop: 5, paddingBlock: 5, color: "#004587" }}>
          ២. ប្រើឥរិយាបថកំពុងស្តាប់
        </h4>
        <ul className="list-style">
          <li>ញញឹម ឬបង្ហាញទឹកមុខរីករាយ </li>
          <li>បើកចិត្តឱ្យទូលាយដើម្បីទទួលយកព័ត៌មាន</li>
          <li>អង្គុយឱ្យបានសមរម្យ</li>
          <li>ផ្តោតអារម្មណ៍ និងយល់ពីចិត្តរបស់អ្នកនិយាយ</li>
          <li>ប្រើក្រសែភ្នែក</li>
          <li>ងក់ក្បាលម្តងម្កាល</li>
          <li>លើកទឹកចិត្តអ្នកនិយាយតាមរយៈការនិយាយៈ ចាស ឬ​ បាទ អូ ពិតមែនឬ…</li>
        </ul>

        <h4 style={{ paddingTop: 5, paddingBlock: 5, color: "#004587" }}>
          ៣. សង្ខេប និងសួរសំណួរបញ្ជាក់
        </h4>
        <ul className="list-style">
          <li>
            និយាយសង្ខេប “អ្វីដែលខ្ញុំបានស្តាប់ឮគឺ ….ឬ
            “ស្តាប់ទៅហាក់ដូចជាអ្នកចង់និយាយថា…..”
          </li>
          <li>
            សួរសំណួរបញ្ជាក់ដូចជាៈ” តើអ្នកចង់មានន័យដូចម្តេចនៅពេលដែលអ្នកនិយាយថា…?”
          </li>
        </ul>

        <h4 style={{ paddingTop: 5, paddingBlock: 5, color: "#004587" }}>
          ៤. ចៀសវាងបុរេវិនិច្ឆ័យ
        </h4>
        <ul className="list-style">
          <li>កុំនិយាយកាត់សម្តី</li>
          <li>
            ស្ដាប់គេនិយាយជាជាងធ្វើការវិនិច្ឆ័យ(ចូរប្រុងប្រយ័ត្នចំពោះការវិនិច្ឆ័យរបស់អ្នក!)
          </li>
          <li>ទុកពេលឱ្យគេនិយាយឱ្យចប់</li>
        </ul>

        <h4 style={{ paddingTop: 5, paddingBlock: 5, color: "#004587" }}>
          ៥. ឆ្លើយតបឱ្យបានសមរម្យ
        </h4>
        <ul className="list-style">
          <li>
            មិនលាក់លៀម បើកចិត្តឱ្យទូលាយ និងស្មោះត្រង់ចំពោះការឆ្លើយតបរបស់អ្នក
          </li>
          <li>បញ្ចូលគំនិតរបស់អ្នកដោយការគោរព</li>
          <li>ស្វែងយល់ពីស្ថានភាពរបស់អ្នកនិយាយ</li>
        </ul>

        <span className="right">
          <i>(ឯកសារបំណិនផ្តល់ព័ត៌មានត្រឡប់ ឆ្នាំ២០១៦)</i>
        </span>
      </div>
    </>
  );
};

export default Article1Content2;
