import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, Card, message, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
// import { request } from '../util/api';
import { request } from '../share/request';

import { storeProfileData, storeUserData } from '../share/helper';

const { Option } = Select;

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState("");
  const [user, setUser] = useState("");

  const onFinish = (values) => {

    setLoading(true);
    var param = {
      email: values.email,
      password: values.password,
    };

    request("post", "auth/login", param).then((res) => {

      setLoading(false);
    
      if (!res.error) {
          storeUserData(res);
          window.location.href="/master"
      } else {
        message.error(res.message);
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="m-auto py-20 flex justify-center">
      <Card
        title={<span className="font-Battambang text-xl">ចូលទៅគណនី</span>}
        className="w-[400px] h-[500px] shadow-sm border-2 border-sbb-dark-blue p-4"
      >
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          size="large"
        >
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message:
                  "អ្វីអ្នកបញ្ចូលមិនត្រូវតាមស្តង់ដា អ៊ីម៉ែលទេ សូមផ្ទៀងផ្ទាត់សញ្ញា (@)!",
              },
              {
                required: true,
                message: "សូមបញ្ចូលអ៊ីម៉ែលរបស់អ្នក!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="អ៊ីម៉ែល"
              type="email"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "សូមបញ្ចូលពាក្យសម្ងាត់!",
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="ពាក្យសម្ងាត់"
            />
          </Form.Item>

          <Form.Item
            name="role"
            label={<span className="font-Battambang">តួនាទី</span>}
            rules={[
              {
                required: true,
                message: "សូមជ្រើសរើសភេទរបស់អ្នក!",
              },
            ]}
          >
            <Select placeholder="ជ្រើសរើសតួនាទីរបស់អ្នក">
              <Option value={0}>ប្រឹក្សាគរុកោសល្យជំនាញ</Option>
              <Option value={1}>ប្រឹក្សាគរុកោសល្យ</Option>
              <Option value={3}>នាយកឬនាយិកា</Option>
              <Option value={4}>គ្រូភាសាខ្មែរ</Option>
              <Option value={5}>គ្រូគណិតវិទ្យា</Option>
              <Option value={6}>គ្រូបង្រៀន</Option>
              <Option value={8}>និស្សិតអប់រំ</Option>
              <Option value={9}>សិស្សឬនិស្សិត</Option>
              <Option value={10}>សហគមន៍</Option>
              <Option value={11}>អាណាព្យាបាល</Option>
            </Select>
          </Form.Item>

          <Form.Item className="pt-10">
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button bg-sbb-dark-blue font-Battambang text-xl"
              loading={loading}
            >
              <span className="font-Battambang">ចូលគណនី</span>
            </Button>
            <span className="px-2">ឬ</span>{" "}
            <a href="register" className="text-xl">
              ចុះឈ្មោះថ្មី!
            </a>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Login;
