import "./Content.css";

const Article1Content6 = () => {
  return (
    <>
      <div className="content-body">
        <h3>ប្រធានអ្នករៀន នាំគេរៀន</h3>
        <ul className="list-style" style={{ paddingTop: 20 }}>
          <li>
            ឆ្លងកាត់ពេលវេលាទាំងនេះ
            ខ្ញុំរៀនសូត្របានយ៉ាងច្រើនពីមនុស្សទាំងឡាយដែលខ្ញុំបានប្រាស្រ័យ។
            ប៉ុន្តែក៏មានសំណួរ កាន់តែ ច្រើនឡើងដែរចូលមកកាន់ខួរក្បាលខ្ញុំ។
            ខ្ញុំចាប់ផ្តើមវែកញែកអំពីព័ត៌មានដែលបានឮ។
            ចាប់ផ្តើមពិចារណានូវអ្វីៗដែលខ្ញុំ បានជួប ប្រចាំថ្ងៃ។
            នេះឬដែលគេហៅថា«រៀនពេញមួយជីវិតនោះ?»
          </li>
          <li>
            រៀនមានន័ថាម៉េច? សម្រាប់ខ្ញុំ រៀនគឺជាដំណើរការហ្វឹកហាត់ចិត្តគំនិត។
            ខ្ញុំរៀនតាមរយៈការស្វែងរកព័ត៌មាន សម្រាំងរក តែព័ត៌មានដែលមានសារៈសំខាន់
            ហើយវិភាគ សំយោគវាដើម្បីបង្កើតចំណេះដឹងថ្មីៗ។ ខ្ញុំប្រើចំណេះដឹងទាំងនេះ
            ដើម្បីគាំទ្រ ការងារប្រចាំថ្ងៃរបស់ខ្ញុំ។ ខ្ញុំសារភាពថា
            វាមិនបានជួយឱ្យការចងចាំរបស់ខ្ញុំកើនឡើងច្រើនជាងមុននោះទេ ប៉ុន្តែវាពិតជា
            បានជម្រុញឱ្យខ្ញុំចង់រៀនថែមកាន់តែសកម្ម
            ហើយបានយកចំណេះដឹងថ្មីមានប្រយោជន៍សម្រាប់បរិបទបច្ចុប្បន្ន មកជំនួសការ
            ចងចាំចាស់ៗដែលខ្ញុំលែងត្រូវការ។
          </li>
          <li>
            រកទីប្រឹក្សារបស់អ្នកដើម្បីឆ្លុះបញ្ចាំងខ្លួនអ្នក។
            ខ្ញុំរៀនជាមួយសហការីខ្ញុំជារៀងរាល់ថ្មី។
            ខ្ញុំដឹងថាអ្នកណាពូកែខាងអ្វីមួយ ហើយខ្ញុំតែ
            ងស្វែងរកឱកាសទទួលការប្រឹក្សាពីគាត់ជារឿយៗ។
            ក្នុងនោះមានមនុស្សម្នាក់ដែលខ្ញុំបានចាត់ទុកជាទីប្រឹក្សាប្រចាំរបស់ខ្ញុំ។
            យើងតែងជួបជជែកគ្នារយៈពេល១ម៉ោង រៀងរាល់២សប្តាហ៍ម្តង។
            ការសន្ទនាតែងប្រព្រឹត្តិទៅយ៉ាងរលូន និងបានផ្តល់ចំណេះដឹង
            ដល់ខ្ញុំយ៉ាងច្រើន។
          </li>
        </ul>
        <p style={{ fontWeight: "bold" }}>
          មានអាថ៌កំបាំង២យ៉ាងដែលខ្ញុំចង់ចែករំលែកដូចខាងក្រោម៖
        </p>
        <ul className="list-style">
          <li>
            <b>រក្សាទំនាក់ទំនងក្នុងភាពជាមិត្ត។</b>
            ខ្ញុំទទួលស្គាល់ថាអ្នកអាចមានទីប្រឹក្សាដែលមានវ័យខុសគ្នា ឋានៈខុសគ្នា
            ដែលចាំបាច់ ត្រូវសម្តែងសេចក្តីគោរពតាមរយៈកាយវិការ និងពាក្យសម្តី។
            យ៉ាងនេះក៏ដោយ ខ្ញុំតែងបើកសេរីភាព ដ៏ទូលាយក្នុង ផ្នែក គំនិត
            ហើយក៏ជជែករឿងរ៉ាវផ្សេងៗក្នុងលំហគំនិតដ៏ទូលាយ
            ហើយគោរពគំនិតយោបល់របស់គាត់ដោយស្មារតីស្ថាបនា។
            ការប្រាប់រឿងរ៉ាវផ្ទាល់ខ្លួន
            ជាមធ្យោបាយកសាងទំនាក់ទំនងដ៏មានប្រសិទ្ធភាព។ អ្នកអាចនិយាយអំពី បទពិសោធន៍
            ជោគជ័យខ្លះ​ បរាជ័យខ្លះ
            ពីព្រោះយើងសុទ្ធតែជឿថាគ្មាននរណាម្នាក់ដែលល្អឥតខ្ចោះនោះទេ។
            យកទីប្រឹក្សាជាកញ្ចក់ឆ្លុះបញ្ចាំងអំពីខ្លួនអ្នក។ នៅពេលអ្នកសិតសក់
            អ្នកប្រាកដជាប្រើកញ្ចក់ដើម្បីប្រាប់ អ្នកថាតើអ្នក ស្អាតសមរម្យហើយឬនៅ។
            បើអ្នកឃើញថានៅទាស់ដោយប្រការណាមួយ
            អ្នកតែងកែតម្រូវបន្តិចបន្តួចរហូតអ្នកពេញចិត្ត
            ដោយមិនបានខឹងក្រោធចំពោះកញ្ចក់ឡើយ។ថ្ងៃបន្ទាប់អ្នកក៏នៅតែឆ្លុះកញ្ចក់ទៀតដដែលប្រកបដោយក្តីទុកចិត្តខ្ពស់បំផុតចំពោះគ្នា។
            សូមរក្សាទំនាក់ទំនងល្អនឹងគ្នានូវគ្រប់កាលៈទេសៈ។
          </li>
          <li>
            <b>តួនាទី និងទំនួលខុសត្រូវ៖ ស្មោះត្រង់ចំពោះខ្លួនឯង និងតួនាទី</b>
            ក្នុងអាជីពជាមួយអង្គការវិញ ខ្ញុំបានធ្វើការដោយផ្ទាល់ជាមួយអ្នកជំនាញ
            ជាច្រើនជាតិសាសន៍ មានខ្មែរយើង អូស្រ្តាលី អង់គ្លេស អៀរឡង់ បារាំង
            អាមេរិក ឡាវ ថៃ វៀតណាមក៏មាន។
            តាមរយៈបទពិសោធទាំងនេះ​ខ្ញុំមិនបានទន់ទោរទៅលើជាតិសាសន៍ណាមួយ
            ថាពូកែជាងជាតិសាសន៍ផ្សេងទៀតនោះទេ
            ប៉ុន្តែទីបំផុតទៅធ្វើឱ្យខ្ញុំចាប់ផ្តើមមើលរឿងរ៉ាវក្នុងភាពជាមនុស្ស
            ជាជាងវិនិច្ឆ័យកត្តាជាតិសាសន៍។
            ខ្ញុំយល់ពីសារៈសំខាន់ក្នុងការផ្តល់សេរីភាពផ្នែកគំនិតឱ្យខ្លួនឯង
            ហើយឆ្លុះបញ្ចាំងខ្លួនឯងក្នុងនាមជាមនុស្សម្នាក់។ ពេលខ្លះ យើងរវល់តែខ្វល់
            ពីអ្នកដទៃមើលមកយើងថាបែបនេះ ឬបែបនោះ
            ហើយបែរជាបំភ្លេចសារៈសំខាន់ក្នុងនាមជាខ្លួនឯង។
          </li>
        </ul>
        <p>
          ក្រោយពីបានឆ្លុះបញ្ចាំងពិសោធន៍ច្រើនឆ្នាំមកនេះ
          ខ្ញុំសារភាពថាខ្ញុំធ្លាប់វង្វេងតួនាទី និងទំនួលខុសត្រូវរបស់ខ្ញុំ។
          ខ្ញុំសូមនិយាយដោយសង្ខេបនូវអ្វីដែលខ្ញុំបានឆ្លងកាត់ដូចខាងក្រោម៖
        </p>
        <ul className="list-style" style={{ listStyle: "circle" }}>
          <li>
            កាលធ្វើជាគ្រូបង្រៀនក្នុងថ្នាក់ ខ្ញុំបានដឹងថាក្រសួងអប់រំ
            ដាក់ឱ្យប្រើប្រាស់គោលវិធីសិស្សមជ្ឈមណ្ឌល ប៉ុន្តែជាក់ស្តែង ខ្ញុំបែរ
            ជាប្រឹងខ្វល់តែលើអក្សរនៅលើសៀវភៅសិក្សាគោល។{" "}
            <b>
              ខ្ញុំគួរតែផ្តោតលើសិស្ស តែជាក់ស្តែងខ្ញុំបែរជាផ្តោតតែលើក្រដាសទៅវិញ។
            </b>
          </li>
          <li>
            កាលធ្វើជានាយកសាលា ខ្ញុំប្រឹងសង្វាតធ្វើសួនច្បារ
            និងហេដ្ឋារចនាសម្ព័ន្ធផ្សេងទៀត ដោយសង្ឃឹមថា វានឹងអាចកាត់បន្ថយអត្រា
            បោះបង់។ ជាក់ស្តែង ការរំពឹងទុកមួយនោះបានបរាជ័យ។ ខ្ញុំភ្លេចគិតថា
            <b>
              តាមពិតទំនួលខុសត្រូវចម្បងបំផុតរបស់ខ្ញុំ គឺដឹកនាំគ្រូ
              ដើម្បីបង្រៀនសិស្សឱ្យចេះរៀនទេតើ!
              ខ្ញុំគួរតែបានផ្តោតយកចិត្តទុកដាក់លើមនុស្ស មិនមែនវត្ថុទាំងនោះទេ។
            </b>
          </li>
          <li>
            ជារឿយៗខ្ញុំឮគ្រូនិយាយគ្នាថា «សាលាគេស្រួលណាស់
            គ្រូចង់ធ្វើអ្វីក៏បានដែរ»។ ឮដូចនេះ
            ខ្ញុំក៏ដាក់ខ្លួននៅឆ្ងាយពីគ្រូបង្រៀន។
            រឿងដែលខ្ញុំខ្វាយខ្វល់គឺមានតែរបាយការណ៍ ហើយបំពេញវាឱ្យត្រូវទម្រង់
            គ្មានកោសលុប និងក្នុងភាពប្រញាប់ ស្ទើរគ្រប់ ពេលវេលា។
            រៀងរាល់ចុងឆមាសនីមួយៗ
            ខ្ញុំក៏តាំងឱ្យគ្រូសរសេរភាគរយកម្មវិធីសិក្សាដែលសម្រេចបាន។
            តើនេះមានន័យដូច ម្តេច? សម្រាប់ខ្ញុំកាលនោះ ភាគរយសម្រេចកម្មវិធីសិក្សា =
            លេខរៀងមេរៀនក្នុងសៀវភៅសិក្សា គោលដែលបានបង្រៀន ចុងក្រោយ
            ធៀបនឹងចំនួនមេរៀនសរុបក្នុងសៀវភៅ (ជាទូទៅលេខរៀងមេរៀនចុងក្រោយ)
            ហើយយកវាទៅគុណនឹង១០០។
          </li>
        </ul>
        <p>
          ខ្ញុំស្តាយក្រោយខ្លាំងណាស់ចំពោះឥរិយាបថខាងលើ!
          បើអាចធ្វើកិច្ចការនេះម្តងទៀត ខ្ញុំនឹង៖
        </p>
        <ul className="list-style" style={{ listStyle: "square" }}>
          <li>
            ស្វែងយល់អំពីលទ្ធផលរំពឹងទុកនៃកម្មវិធីសិក្សាលម្អិតនៃមុខវិជ្ជានីមួយៗ
            ស្វែងយល់អំពីអត្ថន័យបំណិន សតវត្ស ទី២១ នឹងវិធីសាស្រ្តបង្រៀនបែបសកម្ម
          </li>
          <li>
            រួចហើយសម្របសម្រួលកិច្ចប្រជុំជាមួយសហការីក្នុងសាលាដើម្បីនាំពួកគាត់ស្វែងយល់វាដែរ
            ព្រមទាំងកសាងផែន ការសម្រេចគោលដៅទាំងផ្នែកគុណភាព និងបរមាណឱ្យខាងតែបាន។
            ខ្ញុំនឹងកំណត់យកកុមារដាក់ជាស្នូលនៃការពិចារណាទាំងអស់។
          </li>
          <li>
            ដាក់អាទិភាពពេលវេលាឱ្យខ្លួនឯង ដើម្បីទៅសង្កេតថ្នាក់រៀន
            ផ្តល់ប្រឹក្សាដល់គ្រូបង្រៀនយ៉ាងហោចណាស់ឱ្យបានម្តងក្នុងមួយខែសម្រាប់គ្រូបង្រៀនម្នាក់ៗ។
          </li>
          <li>
            រៀងរាល់ពេលចុះសង្កេត ខ្ញុំនឹងសិក្សាអំពីគម្លាតនៃការអនុវត្តជាក់ស្តែង
            ធៀបជាមួយវិធីសាស្ត្រដែលយើងបានកំណត់ក្នុងផែនការ។
          </li>
          <li>
            ខ្ញុំក៏នឹងសង្កេតអំពីកម្រិតស្ទាត់ជំនាញរបស់គ្រូ
            ព្រមទាំងផ្តល់ព័ត៌មានត្រឡប់ប្រកបដោយស្មារតីស្ថាបនា
          </li>
          <li>
            ខ្ញុំនឹងដឹកនាំរៀបចំខ្លឹមសារតេស្តសិស្សដែលអាចឆ្លុះបញ្ចាំងលទ្ធផលរំពឹងទុករបស់សាលា
            គ្រប់គ្រងតម្លាភាពក្នុងការប្រឡង
            និងដឹកនាំប្រើប្រាស់លទ្ធផលដើម្បីឆ្លុះបញ្ចាំងប្រសិទ្ធភាពការងាររួមរបស់សាលាប្រកបដោយគណនេយ្យភាពបំផុត។
            គ្មានហេតុផលណាមួយដែលខ្ញុំត្រូវកុហកបោកប្រាស់ខ្លួនឯងនោះឡើយ។
          </li>
          <li>
            ខ្ញុំនឹងនាំថ្នាក់លើ ព្រមទាំងសហគមន៍
            ប្រារព្ធភាពជោគជ័យរបស់សាលារៀងរាល់ឆ្នាំ។
          </li>
        </ul>
        <p>
          ខ្ញុំជឿថា ការអភិវឌ្ឍគ្មានភ្លូវកាត់នេះ។
          មុននឹងមនុស្សអស្ចារ្យទាំងឡាយឈានទៅបង្កើតអ្វីពិសេសមួយបាន
          ពួកគាត់បានឆ្លងកាត់ការេពិសោធ សាកល្បងច្រើនរាប់មិនអស់។
          រឿងពិតមួយដែលមិនអាចប្រកែកបានគឺ រាល់លើកពួកគាត់ បានរៀនអ្វីមួយពីវា
          ហើយចេះតែកែតម្រូវរហូតត្រូវបានទទួលស្គាល់ថាមានតម្លៃសកល។ ចុះអ្នកវិញ?
        </p>
        <p>
          តាមរយៈករណីសិក្សាខាងលើតើលោកគ្រូ/អ្នកគ្រូបានរៀនសូត្រអ្វីខ្លះ?
          តើអ្វីខ្លះជាបញ្ហាប្រឈមសម្រាប់លោកគ្រូ/អ្នកគ្រូក្រុងការពង្រឹងគុណផាពការបង្រៀន​និងរៀននៅតាមសាលា?
        </p>
      </div>
    </>
  );
};

export default Article1Content6;
