import "./Content.css";
const people = [
  { 
    name: 'គ្រូបង្រៀនកម្រិតថ្នាក់ដំបូង', 
    title:<>
            <ul style={{fontSize: '12px', listStyle: 'inside', color: '#333'}}>
              <li>កសាងចំណេះដឹងលើបញ្ញត្តិ</li>
              <li>រៀបចំកិច្ចតែងការបង្រៀន</li>
              <li>ដំណឹកនាំមេរៀន/ការគ្រប់គ្រងថ្នាក់រៀន</li>
              <li>ការគ្រប់គ្រងសម្ភារៈ</li>
              <li>តាមដានការ និងវាយតម្លៃសិក្សារបស់សិស្ស</li>
              <li>ធ្វើការសហការនិង​ការចូលរួម ប្រកបដោយវិជ្ជាជីវៈ</li>
            </ul>
          </>,
    other: 'ទម្រង់វាស់វែងកម្រិត ជំនាញ'
}, 
{
  name: 'គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលា',
  title:<>
          <ul style={{fontSize: '12px', listStyle: 'inside', color: '#333'}}>
            <li>ជំនាញគ្រប់គ្រង</li>
            <li>ជំនាញទំនាក់ទំនងនិងសហការ</li>
            <li>ជំនាញសង្កេត</li>
            <li>ជំនាញផ្តល់​​​ព័ត៌​មានត្រឡប់</li>
            <li>យល់ច្បាស់នូវខ្លឹមសារមេរៀនគណិតវិទ្យា</li>
            <li>កសាងចំណេះដឹងលើខ្លឹមសារ/បញ្ញត្តិមុខវិជ្ជាគណិតវិទ្យា ដល់គ្រូបង្រៀន</li>
          </ul>
        </>,
  other: ''
},
{
  name: 'ទីប្រឹក្សាគរុកោសល្យជំនាញ',
  title:<>
            <ul style={{fontSize: '12px', listStyle: 'inside', color: '#333'}}>
              <li>កសាងទំនាក់ទំនងនិងសហការ</li>
              <li>ជំនាញគ្រប់គ្រង</li>
              <li>ផ្តល់ការគាំទ្រទៅលើផ្នែកចំណេះដឹង និងជំនាញអប់រំ</li>
              <li>ជំនាញសង្គេត</li>
              <li>ជំនាញផ្ដល់ព័ត៌មានត្រឡប់</li>
              <li>ជំនាញប្រើភស្តុតាង</li>
              <li>ជំនាញសម្របសម្រួលវគ្គ សិក្ខាសាលានិងកិច្ចប្រជុំ</li>
              <li>ជំនាញភាពជាអ្នកដឹកនាំផ្នែកកិច្ចការសិក្សា</li>
            </ul>
        </>,
  other: ''
},
{
  name: 'មន្ត្រីការិយាល័យស្រុក',
  title:<>
          <ul style={{fontSize: '12px', listStyle: 'inside', color: '#333'}}>
            <li>​កសាងទំនាក់ទំនងនិងសហការ</li>
            <li>ជំនាញគ្រប់គ្រង</li>
            <li>ផ្តល់ការគាំទ្រទៅលើផ្នែកចំណេះដឹង និងជំនាញអប់រំ</li>
            <li>ជំនាញសង្គេត</li>
            <li>ជំនាញផ្ដល់ព័ត៌មានត្រឡប់</li>
            <li>ជំនាញប្រើភស្តុតាង </li>
            <li>ជំនាញសម្របសម្រួលវគ្គ សិក្ខាសាលានិងកិច្ចប្រជុំ</li>
            <li>ការអនុវត្ត</li>
          </ul>
        </>,
  other: ''
}

  // More people...
]

const Article1Content1 = () => {
  return (
    <>
      <div className="content-body">
        <h3>៧. ការពិនិត្យតាមដាននិងធ្វើការវាយតម្លៃ</h3>

        <p>
        ការពិនិត្យតាមលើភាពរីកចម្រើនរបស់គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាគឺជាផ្នែកនៃតួនាទីរបស់ទីប្រឹក្សាគរុកោសល្យជំនាញ។ 
        យល់ដឹងភាពរីកចម្រើនរបស់គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាព្រមទាំងធ្វើការឆ្លង និងលើកសរសើរចំណុចទាំងអស់នោះ ព្រមទាំងកំណត់ 
        រកចំណុចដែលត្រូវពង្រឹង អនុវត្តបន្ថែមនឹងជួយក្នុងពេលធ្វើប្រឹក្សាគរុកោសល្យ។ ក្រោយពេលធ្វើប្រឹក្សាគរុកោសល្យបានមួយពេល ផ្តោតលើ 
        អ្វីដែលជាលទ្ធផលសម្រេចបានក្នុងរយៈពេលខ្លី វានឹងអាចពិបាកនឹងមើលឱ្យឃើញមានការរីកចម្រើនជាទូទៅ។ ដូច្នេះការបំពេញការវាយ
        តម្លៃកម្រិតជំនាញអាចជួយឱ្យឃើញមានរីកចម្រើនជាទូទៅដែលគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាបានបង្កើតឱ្យមាន ព្រមទាំងធ្វើឱ្យទីប្រឹក្សា 
        គរុកោសល្យអាចឆ្លងនិងលើកសរសើរបាន។ វាក៏ជាចំណុចល្អក្នុងការមើល​ឱ្យឃើញភាពរីកចម្រើនរបស់គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលា 
        ទាំងអស់ទូទាំងកម្រង ឬស្រុក។ ការិយាល័យអប់រំ យុវជននិងកីឡាស្រុកអាចប្រើលទ្ធផលទាំងអស់នេះដើម្បីពិនិត្យមើលភាពរីកចម្រើន 
        គ្រប់គ្រូប្រឹក្សាគរុកោសល្យទាំងអស់ និងជួយឱ្យឃើញភាពខុសគ្នានៃការងារកម្មវិធីប្រឹក្សាគរុកោសល្យកំពុងកើតឡើង។
        </p>

        <h5 style={{fontWeight: 'bold', color: '#004587'}}>
          វាយតម្លៃលើកម្រិតជំនាញ
        </h5>

        <p> កម្រិតជំនាញបង្ហាញផ្លូវធ្វើដំណើរដល់គ្រូបង្រៀន គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលា ព្រមទាំងដល់ទីប្រឹក្សាគរុកោសល្យជំនាញ ដើម្បីឱ្យធ្វើការអភិវឌ្ឍ។ 
          យើងរំពឹងទុកថានៅពេលគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាចាប់ផ្តើមដំបូងនៅក្នុងគម្រោង ស្របពេលពួកគាត់ចាប់ ផ្តើមរៀន ដែលពួកគាត់នឹងមានភាពពិតប្រាកដ។ 
          ប៉ុន្តែយើងរំពឹងទុកថាចំណុចទាំងអស់នេះនឹងបន្តកំណើនឡើង។ កម្រិតជំនាញដែលរំពឹង ទុកនឹងមានភាពខុសគ្នាទៅតាមពីមនុស្សម្នាក់ទៅមនុស្សម្នាក់ទៀត។ 
          វាអាស្រ័យទៅលើរយៈពេលនៃបទពិសោធន៍បង្រៀន ឬការងារប្រឹក្សាគរុ កោសល្យ និងពេលខ្លះវាអាចអាស្រ័យទៅលើកក្តាខាងក្រៅដូចជាការផ្លាស់ទៅសាលា ឬកម្រងថ្មី។</p>
        
        <p>
          កម្រិតជម្រាញនីមួយៗសម្រាប់គ្រូបង្រៀន គ្រូប្រឹក្សាគរុកោសល្យ និងទីប្រឹក្សាគរុកោសល្យជំនាញបានកំណត់នូវកម្រិតផ្សេងៗក្នុង ជំនាញដែលមនុស្សម្នាក់នឹងត្រូវឆ្លងកាត់។ 
          នៅក្នុងនោះវាបានប្រាប់នូវជំហានក្នុងការឱ្យរីកចម្រើនដែលគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលា ត្រូវសំឡឹងមើលនៅពេលពួកគាត់ចុះធ្វើការងារប្រឹក្សាគរុកោសល្យ។ ឧបករណ៍វាស់វែងកម្រិតជំនាញគឺជាឧបករណ៍ដ៏មានប្រយោជន៍សម្រាប់ 
          ធ្វើការឆ្លុះបញ្ចាំងថាពួកគាត់ជាគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាស្ថិតនៅត្រង់កម្រិតណា ព្រមទាំងឃើញនូវចំណុចខ្លាំងនិងចំណុចលំបាក ដែលជួយក្នុងការរៀបចំគោលដៅ។
        </p>

        <p>មានជំនាញចំនួន៦ ជំនាញគ្រប់គ្រង ជំនាញទំនាក់ទំនងនិងសហការ ជំនាញសង្កេត ជំនាញផ្តល់ព័ត៌មានត្រឡប់ គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាកសាងចំណេះដឹងលើខ្លឹមសារមុខវិជ្ជាគណិតវិទ្យា​និងគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាកសាងចំណេះដឹង 
          លើខ្លឹមសារ /បញ្ញត្តិ មុខវិជ្ជាគណិតវិទ្យាដល់គ្រូបង្រៀន។ ក្នុងជំនាញនីមួយៗមានការពិពណ៌នាចំនួន៤ចំណុចនៃប្រភេទកម្រិតជំនាញ ព្រមទាំងសកម្មភាពដែលគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាត្រូវធ្វើទៅតាមកម្រិតផ្សេងៗគ្នា។</p>
        
        <h5 style={{fontWeight: 'bold', color: '#004587'}}>
          កម្រិតជំនាញ
        </h5>

        <p style={{fontWeight: 'bold', color: '#004587'}}>ផ្តើមដកពិសោធន៍</p>
        <p>គ្រូប្រឹក្សាគរុកោសល្យនៅកម្រិតផ្តើមដក ពិសោធន៍មានចំណេះដឹងអំពីរបៀបកែលម្អ ការងារប្រឹក្សាគរុកោសល្យ ហើយចាប់ផ្តើម យកវាទៅអនុវត្ត។ គ្រូប្រឹក្សាគរុកោសល្យ 
          ព្យាយាមធ្វើការប្រឹក្សាគរុកោសល្យដោយប្រើចំណេះដឹងទាំងនោះ ប៉ុន្តែមិនទាន់មានទំនុក 
          ចិត្តពេញលេញនៅឡើយ។ គ្រូប្រឹក្សាគរុកោ សល្យព្យាយាមធ្វើតាមអ្វី ដែលបានរៀនពី វគ្គបំប៉ន ប៉ុន្តែភាពជាម្ចាស់ និងទំនួលខុស ត្រូវនៅមានកម្រិត។</p>
        
          <p style={{fontWeight: 'bold', color: '#004587'}}>មានពិសោធន៍គ្រប់គ្រាន់ </p>
          <p>គ្រូប្រឹក្សាគរុកោសល្យ មានពិសោធន៍​ គ្រប់គ្រាន់ ធ្វើបានតាមការរំពឹងទុកនៃកម្មវិធីប្រឹក្សាគរុកោ សល្យប្រកបដោយទំនុកចិត្ត ព្រមទាំងដឹងនូវអ្វី ដែលខ្លួនត្រូវធ្វើដើម្បីទាញការចូលរួមពី គ្រូបង្រៀន។ 
            គ្រូប្រឹក្សាគរុកោសល្យកំណត់បាននូវ តម្រូវការរបស់គ្រូបង្រៀន ព្រមទាំងយុទ្ធសាស្ត្រ កែលម្អ។ 
            គ្រូប្រឹក្សាគរុកោសល្យ ដឹងអំពីគោល បំណងនៃកម្មវិធីប្រឹក្សាគរុកោសល្យហើយយល់ថាខ្លួនមាន តួនាទីសំខាន់ក្នុងការជួយធ្វើជាទីប្រឹក្សា គរុកោសល្យ ដល់គ្រូបង្រៀន និងសិស្សឱ្យ សម្រេចជោគជ័យ។</p>
          
          <p style={{fontWeight: 'bold', color: '#004587'}}>មានពិសោធន៍កម្រិតខ្ពស់</p>
          <p> គ្រូប្រឹក្សាគរុកោសល្យមានពិសោធន៍ខ្ពស់ធ្វើការ សម្រេចចិត្តដោយខ្លួនឯងទាក់ទងការប្រឹក្សាគរុកោ សល្យដោយឈរលើអ្វីដែលគាត់ជឿថា ធ្វើឱ្យការបង្រៀនមានគុណភាព។ 
            គ្រូប្រឹក្សាគរុកោសល្យអាចផ្តល់ហេតុផលលើអ្វីដែលគាត់បានសម្រេចចិត្ត។ ឧ.«ខ្ញុំជ្រើសរើសវិធីនេះ ពីព្រោះ……»។ គ្រូប្រឹក្សាគរុកោសល្យចាប់ផ្តើមពង្រីកលើសពីអ្វីដែល គាត់បានរៀនពីវគ្គបំប៉ន 
            ហើយបន្ថែមគំនិត ដើម្បីធ្វើឱ្យកម្មវិធីប្រឹក្សាគរុកោសល្យ កាន់តែមាន ប្រសិទ្ធភាព។</p>
          
          <p style={{fontWeight: 'bold', color: '#004587'}}>ជាអ្នកជំនាញ </p>
          <p>គ្រូប្រឹក្សាគរុកោសល្យកម្រិតអ្នកជំនាញ គឺគិត អ្វីមួយដោយស្វ័យប្រវត្ថិ និងមាន ទស្សនៈវិស័យ ច្បាស់លាស់អំពីអ្វីដែលខំប្រឹងប្រែងឱ្យឈានដល់ សម្រេចបាន។ 
            គ្រូប្រឹក្សាគរុកោសល្យអាចពិចារណារិះរកវិធីសាស្រ្តជា ច្រើនសម្រាប់ផ្តល់ឱ្យគ្រូបង្រៀន និងពិនិត្យតាមដាន កម្មវិធីប្រឹក្សាគរុកោសល្យ ទាំងមូល ហើយតែងធ្វើ ឱ្យសម្រេចការរីកចម្រើនទៅមុខ។ 
            គ្រូប្រឹក្សាគរុកោសល្យ ចង់ចែករំលែកចំណេះដឹងជា មួយទីគ្រូប្រឹក្សាគរុកោសល្យផ្សេងទៀត និងមានសមត្ថភាពបំប៉នអ្នកដទៃ។ គ្រូប្រឹក្សាគរុកោសល្យចែករំលែកបទពិសោធន៍  
            ក្នុងការប្រជុំប្រឹក្សាគរុកោសល្យ ព្រមទាំងអាច បញ្ចេញគំនិតអំពី ការប្រឹក្សាគរុកោសល្យ និងការបង្រៀនបានយ៉ាងច្បាស់លាស់។</p>
          
          <p>យើងអាចប្រើការពិពណ៌នាទាំងនេះដើម្បីជួយយើងឱ្យមើលឃើញថាយើងនៅកម្រិតណាពេលនេះ។ យើងអាចមើលឃើញថាចំណុចណា ដែលយើងខ្លាំង និងកន្លែងណាត្រូវការខំប្រឹងពង្រឹងបន្ថែម។ 
            ដោយសារវាត្រូវបន្តជាប្រចាំ យើងអាចប្រើចំណុចទាំងអស់នេះដើម្បីមើលប្រៀបធៀបថា វាមានលក្ខណៈដូចម្តេចនៅពេលមានភាពរីកចម្រើន។ វាគឺជាការជម្រុញទឹកចិត្តឱ្យប្រឹងទៅមុខ។</p>
          
          <p>ឧ. ខ្ញុំស្ថិតនៅកម្រិតផ្តើមដកពិសោធន៍  ខ្ញុំមើលឃើញកម្រិតមានពិសោធន៍គ្រប់គ្រាន់  និងឃើញថាខ្ញុំត្រូវការបំពេញគ្រប់ផ្នែកទាំងអស់ នៃផែនការរួចហើយប្រើវាដើម្បីធ្វើការរៀបចំ។ 
            ចំណុចនេះអាចជាគោលដៅបន្ទាប់ទៀតសម្រាប់ខ្ញុំ។ នៅពេលេដែលខ្ញុំឈានដល់កម្រិតនោះខ្ញុំអាច មើលកម្រិតបន្ទាប់ទៀតដែលខ្ញុំត្រូវការអនុវត្តនាពេលឥឡូវនេះ។ 
            យើងដឹងហើយថាគ្រូបង្រៀនមិនអាចក្លាយទៅជា ជាអ្នកជំនាញ ក្នុងរយៈពេលមួយ ថ្ងៃបានទេ ដែលមិនគ្មាននរណាធ្វើបានទេ។ 
            កម្រិតជំនាញបំបែកទៅជាចំណុចផ្សេងៗក្នុងការបង្រៀនដើម្បីឱ្យមានភាពងាយស្រួលក្នុងការធ្វើឱ្យសម្រេចបាន ក្នុងផ្នែកទាំងអស់។</p>
          
          <p>កម្រិតជំនាញត្រូវការបំពេញចំនួនពីរដងក្នុងមួយឆ្នាំព្រមទាំងធ្វើការកាត់ត្រាទុក ប៉ុន្តែទម្រង់វាស់កម្រិតជំនាញនេះអាចប្រើគ្រប់ពេល។ លោកអ្នក ប្រហែលជ្រើសរើសយកវាមកប្រើនៅរាល់ពេលចុះធ្វើការប្រឹក្សាគរុកោសល្យ 
            ឬក៏រាងរាល់ពេលពីរទៅបីពេលចុះផ្តល់ប្រឹក្សាគរុកោសល្យប្រើម្តង។ 
            ទម្រង់នេះ វាស់វែងកម្រិតជំនាញនេះគ្រូបង្រៀនអាចប្រើដើម្បីធ្វើការស្វ័យវាយតម្លៃ ឆ្លុះបញ្ចាំងនូវអ្វីដែលពួកគាត់បានធ្វើនិងធ្វើការពិភាក្សាផ្សេងៗ។ </p>
          
          <p>នៅពេលប្រមូលកម្រិតជំនាញជាផ្លូវការណ៍យើងត្រូវការបង្ហាញនូវអ្វីដែលដែលបានកើតឡើងនៅថ្ងៃនោះ។ វាគឺជាអ្វីដែលលោកអ្នកបានសង្កេត ឃើញនៅពេលនោះ។ បើសិនវាជាការមានតែក្នុងមេរៀនជាក់លាក់ណាមួយ ដែលវាខុសពីអ្វីដែលបានសង្កេតឃើញនៅពេលលោកអ្នកចុះប្រឹក្សាគរុ កោសល្យ លោកអ្នកត្រូវធ្វើការតាមចុះតាមដាននៅពេលចុះផ្តល់ប្រឹក្សាគរុកោសល្យនាពេលលើកក្រោយ។ 
            នៅពេលចុះធ្វើការវាស់វែងកម្រិតជំនាញលោកអ្នកមិនធ្វើការផ្តល់ប្រឹក្សាគរុកោសល្យទេ។ លោកអ្នកប្រហែលជាសួរសំណួរដើម្បីជួយលោកអ្នក ដើម្បីបំពេញទម្រង់កម្រិតជំនាញបាន ប៉ុន្តែលោកអ្នកត្រូវការសង្កេតនៅក្នុងអំឡុងពេលបង្រៀន។ 
            ដើម្បីធ្វើកំណត់ត្រាលទ្ធផលលោកអ្នកត្រូវធ្វើការកត់ត្រាតែកម្រិតជំនាញនីមួយ។ ឯកសារនេះនឹងត្រូវផ្ញើឱ្យ/ប្រមូលនៅពេលប្រជុំប្រឹក្សាគរុកោ សល្យលើកក្រោយ។ 
          </p>

        <ul>
          <ul style={{fontSize: '16px', listStyle: 'inside', color: '#333'}}>
            <li>ជំនាញគ្រប់គ្រង</li>
            <li>ជំនាញទំនាក់ទំនង និងសហការ</li>
            <li>ជំនាញសង្កេតនិងផ្តល់ព័ត៌មានត្រឡប់</li>
            <li>គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាកសាងចំណេះដឹងលើខ្លឹមសារមុខវិជ្ជាគណិតវិទ្យា</li>
            <li>គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាកសាងចំណេះដឹង លើខ្លឹមសារ /បញ្ញត្តិ មុខវិជ្ជា គណិតវិទ្យា ដល់ គ្រូបង្រៀន</li>
          </ul>

        <p>ដើម្បីធ្វើការវាស់វែងលើភាពរីកចម្រើនលើជំនាញរបស់គ្រូបង្រៀនម្នាក់ គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលា 
          និងទីប្រឹក្សាគរុកោសល្យជំនាញត្រូវតែមានផែនការដើម្បីធ្វើការឆ្លុះបញ្ចាំងទៅលើជំនាញទាំងអស់រៀងរាល់ ៦ខែអាស្រ័យ ទៅតាមតួនាទីនិងទំនួលខុសត្រូវ៖
        </p>
      </ul>

        <h5 style={{fontWeight: 'bold', color: '#004587'}}>គ្រោងសិក្ខាសាលាដែលមាន</h5>
        <div className="px-4 sm:px-6 lg:px-8">          
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                        ប្រឹក្សាគរុកោសល្យ
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        ជំនាញ
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        ផ្សេងៗ
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {people.map((person, index) => (
                      <tr key={index}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                          {person.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.title}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.other}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <p>ទីប្រឹក្សាគរុកោសល្យជំនាញនឹងជួបប្រជុំជាមួយគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលានៅពេលប្រជុំប្រឹក្សាគរុកោសល្យ ប្រចាំខែ។ 
          គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាមកពីសាលាក្នុងកម្រងតែមួយនឹងប្រជុំគ្នាប្រចាំខែដើម្បីធ្វើការចែករំលែកបទ ពិសោធន៍ និងលទ្ធផលពីការសិក្សាស្រាវជ្រាវគរុកោសល្យព្រមទាំងស្វែងរកដំបូន្មានលើវិធីសាស្ត្រគរុកោលស្យបង្រៀនជាក្រុម។ 
          ទីប្រឹក្សាគរុកោសល្យជំនាញត្រូវបង្កើតការប្រជុំឱ្យកើតឡើង ព្រមទាំងជំរុញឱ្យគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាឱ្យចូលរួមក្នុង ពេលប្រជុំនិងរៀនពីគ្នាទៅវិញទៅមកឱ្យបានច្រើន។ </p>
        
        <p>ក្រោយពេលពិភាក្សារួចទីប្រឹក្សាគរុកោសល្យនឹងផ្តល់កំណត់ត្រាសង្កេត ព្រមទាំងផែនការសកម្មភាព ដែលមានផ្ទុក ព័ត៌មានត្រឡប់និងចំណុចដែលត្រូវកែលម្អ។</p>
      </div>
    </>
  );
};

export default Article1Content1;
