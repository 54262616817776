import {Link} from 'react-router-dom'

const PageNotFound = () => {
    return ( 
        <>
        <div className="d-flex align-items-center justify-content-center" style={{marginTop:100, marginBottom:100, height:500}}>
            <div className="text-center">
                <h1 className="display-1 fw-bold">404</h1>
                <p className="fs-3"> <span className="text-danger">អព្យាស្រ័យ!</span> ទំព័រមិនមានទេ</p>
                <p className="lead"> ទំព័រដែលអ្នកកំពុងស្វែងរក មិនមាននៅក្នុងប្រព័ន្ធយើងខ្ញុំទេ</p>
                <Link to="/" className="btn btn-primary">ទៅកាន់ទំព័រដើម</Link>
            </div>
        </div>
        </>
     );
}
 
export default PageNotFound;