import "./Content.css";

const Article1Content1 = () => {
  return (
    <>
      <div className="content-body">
        <h3>៥. ការប្រជុំ</h3>

        <h5 style={{fontWeight: 'bold', color: '#004587'}}>
          ប្រជុំបច្ចេកទេសថ្ងៃព្រហស្បតិ៍
        </h5>
        <p>
        យោងតាមសេចក្ដីណែនាំគ្រឹះស្ថានបឋមសិក្សានិងមត្តេយ្យសិក្សាសាធារណៈរបស់ក្រសួងអប់រំយុវជននិងកីឡា នៅតាម សាលារៀនឬកម្រងសាលារៀនត្រូវរៀបចំឱ្យមានការប្រជុំបច្ចេកទេសប្រចាំខែរាល់សប្ដាហ៍ទី៤រៀងរាល់ខែ
        នៅក្នុងកម្រងនីមួយៗ។ការប្រជុំនេះមានសារៈសំខាន់ណាស់ក្នុងការជួយឱ្យគ្រូបង្រៀននៅក្នុងកម្រិតដូចគ្នាបានជួបគ្នាក្នុងរយៈពេលមួយខែម្ដងដើម្បី ពិភាក្សាពីភាពជោគជ័យនិងបញ្ហាលំបាកដែលពួកគាត់បានជួបពេលបង្រៀន
        និងទទួលបាននូវបទពិសោធន៍ថ្មីសម្រាប់យកទៅអនុ វត្តបន្ត។ការប្រជុំនេះត្រូវផ្ដោតសំខាន់លើវិធីសាស្រ្តបង្រៀនការរៀបចំកិច្ចតែងការការផលិតនិងប្រើប្រាស់សម្ភារៈការធ្វើថ្នាក់និទស្សន៍ការជួយសិស្សរៀនយឺត.........។ល។
        ទន្ទឹមនឹងនេះការប្រជុំបច្ចេកទេសក៏អាចជួយបំប៉នគ្រូបង្រៀនក្នុងក្រុមដែលជួបការលំបាកទាំងផ្នែកចំណេះដឹង ជំនាញវិជ្ជាជីវៈ ការងារគ្រប់គ្រង និង ការងារទំនាក់ទំនង ផងដែរ ។
        </p>
        <p>គោលបំណងក្នុងការប្រជុំបច្ចេកទេសថ្ងៃព្រហស្បតិ៍គឺដើម្បីពង្រឹងគុណភាពបង្រៀន​ឱ្យមានភាពប្រសើរដោយមានការ ជជែកគ្នាលើបញ្ហាវិធីសាស្រ្ដបង្រៀនស្រាវជ្រាវ។ដើម្បីឱ្យដំណើរការប្រជុំ
          បច្ចេកទេសថ្ងៃព្រហស្បតិ៍មានប្រសិទ្ធភាពត្រូវមានការ រៀបចំឱ្យបានច្បាស់លាស់ និងជាក់លាក់។ </p>
        
        <h5 style={{fontWeight: 'bold', color: '#004587'}}>មុនពេលប្រជុំ</h5>
        <ul className="list-style">
          <li>កំ​ណត់កាលបរិច្ឆេទច្បាស់លាស់</li>
          <li>កំណត់ទីកន្លែងប្រជុំ</li>
          <li>កំណត់គោលបំណងប្រជុំបច្ចេកទេសអោយបានច្បាស់លាស់</li>
          <li>ចាត់តាំងអ្នកសម្របសម្រួលការប្រជុំ ឱ្យទទួលតួនាទីនិងទំនួលខុសត្រូវ</li>
          <li>រៀបចំបញ្ជីសម្រង់វត្ដមាន</li>
          <li>រៀបចំសម្ភារៈប្រជុំ ( ក្រដាសធំ ហ្វឺត ស្កុត ស្លាយ...បើសិនត្រូវការ.)</li>
          <li>ចាត់ចែងអ្នកទទួលបន្ទុកសរសេរ កំណត់ហេតុ របាយការណ៍ប្រជុំ </li>
          <li>ត្រៀមរៀបចំគម្រោង, រៀបចំកិច្ចការសម្រាប់ពិភាក្សាក្នុងរបៀបវារៈ</li>
        </ul>

        <h5 style={{fontWeight: 'bold', color: '#004587'}}>ការត្រៀមមុនពេលប្រជុំ</h5>
        <ul className="list-style">
          <li>ធ្វើការឈប់ខ្លះនៅពេលសម្របសម្រួលកិច្ចប្រជុំ ធ្វើការជំរុញ លើកទឹកចិត្តឱ្យអ្នកចូលរួមមានការចូលរួមប្រកប ដោយប្រសិទ្ធភាព ព្រមទាំងកសាងទំនាក់ទំនងល្អ</li>
          <li>ឧស្សាហ៌ពិនិត្យពីគោលដៅនិងលទ្ធផលរំពឹងទុកនៃការប្រជុំ</li>
          <li>ធ្វើការផ្លាស់ប្តូរនៅពេលបានទទួលមតិត្រឡប់នូវវិធីឱ្យអ្នកចូលរួមប្រជុំមានការចូលរួម</li>
          <li>ប្រើសម្ភារផ្សេងៗនិងធ្វើឱ្យមានការចូលរួមការពិភាក្សា</li>
        </ul>

        <h5 style={{fontWeight: 'bold', color: '#004587'}}>ក្រោយពេលប្រជុំ</h5>
        <ul className="list-style">
          <li>បូកសរុបលទ្ធផលការប្រជុំ</li>
          <li>ពិភាក្សាទៅលើចំណុចអ្វីដែលអាចកែលម្អ និងបន្ថែមទៅរបៀបវារៈនៅខែក្រោយ</li>
          <li>កំណត់ផែនការសម្រាប់ការប្រជុំខែបន្ទាប់</li>
          <li>កំណត់ទីកន្លែសម្រាប់ការប្រជុំសម្រាប់ខែបន្ទាប់</li>
        </ul>
      </div>
    </>
  );
};

export default Article1Content1;
