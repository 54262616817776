import "./Content.css";

const Article1Content5 = () => {
  return (
    <>
      <div className="content-body">
        <h3>ការសង្កេត៖</h3>
        <p>អ្នកសំឡឹងរកមើលគ្រប់ភស្តុតាងទាំងអស់លើជំនាញនិងឥរិយាបថរបស់គ្រូប្រឹក្សាគរុកោសល្យ នៅពេលសង្កេតគ្រូ ប្រឹក្សាគរុកោសល្យប្រចាំសាលា។ 
          អ្នកត្រូវរកកន្លែងអង្គុយធ្វើការសង្កេតគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាធ្វើ ការផ្តល់ប្រឹក្សា គរុកោសល្យដែលធានាបាននូវមិនឱ្យមានការរំខានដល់ការបង្រៀននិងការផ្តល់ប្រឹក្សាគរុកោសល្យ។ 
          អ្នកអាចជ្រើសរើសយក ការជួយគ្រូ បង្រៀន ឬជួយគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលានៅពេលណាមួយអំឡុងពេលសង្កេតប៉ុន្តែគោលដៅរបស់អ្នក គឺមិនរំខានដល់ ការសិក្សារបស់សិស្សនោះទេ។ 
          ក្នុងអំឡុងពេលសង្កេតតួនាទីសំខាន់របស់អ្នក គឺប្រមូលព័ត៌មានឱ្យបានច្រើន តាមដែលអ្នក អាចធ្វើបានដែលនឹងជួយផ្តល់មតិកែលម្អនិងព័ត៌មានត្រឡប់និងកែលម្អគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលា។
          ក្នុងអំឡុងពេលសង្កេតអ្នកគួរតែចងចាំ៖
        </p>

        <ul className="list-style">
          <li>មិនត្រូវធ្វើការសន្មត់ ឬស្មាន អ្នកកំពុងស្វែងរភស្តុតាង។</li>
          <li>អ្នកនៅទីនោះដើម្បីរៀនអំពីការធ្វើប្រឹក្សាគរុកោសល្យ។</li>
          <li>ធ្វើផ្តោតលើការផ្ដល់ប្រឹក្សាគរុកោសល្យ និងការគាំទ្រដែលអ្នកអាចផ្តល់ឱ្យបាន។</li>
          <li>មើលអ្វីដែលគ្រូប្រឹក្សាគរុកោសល្យកំពុងធ្វើនិងនិយាយ។</li>
        </ul>

        <p>ការសង្កេតគួរតែបំរើឱ្យការធ្វើឱ្យមានការរីកចម្រើន។ ក្នុងតួនាទីជាទីប្រឹក្សាគរុកោស្យជំនាញគឺត្រូវយល់ពីគ្រូប្រឹក្សា គរុកោសល្យ ប្រចាំសាលា ហើយប្រើ ចំណេះដឹងនេះជាជំនួយក្នុងការផ្ដល់ព័ត៌មានត្រឡប់។ 
          ក្នុងអំឡុងពេលការសង្កេត អ្នកធ្វើការប្រមូល ភស្តុតាងផ្នែកជំនាញ និង ឥរិយាបថរបស់គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលា ហើយប្រើព័ត៌មានទាំងនេះ ធ្វើការផ្ដល់ព័ត៌មានត្រឡប់។ 
          ក្នុងនាមជាអ្នកសង្កេត អ្នកសួរសំណួរខ្លួនឯងអំពីគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលា និងស្វែងរកចម្លើយទាំងនេះ៖</p>
        
        <ul className="list-style">
          <li>តើគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាអាចឆ្លើយសំណួររបស់គ្រូបង្រៀនបានត្រឹមត្រូវទេ?</li>
          <li>តើ​គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលា​អាច​ពន្យល់​ បានក្បោះក្បាយ​លើ​ប្រធានបទ​និង​ផ្សារភ្ជាប់​ចំណេះដឹង​ផ្ទាល់​ខ្លួន​ឬ​ទេ?</li>
          <li>តើ​គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាហាក់​មាន​ទំនុក​ចិត្ត​លើ​ចំណេះ​ដឹង​របស់​ខ្លួន​លើ​មុខ​វិជ្ជា​ឬ​ទេ?</li>
          <li>តើ​គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលា​បង្ហាញ​ភាពខ្វះចន្លោះ​​លើ​ចំណេះដឹង​ផ្នែកប្រធាន​បទ​សំខាន់​ឬ​ទេ?</li>
          <li>តើ​គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាសង្កេត​មើល​គ្រូ​មាន​ប្រសិទ្ធភាព​ឬក៏យកតួនាទីធ្វើគ្រូបង្រៀន? </li>
          <li>តើ​គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាស​គិត​ពី​តម្រូវ​ការ​របស់​កុមារ ហើយ​ដឹង​ថា​ពេល​ណា​គួរ​សង្កេត និង​ពេល​ណា​គួរ​បង្រៀនសហការជាមួយគ្រូទេ?</li>
        </ul>
      </div>
    </>
  );
};

export default Article1Content5;
