import { Carousel } from "antd";
// import RescFooter from "../../componants/Resc.footer";
// import UserHero from "../../componants/UserHero";

const HfWordHome = () => {
  const imgSlides = [
    { url: require("../../assets/images/hfword/slide/img01.jpg") },
    { url: require("../../assets/images/hfword/slide/img02.png") },
    { url: require("../../assets/images/hfword/slide/img03.png") },
    { url: require("../../assets/images/hfword/slide/img04.jpg") },
    { url: require("../../assets/images/hfword/slide/img05.jpg") },
    { url: require("../../assets/images/hfword/slide/img06.jpg") },
  ];

  //   const textOverlay = [{ text: "" }];

  return (
    <>
      <div className="container">
        <Carousel autoplay className="py-4">
          {imgSlides.map((slide, slideIndex) => (
            <div>
              <img
                src={slide.url}
                alt=""
                className="w-[1660px] h-[428px] object-cover object-center rounded"
              />
            </div>
          ))}
        </Carousel>

        <div className="bg-white px-6 py-8 sm:py-10 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-4xl font-bold tracking-tight text-sbb-dark-blue sm:text-3xl">
              សូមស្វាគមន៍មកកាន់ បណ្ណាល័យឌីជីថល
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              បណ្ណាល័យឌីជីថល នេះបង្កើតឡើងដើម្បីលើកកម្ពស់គុណភាពបង្រៀន និងរៀន
              និងអភិវឌ្ឍន៍សមត្ថភាពជំនាញ របស់លោកគ្រូ អ្នកគ្រូ។
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default HfWordHome;
