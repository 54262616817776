import "./Content.css";

const Article1Content1 = () => {
  return (
    <>
      <div className="content-body">
        <h3>១. សេចក្តីផ្តើមទីប្រឹក្សាគរុកោសល្យជំនាញ</h3>
        <p>
          អ្នកទទួលផលសំខាន់ៗនៃការប្រឹក្សាគរុកោសល្យថ្នាក់ដំបូង
          ជាគ្រូបង្រៀនថ្នាក់ដំបូងដែលគួរបង្កើតឱ្យមានគោលវិធីបង្រៀនប្រកប
          ដោយវិជ្ជាជីវៈ និងចុងក្រោយការកែលម្អលទ្ធផលសិក្សារបស់សិស្ស។
          ចំនួនម៉ោង/ក្រេឌិតក្នុងការអភិវឌ្ឍវិជ្ជាជីវជាប្រចាំអាចគេយកទៅធ្វើការលើកទឹកចិត្តដល់គ្រូបង្រៀននិងគ្រូប្រឹក្សាគរុកោសល្យ
          ប្រចាំសាលា បានសមស្របនៅពេលគេរៀបចំប្រព័ន្ធហើយ។
        </p>
        <span className="right">
          <i>
            ប្រឹក្សាគរុកោសល្យកម្រិតថ្នាក់ដំបូង ក្រសួងអប់រំ យុវជននិងកីឡា ខែកញ្ញា
            ឆ្នាំ២០២០
          </i>
        </span>
        <p>
          ការធ្វើប្រឹក្សាគរុកោសល្យដោយទីប្រឹក្សាគរុកោសល្យជំនាញប្រកបដោយគុណភាពជួយគាំទ្រកាអនុវត្តកម្មវិធីប្រឹក្សាគរុកោសល្យ
          កម្រិតថ្នាក់ ដំបូង
          ដោយការផ្តល់ប្រឹក្សាផ្នែកគរុកោសល្យប្រកបដោយប្រសិទ្ធភាពទៅកាន់គ្រូបង្រៀនកម្រិតថ្នាក់ដំបូង
          ក្នុងគោលបំណងពង្រឹង គុណភាព ការបង្រៀន និងលទ្ធផលការសិក្សារបស់សិស្ស។
        </p>
      </div>
    </>
  );
};

export default Article1Content1;
