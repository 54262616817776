import "./Content.css";

const Article1Content1 = () => {
  return (
    <>
      <div className="content-body">
        <h3>៣. ភាពជាអ្នកដឹកនាំរបស់ទីប្រឹក្សាគរុកោសល្យជំនាញ</h3>

        <p>
          ទីប្រឹក្សាគរុកោសល្យជំនាញដែលមានសក្តានុពលគឺជាអ្នកគំរូគេ
          មានគុណវុឌ្ឍិគ្រប់គ្រាន់ ព្រមទាំងមានភាពជាអ្នកដឹកនាំ។ ពួកគេមាន
          ឥរិយាបថល្អព្រមទាំងពិចារណាលើសីលធម៌
          និងមានភាពពិតប្រាកដ។​ទីប្រឹក្សាគរុកោសល្យជំនាញមានសន្តានចិត្តល្អ មានការ
          គិតហ្មតចត់ និងជាក់លាក់លើកិច្ចការងារ។
          ទីប្រឹក្សាគរុកោសល្យជំនាញគួរតែមានគុណធម៌ មេត្តាធម៌ ជាប្រយោជន៍ដល់អ្នកដទៃ
          និងជួយ
          ពន្យល់ដល់គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាក៏ដូចជាដល់គ្រូបង្រៀននៅតាមសាលា។
          ទីប្រឹក្សាគរុកោសល្យប្រកាន់ភ្ជាប់លើសច្ចធម៌ មានកត្តញ្ញូ
          ព្រមទាំងផ្តល់កំលាំងចិត្តដល់អ្នកនៅជុំវិញខ្លួន។
        </p>
        <p>
          ទីប្រឹក្សាគរុកោសល្យជំនាញគឺជាអ្នកដឹកនាំ រួចហើយពួកគាត់មានភាពឈ្លាសវៃ
          ដោះស្រាយបញ្ហាបានភ្លាមៗ មានគំនិតច្នៃប្រឌិត
          ក្នុងនាមជាអ្នកដែលមានបទពិសោធលើវិធីសាស្ត្របង្រៀន។
          ទីប្រឹក្សាគរុកោសល្យជំនាញមានការប្តេជ្ញាចិត្តធ្វើការចែករំលែកចំណេះដឹង
          ព្រមទាំងវិធីសាស្ត្របង្រៀនទៅកាន់គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលាទៅតាមតម្រូវការ។
          ម្យ៉ាងទៀតទីប្រឹក្សាគរុកោសល្យជំនាញប្រកប ដោយវិជ្ជាជីវៈត្រូវតែទទួលយក
          និងស្តាប់យកគំនិតពីអ្នកនិយាយ
          ព្រមទាំងចូលរួមយ៉ាងសកម្មនៅក្នុងសកម្មភាពបង្រៀននិងរៀន។
        </p>
        <p>
          ទោះជាយ៉ាងណាក៏ដោយទីប្រឹក្សាគរុកោសល្យត្រូវតែយកចិត្តទុកដាក់ថែរក្សាលើសុខភាពរបស់ខ្លួន
          ដើម្បីអាចបំពេញកិច្ចការងារ ប្រកបដោយប្រសិទ្ធភាព។
          វិធីរក្សាសុខភាពគឺការធ្វើលំហាត់ប្រាណ
          បរិភោគអាហារដែលធ្វើឱ្យសុខភាពល្អនិងមានអនាម័យ។
          ម៉្យាងទៀតការសម្រាកលំហែអារម្មណ៍ ស្តាប់តន្តី្រ
          ក៏ជួយបង្កើនថាមពលដល់រាងកាយបានល្អផងដែរ។
        </p>
        <p>
          ដើម្បីឱ្យក្លាយទៅជាទីប្រឹក្សាគរុកោសល្យរឹងមាំ
          ពូកែគឺទទួលយកការងារប៉ុន្តែវាជាបទពិសោធន៍ពោពេញដោយសេចក្តីសប្បាយចិត្ត
          ដែលអាចជំរុញលើកទឹកចិត្តគ្រូបង្រៀន
          គ្រូប្រឹក្សាគរុកោសល្យជាច្រើនអ្នកគាំទ្រលើការរៀនសូត្រ។
        </p>
      </div>
    </>
  );
};

export default Article1Content1;
