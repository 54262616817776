const MentoringData = () => {
  return (
    <>
      <iframe
        title="Edtech and Literacy Daily Mentoring"
        width="1024"
        height="804"
        src="https://app.powerbi.com/view?r=eyJrIjoiZmU5NmM5YWMtOGI4My00Y2RmLTllMDEtNTY5MjQyYjlkYWZmIiwidCI6IjgzZTg2MjRjLThjNWQtNDQ0OS1hMDkyLTM1NmU1N2ZhNjMxNyIsImMiOjEwfQ%3D%3D"
        frameborder="0"
        allowFullScreen="true"
      ></iframe>
    </>
  );
};

export default MentoringData;
