import React from "react";
import { useState, useEffect } from 'react';
import { request } from "../../util/api";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Space, Table, Tag } from 'antd';

//External Style
import './lessonBuilder.css'

const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    
  ];

  const data = [
    {
      key: '1',
      name: 'John Brown',
      age: 32,
      address: 'New York No. 1 Lake Park',
      tags: ['nice', 'developer'],
    },
    {
      key: '2',
      name: 'Jim Green',
      age: 42,
      address: 'London No. 1 Lake Park',
      tags: ['loser'],
    },
    {
      key: '3',
      name: 'Joe Black',
      age: 32,
      address: 'Sydney No. 1 Lake Park',
      tags: ['cool', 'teacher'],
    },
    {
        key: '1',
        name: 'John Brown',
        age: 32,
        address: 'New York No. 1 Lake Park',
        tags: ['nice', 'developer'],
      },
      {
        key: '2',
        name: 'Jim Green',
        age: 42,
        address: 'London No. 1 Lake Park',
        tags: ['loser'],
      },
  ];


const LessonPlandBuilder = () => {
    const ref = React.createRef();
    const [active, setActive] = useState(0);
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
  
    useEffect(() => {
      getList(); // call funcion getList
    }, []);
  
    // create a function fetch data from api
    const getList = () => {
      setLoading(true)
      request("get", "teacher").then((res) => {
          setList(res.data.list);
        //   console.log(res.data.list)
          setLoading(false)
      });
    };

      const uniqueSchool = [...new Set(list.map(item => item.school))];
      const uniqueGrade = [...new Set(list.map(item => item.grade))];

    const DownloadPDF =()=>{
        const input = ref.current;
        html2canvas(input).then((canvas)=>{
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4', true);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
            const imgX = (pdfWidth - imgWidth * ratio) / 2;
            const imgY = 30;
            pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
            pdf.save('lessonplan.pdf');
        });
    };

    return ( 
        <>  
            <div className="main" ref={ref}>
                <div className="header">
                    <h1>រៀបចំកិច្ចតែងការ</h1>
                </div>
                <div className="general">
                    <div className="teacher">
                        <label className="label" htmlFor="name">ឈ្មោះគ្រូៈ</label>
                        <select className="teacher-name">
                            {list.map((data, index)=>(
                                    <option className="teacher-select" key={index} value={data.teacher_name}>{data.teacher_name}</option>
                                ))}
                        </select>
                    </div>

                    <div className="grade">
                        <label className="label" htmlFor="name">ថ្នាក់ទីៈ</label>
                        <select className="teacher-name">
                            {uniqueGrade.map((data, index)=>(
                                    <option className="teacher-select" key={index} value={data}>{data}</option>
                                ))}
                        </select>
                    </div>

                    <div className="school">
                        <label className="label" htmlFor="name">ថ្នាក់ទីៈ</label>
                        <select className="teacher-name">
                            {uniqueSchool.map((data, index)=>(
                                    <option className="teacher-select" key={index} value={data}>{data}</option>
                                ))}
                        </select>
                    </div>
                </div>
                <div className="header-content">
                    <div className="header-input">
                        <h2 style={{textAlign: 'center', paddingTop: 10, paddingBottom: 10}}>ព័ត៌មានទូទៅ</h2>
                        
                        <div className="general-info">
                            <div className="box-one">
                                <div className="course-box">
                                    <label className="label" htmlFor="course-name">មុខវិទ្យាបង្រៀនៈ</label>  
                                    <input type="text" name="course-name" id="course-name" className="input-box"/>
                                </div>

                                <div className="course-box">
                                    <label className="label" htmlFor="objective">វត្ថុបំណងៈ</label>  
                                    <input type="text" name="objective" id="objective" className="input-box"/>
                                </div>

                                <div className="course-box">
                                    <label className="label" htmlFor="knowledge">ចំណេះដឹងៈ</label>  
                                    <input type="text" name="knowledge" id="knowledge" className="input-box"/>
                                </div>
                                <div className="course-box">
                                    <label className="label" htmlFor="skill">បំណិនៈ</label>  
                                    <input type="text" name="skill" id="skill" className="input-box"/>
                                </div>                                
                            </div>

                            <div className="box-two">
                                <div className="course-box">
                                    <label className="label" htmlFor="atribute">កាលបរិច្ឆេទៈ</label>  
                                    <input type="text" name="atribute" id="atribute" className="input-box"/>
                                </div>
                                <div className="course-box">
                                    <label className="label" htmlFor="material">ថ្ងៃទី</label>  
                                    <input type="text" name="material" id="material" className="input-box"/>
                                </div>
                                <div className="course-box">
                                    <label className="label" htmlFor="course-name">ឥរិយាបថៈ</label>  
                                    <input type="text" name="course-name" id="course-name" className="input-box"/>
                                </div>
                                <div className="course-box">
                                    <label className="label" htmlFor="objective">សម្ភារៈ</label>  
                                    <input type="text" name="objective" id="objective" className="input-box"/>
                                </div>
                            </div>
                        </div>

                        <div className="pt-6">
                            <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
                                កំណត់ចំណាំផ្សេងៗ៖
                            </label>
                            <div className="mt-2">
                                <textarea
                                rows={3}
                                name="comment"
                                id="comment"
                                className="block w-full rounded-md border-1 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                defaultValue={''}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content-body">
                    <Table columns={columns} dataSource={data} pagination={10}/>
                    
                </div>
            </div>







            <div className="download-btn" onClick={DownloadPDF} >
                <h3>ទាយយកឯកសារ</h3>
            </div>
            
        </>
     );
}
 
export default LessonPlandBuilder;







