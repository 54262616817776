import "./Content.css";

const Article1Content5 = () => {
  return (
    <>
      <div className="content-body">
        <h3>រៀបចំផែនការសម្រាប់ការចុះ</h3>
        <p>
          ធ្វើការរៀបចំផែនការនូវអ្វីដែលអ្នកត្រូវធ្វើនៅពេលអ្នកចុះធ្វើការផ្តល់ប្រឹក្សាគរុកោសល្យដល់គ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលារៀន
          គឺចំណេញពេល
          រួចហើយគ្រូប្រឹក្សាគរុកោសល្យនឹងទទួលបាននូវការគាំទ្រពីអ្នកបានច្រើនបំផុតនៅពេលចុះ។
          មុនពេលចុះទៅ ធ្វើទីប្រឹក្សា គរុកោសល្យគួរតែ៖
        </p>
        <ul className="list-style">
          <li>រៀបចំផែនការចុះមានកាលបរិច្ឆេទច្បាស់លាស់ ម៉ោង ព្រមទាំងមុខវិជ្ជា</li>
          <li> ធ្វើការថតចម្លងផែនការចុះផ្តល់ប្រឹក្សាគរុកោសល្យ។</li>
          <li>
            ចំណាយពេលធ្វើការពិនិត្យ ឆ្លុះបញ្ចាំងលើការចុះលើកមុន
            ព្រមទាំងធ្វើការពិចារណានូវអ្វីដែលត្រូវធ្វើការផ្តោតទៅលើ។ យកល្អអាន
            នូវការកត់ត្រាបានមកពីការចុះលើកមុនៗ ព្រមទាំងមើលកម្រិតជំនាញមុនៗ
            ដូច្នេះធ្វើឱ្យយើងដឹងថាគ្រូប្រឹក្សាគរុកោសល្យនឹង​ត្រូវ
            ការគាំទ្រលើផ្នែកអ្វី។
          </li>
          <li>
            សរសេរចុះនូវផែនការដែលត្រូវធ្វើការផ្តោតទៅលើនៅពេលចុះជួយលើកនេះ។
            តើមានចំណុចអ្វីដែលត្រូវនិយាយពិភាក្សា
            ឬក៏សំណួរអ្វីខ្លះប្រើក្នុងការឆ្លុះបញ្ចាំង?
            តើវិធីសាស្ត្រអ្វីខ្លះនឹងប្រើក្នុងការសង្កេត?
            តើអំឡុងពេលសង្កេតត្រូវសំឡឹងរកអ្វីខ្លះ?
            ដោយមានផែនការធ្វើរួចជាស្រេចដូច្នេះនឹងធ្វើឱ្យការសង្កេតប្រកបដោយប្រសិទ្ធភាព។
          </li>
        </ul>
      </div>
    </>
  );
};

export default Article1Content5;
