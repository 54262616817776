import "./Content.css";

const Article1Content1 = () => {
  return (
    <>
      <div className="content-body">
        <h3>
          ២.
          ដំណើរការចុះផ្តល់ប្រឹក្សាគរុកោសល្យតាមសាលារៀនរបស់ទីប្រឹក្សាគរុកោសល្យជំនាញ
        </h3>
        <p>
          ទីប្រឹក្សាគរុកោសល្យជំនាញជួបប្រជុំជាមួយគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលារៀននិងនាយកសាលាជាប្រចាំព្រមទាំងសង្កេត
          ដំណើរការប្រឹក្សាគរុកោសល្យដែលធ្វើឡើងដោយគ្រូប្រឹក្សាគរុកោសល្យប្រចាំសាលារៀនក្នុងពេលដំណាលគ្នានោះក៏ត្រូវធានាអោយ
          បាននូវសមធម៌និង ផ្តល់នូវកម្រិតនៃការធានាគុណភាពនៅក្នុងកម្រងសាលារៀន។
        </p>
        <p>
          ទំនាក់ទំនងល្អគឺមានសារៈសំខាន់នៅក្នុងដំណើរការធ្វើការប្រឹក្សាគរុកោសល្យ។
          វាជារឿងចាំបាច់ណាស់ដែលគ្រូបង្រៀន
          គ្រូប្រឹក្សាគរុកោសល្យនិងថ្នាក់គណៈគ្រប់គ្រងសាលាយល់ដឹងពីតួនាទីរបស់ទីប្រឹក្សាគរុកោសល្យ
          ដំណើរការការងារ ព្រមទាំងធ្វើ
          ការឯកភាពគ្នាចាប់តាំងពីថ្ងៃចុះទៅជួយនាថ្ងៃដំបូង។
          អ្នកទាំងអស់គ្នាគួរតែយល់ឃើញថាទីប្រឹក្សាគរុកោសល្យគឺជាអ្នកជួយគាំទ្រ
          ជាជាងអធិការ។
        </p>
      </div>
    </>
  );
};

export default Article1Content1;
